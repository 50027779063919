import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from "react-helmet";
import logo from "../../images/logo.png"
import logo2 from "../../images/logo2.png"
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../Redux/ActionCreater';
import $ from "jquery";
import ComingSoonModal from './ComingSoonModal';
import { PORTAL_URL } from '../../config';
function Header() {
  const navigate = useNavigate();
  const [page,setPage]=useState("bintix");
  const [comingSoon,setComingSoon]=useState(false);
  // const [temp, setTemp] = useState(0);
  const [flag, setFlag] = useState(false);
  const dispatch = useDispatch();
  const ref = useRef(null);
  const { headerType } = useSelector(
    (state) => ({
      headerType: state.dataReducer.headerType
    }),
    shallowEqual
  )

  const closeModal =()=>{
    setComingSoon(false)
  };
  const removeClass=()=>{
    document.querySelector('body').classList.remove('layout-expanded2'); 
  }
  useEffect(()=>{
  let  clg=window.location.pathname.split("/").slice(-1)[0];
  setPage(clg)
  },[window.location.pathname])

let loadingTrue=()=>{
    dispatch(setLoading(true))
}

useEffect(() => {
  let val=0;
  $('body').prepend( $( '<div class="layoutovarlay"></div>' ) );
  $('.navigation').prepend( $( '<div class="layoutovarlay2"></div>' ) );
  $(".NavBar").click(function () {
    $("body").toggleClass('layout-expanded2');
  });
  $('.layoutovarlay2').on('click', function(e){
    e.preventDefault();
    if($("body").hasClass('layout-expanded2')){
      $("body").removeClass('layout-expanded2');
    }
  });
      $(window).scroll(function() {
        if ($(document).scrollTop() < val) {
          $(".headercontainer").addClass("sticky");
        } else {
          $(".headercontainer").removeClass("sticky");
        }
       val= $(document).scrollTop()
       console.log("val", val);
      //  setTemp(val)
      //  console.log("temp", temp);
       if(val===0){
        $(".headercontainer").removeClass("sticky");
       }
      });
      
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      removeClass()
    }
  };
  document.addEventListener('click', handleClickOutside, true);
  return () => {
    document.removeEventListener('click', handleClickOutside, true);
  };
  
}, [flag]);
  return (
    <>
      <Helmet>
    
      </Helmet>
      <header className={headerType===1?"headercontainer":"headercontainer detailhedr hedrbg"}>
        <div className="container">
          <div className="row align-items-center">
            <div className="logoouter">
              <div className="logo">
              {headerType===1&&<a href="/bintix" onClick={(e)=>{e.preventDefault();window.location.pathname!=="/bintix/"&&window.location.pathname!=="/bintix"&&window.location.pathname!=="/"&&loadingTrue();navigate("/")}}><img src={logo} alt="logo" width={"90px"} height={"53px"} /></a>}
                {headerType===2&&<a href="/bintix" onClick={(e)=>{e.preventDefault();window.location.pathname!=="/bintix/"&&window.location.pathname!=="/bintix"&&window.location.pathname!=="/"&&loadingTrue();navigate("/")}}><img src={logo2} alt="logo" /></a>}
              </div>
            </div>
            <div className="navigation">
              <div className="navigationouter" ref={ref}>
                <div className="navigationbox" >
                  <div className="sf-menu-outer">
                    <ul className="sf-menu">
                      <li className={page==="bintix"?"active":""}><a href="/bintix" onClick={(e)=>{e.preventDefault(); window.scrollTo(0, 0);window.location.pathname!=="/bintix/"&&window.location.pathname!=="/bintix"&&window.location.pathname!=="/"&&loadingTrue();removeClass();navigate("/")}}>Home</a></li>
                      <li className={page==="collect-my-waste"?"active":""}><a href="/bintix/collect-my-waste"  onClick={(e)=>{e.preventDefault();window.scrollTo(0, 0);window.location.pathname!=="/bintix/collect-my-waste/"&&window.location.pathname!=="/bintix/collect-my-waste"&&loadingTrue();removeClass();navigate("/collect-my-waste")}}>Collect My Waste</a></li>

                      <li className={page==="context"?"active":""}><a href="/context"  onClick={(e)=>{e.preventDefault(); window.scrollTo(0, 0);loadingTrue();removeClass();navigate("/context")}}>Clients</a></li>
                      <li className={page==="impact"?"active":""}><a href="/bintix/impact" onClick={(e)=>{e.preventDefault(); window.scrollTo(0, 0);window.location.pathname!=="/bintix/impact/"&&window.location.pathname!=="/bintix/impact"&&loadingTrue();removeClass();navigate("/impact")}}>Operations</a></li>

                      <li className={page==="about-us"?"active":""}><a href="/bintix/about-us" onClick={(e)=>{e.preventDefault(); window.scrollTo(0, 0);window.location.pathname!=="/bintix/about-us/"&&window.location.pathname!=="/bintix/about-us"&&loadingTrue();removeClass();navigate("/about-us")}}>About us</a></li>
                      {headerType===1&&<li><a href="/partner-code" onClick={(e)=>{e.preventDefault(); window.scrollTo(0, 0);window.location.pathname!=="/bintix/partner-code/"&&window.location.pathname!=="/bintix/partner-code"&&loadingTrue();removeClass();navigate("/partner-code")}}>Partner Zone</a></li>}
                      {headerType===1&&<li><a href={PORTAL_URL+"/login"}>Login</a></li>}
                      {headerType===2&&<li><a href="/bintix" onClick={(e)=>{e.preventDefault(); window.scrollTo(0, 0); window.location.pathname!=="/bintix/about-us#contact/"&&window.location.pathname!=="/bintix/about-us#contact"&&loadingTrue();navigate("/about-us#contact")}}>Contact Us</a></li>}
                    </ul>
                    <div className="loginbtx">
                    {headerType===1&&<a href="/bintix" onClick={(e)=>{e.preventDefault(); window.scrollTo(0, 0); setComingSoon(true)}}>Download The App</a>}
                  {headerType===2&&<a href="/bintix" onClick={(e)=>{e.preventDefault();}}>Login To The App</a>}
                </div>
                  </div>
                </div>
                
                <a className="NavBar" href="/bintix" onClick={(e)=>{e.preventDefault();$("body").addClass("layout-expanded2")}}><i className="icon icon-segment" /></a>
              </div>
            </div>
          </div>
        </div>
      </header>
      {headerType===3&&<header className="headercontainer detailhedr hedrbg">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="logoouter">
                            <div className="logo">
                                <a href="/bintix" onClick={(e)=>{e.preventDefault();loadingTrue();navigate("/")}}><img src={logo2} alt /></a>
                            </div>
                        </div>
                        <div className="navigation">
                            <div className="navigationouter">
                                <div className="navigationbox">
                                    <div className="navuser">
                                        <div className="userimg">
                                            <img src={logo} alt="Logo" />
                                        </div>
                                    </div>
                                    <div className="sf-menu-outer">
                                        <ul className="sf-menu">
                                            <li className=""><a href="/bintix" onClick={(e)=>{e.preventDefault();loadingTrue();navigate("/")}}>Home</a></li>
                                            <li className=""><a href="/bintix/collect-my-waste" onClick={(e)=>{e.preventDefault();loadingTrue();navigate("/collect-my-waste")}}>Collect My waste</a></li>
                                            <li><a href="/bintix/context" onClick={(e)=>{e.preventDefault();loadingTrue();navigate("/context")}}>Clients</a></li>
                                            <li className=""><a href="/bintix/impact" onClick={(e)=>{e.preventDefault();loadingTrue();navigate("/impact")}}>Impact</a></li>
                                            <li className=""><a href="/bintix/about-us" onClick={(e)=>{e.preventDefault();loadingTrue();navigate("/about-us")}}>About us</a></li>
                                            {/* <li><a href="/bintix" onClick={(e)=>{e.preventDefault();loadingTrue();navigate("/")}}>Press &amp; Blogs</a></li> */}
                                            <li><a href="/bintix" onClick={(e)=>{e.preventDefault();loadingTrue();navigate("/about-us#contact")}}>Contact Us</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="loginbtx">
                                    <a href="/bintix" onClick={(e)=>{e.preventDefault();navigate("/")}}>Login To The App</a>
                                </div>
                                {/* <a className="NavBar" onClick={(e)=>{e.preventDefault();}} href="/bintix"><i className="icon icon-segment" /></a> */}
                                <a className="NavBar" href="/bintix" onClick={(e)=>{e.preventDefault()}}><i className="icon icon-segment" /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </header>}
            {comingSoon&&<ComingSoonModal closeModal={closeModal}/>}
    </>


  )
}

export default Header