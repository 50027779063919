import { applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import reducer from './Redux/Reducer';


const reducers = combineReducers({
  dataReducer: reducer,
});
const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(thunk))
);
export default store