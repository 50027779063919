import './css/vendor.min.css';
import './css/style.css';
import './css/media.css';
import './App.css';
import load from "./images/loading.gif"
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import HomepageDynamic from './views/Home/Home';
import Header from './views/Partial/Header';
import Footer from './views/Partial/Footer';
import CollectWasteDynamic from './views/Collect-waste/Collect-waste';
import Context from './views/Context/Context';
import OurData from './views/Our-data/Our-data';
import DynamicDynamic from './views/impact/Impact';
import AboutUs from './views/About-us/AboutUs';
import News from './views/News/News';
import Details from './views/Details/Details';
import PartnerCode from './views/PartnerCode/PartnerCode';
import Movement from './views/Movement/Movement';
import Partner1 from './views/Partner1/Partner1';
import Partner2 from './views/Partner2/Partner2';
import ThankYou from './views/ThankYou/ThankYou';
import { shallowEqual, useSelector } from 'react-redux';
import TermsPolicy from './views/Term-policy/Terms-policy';
import {  HelmetProvider } from 'react-helmet-async';
import PrivacyPolicy from './views/Privacy-policy/Privacy-policy';

function App() {
  const { loading} = useSelector(
    (state) => ({
      loading: state.dataReducer.loading  }),
    shallowEqual
  );
  return (
    <HelmetProvider>
 <div className="App">
      <BrowserRouter  basename='/'>
        <Header/>
        <Routes >
          <Route exact path='/' element={<HomepageDynamic/>} />
          <Route exact path='/collect-my-waste' element={<CollectWasteDynamic/>} />
          <Route exact path='/context' element={<Context/>} />
          <Route exact path='/our-data' element={<OurData/>} />
          <Route exact path='/impact' element={<DynamicDynamic/>} />
          <Route exact path='/about-us' element={<AboutUs/>} />
          <Route exact path='/news' element={<News/>} />
          <Route exact path='/details' element={<Details/>} />
          <Route exact path='/partner-code' element={<PartnerCode/>} />
          <Route exact path='/movement' element={<Movement/>} />
          <Route exact path='/partner1' element={<Partner1/>} />
          <Route exact path='/partner2' element={<Partner2/>} />
          <Route exact path='/thank-you' element={<ThankYou/>} />
          <Route exact path='/terms-policy' element={<TermsPolicy/>} />
          <Route exact path='/privacy-policy' element={<PrivacyPolicy/>} />

          {/* <Redirect to="/" /> */}
          <Route path='/*' element={ <Navigate to="/" /> }/>
        </Routes>
        <Footer/>
      </BrowserRouter>
     {loading&&<div className='loader'><img src={load}/></div>}
    </div>

    </HelmetProvider>
   
  );
}

export default App;
