import React from 'react'

function VideoModal({youtube_id,closeModal}) {
  return (
    <div className="modal fade show" style={{background:"rgba(0, 0, 0, 0.96)",display:"block"}} id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-body">
         <button type="button" onClick={closeModal} className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>        
  <div className="embed-responsive embed-responsive-16by9">
    <iframe title="video" className="embed-responsive-item" src={`//www.youtube.com/embed/${youtube_id}?autoplay=1&mute=1&enablejsapi=1`} id="video"  allowscriptaccess="always" allow="autoplay"></iframe>
  </div>
          
          
        </div>
  
      </div>
    </div>
  </div>

  )
}

export default VideoModal