import axios from "axios";
import { BASE_URL } from "../config";
import {  
      SET_PAGE, 
      SET_IMPACT_SECTION_DATA,SET_HEADER_TYPE,SET_EMAIL,SET_LOADER } from "./Action";

// const setRazorPay = (data) => ({
//   type: SET_LOADING,
//   payload: data,
// });

export  const setCurrentPage = (data) => ({
    type: SET_PAGE,
    payload: data,
  });
  export  const setHeaderType = (data) => ({
    type: SET_HEADER_TYPE,
    payload: data,
  });
  export  const setImpactSectionData = (data) => ({
    type: SET_IMPACT_SECTION_DATA,
    payload: data,
  });
  export  const setEmailaction = (data) => ({
    type: SET_EMAIL,
    payload: data,
  });
  export  const setLoading = (data) => ({
    type: SET_LOADER,
    payload: data,
  });


 
  export const getImpactSectionData=()=>async(dispatch)=>{
    let data = await axios.get(`${BASE_URL}/impact`)
    dispatch(setImpactSectionData(data.data));
  }

