import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { setHeaderType, setLoading } from '../../Redux/ActionCreater';
import { Helmet } from 'react-helmet-async';

function PrivacyPolicy() {
    let dispatch=useDispatch()

    useEffect(() => {
       
            setTimeout(() => {
                dispatch(setLoading(false))
                
              }, 2000);
            //window.scrollTo(0, 0);
        }, [])

        useEffect(()=>{
            dispatch(setHeaderType(2))
        },[])
  return (
    <>
    <Helmet>
        <title>Bintix::Privacy Policy</title>
        <meta property="og:title" content="Bintix::Term Policy" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
    <section className='details-sec'>
                <div className='container'>
                    <div className='detaslheding policy-text'>

<h2 className='mb-4'>Privacy and Policy </h2>
<h3>1. Your Consent</h3>

<p>
By becoming a User, you acknowledge that you have read and understood the terms and conditions of this User Agreement and that you agree to be bound by all the provisions of this User Agreement as may be revised from time to time, and you understand and agree that you are bound by such terms when you access, use, download, operate this Website/ Mobile Application or utilizing or relying on any service which forms a part of any of the Bintix Services.
</p>

<h3>2. Personal Information</h3>
<p>The User will be required to provide details such as names, address, email, telephone number etc., on the Website/ Mobile Application. The User understands that certain information provided by him/her will qualify to be personally identifiable sensitive information under the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data and Information) Rules, 2011and the disclosure of the same is completely voluntary. We confirm that the storage of such personal information is  in compliance with the requirements under the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data and Information) Rules, 2011.  You expressly consent to the collection, storage and the aforesaid transmission of information and shall not hold Bintix liable or make any claims against Bintix in respect of the collection, storage and transmission of the aforesaid information in accordance with this User Agreement. The User also understands that the efficiency of Bintix Service is dependent on the accuracy and completeness of the information provided by you. Therefore, it is requested that you keep the information up to date. </p>

<p>
Bintix may share your personal information with other service providers for the purpose of providing Bintix Services, including, without limitation, collection agents, website hosting services,  order processing, for verifying location and demographics and visitor surveys. The Company takes commercially reasonable steps to ensure these service providers adhere to the security standards we apply to your personal information and prohibits them from using your information for any reason apart from provision of Bintix Services.
</p>

<p>
At times, Bintix will conduct customer surveys, polls etc., to understand customer behaviour, consumption preferences etc., and may collect certain personally identifiable information from you, which may be transmitted to third parties. Please note that, Bintix will procure your consent before sharing/transmitting any of your personally identifiable information collected as part of these surveys, dry waste collected from your household polls etc., with third parties. All such disclosures shall be made in compliance with applicable laws.
</p>

<p>
Bintix shall create a personal dashboard that can be accessed through your personal account on the Website/Mobile Application based on the dry waste collected from your household which shall include information on your consumption patterns and their environmental impact.  
</p>

<p>
Additionally, Bintix collects, stores, and transmits information about the dry waste collected from your household. Bintix reserves the right to share with third parties, anonymized  data with  third parties including its recyclers, with other waste aggregators, with organizations creating waste awareness, with non-governmental or governmental organizations, with waste picker associations, with circular economy research organizations, with carbon credit organization, for market research, for extended producer responsibility fulfillment, to aid policy making, and/or any other organizations working in the waste management space to promote environmentally sustainable waste management. The User hereby agrees and understands that Bintix will not require consent of the User while sharing such anonymized data. 
</p>
<p>
The Company may also disclose your personal information as is necessary to: (i) comply with a court order; (ii) cooperate with law enforcement or other government agencies; (iii) establish or exercise its legal rights; (iv) protect the property or safety of Bintix and its employees, contractors, vendors, suppliers, and customers; (v) defend against legal claims; (vi) help with internal and external investigations; or (vii) as otherwise required by law or permitted by law. Bintix may disclose your information to others in connection with the sale, merger, acquisition  or financing of Bintix, or in connection with any transaction that involves the sale or assignment of some or all of our assets, including during the diligence process. 
</p>

<p>
Bintix is concerned with the security of the data and information it collects, and uses commercially reasonable physical, electronic and procedural safeguards to help ensure the security, integrity and privacy of all personal information. Bintix limits access to personal information about you to employees, sub-contractors, or third-party service providers who we believe reasonably need to come into contact with that information to provide service to you or in order to do their jobs. However, due to the inherent insecurity of the internet, no security measures should be viewed as 100 percent effective in all circumstances. Bintix cannot absolutely guarantee against a breach of its security or the actions of third parties who may seek to obtain unauthorized access to personal information. The User acknowledges that all transmissions of your personal information is done at your own risk. 
</p>

<h3>3. Opt-Out Policy</h3>

<p>
Bintix may use your personal information to contact you via mail, e-mail, telephone or mobile device in order to give you updates about information regarding Bintix Services availed by you, payment confirmations, current information regarding our Services, or other promotions that may be of interest to you. Please note that you will have an option to opt out of any promotional emails, newsletters or other emails sent by Bintix to you. 
</p>

<h3>4. How You Can Reach Us</h3>

<p>If you have any questions or complaints about our Site, our Privacy Policy, or the Information we collect, please contact our customer service team by email at <strong>hello@bintix.com</strong></p>

        </div>
    </div>
    </section>
    </>
  )
}

export default PrivacyPolicy