import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { setHeaderType, setLoading } from '../../Redux/ActionCreater';
import { Helmet } from 'react-helmet-async';

function TermsPolicy() {
    let dispatch=useDispatch()

    useEffect(() => {
       
            setTimeout(() => {
                dispatch(setLoading(false))
                
              }, 2000);
            //window.scrollTo(0, 0);
        }, [])

        useEffect(()=>{
            dispatch(setHeaderType(2))
        },[])
  return (
    <>
    <Helmet>
        <title>Bintix::Terms Policy</title>
        <meta property="og:title" content="Bintix::Term Policy" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
    <section className='details-sec'>
                <div className='container'>
                    <div className='detaslheding policy-text'>
                    <h2 className='mb-4'>Terms of Use </h2>
                    <h3>1. INTRODUCTION </h3>
<p>
Bintix Waste Research Private Limited is a private limited company having its registered office at Survey 23 (part), Gaganpahad village, Telangana NGOs colony, Katedhan, Hyderabad, Telangana 500077(hereinafter referred to as the “Company” or “Bintix” or “we” or “us” or “our”). Bintix provides under its own name or through its subsidiaries/affiliate entities, dry waste collection services to households. 
</p>
<p>
Bintix has developed a website – www..bintix.com (hereinafter referred to as the “Website”) and a smart phone mobile application (“Mobile Application”) that enables the users/ registered members of the Website/Mobile Application to seek the collection of dry waste and recyclables at their door step (“Services”). 
</p>

<h3>2. PARTIES TO THE USER AGREEMENT  </h3>
<p>The membership of the Website/ Mobile Application is open to individuals and residential societies. For the purposes of these Terms and Conditions, the term ‘User’ or “you” or “your” shall mean individuals who download, use, accesses, or registers with the Website and/or the Mobile Application for his/her personal use; or an individual who downloads, uses, accesses or registers with the Website and/or the Mobile Application on behalf of any entity.  </p>
<p>
This User Agreement (as defined below) is between Bintix and the User.
</p>

<p>
Every User hereby agrees that by accessing, registering on or using the Website and/or the Mobile Application, or other information contained therein (collectively “Bintix Services”), he/she is entering into a legally binding agreement with Bintix based on the Terms and Conditions mentioned in this document and those contained in Bintix’s privacy policy, as updated from time to time (“Privacy Policy”), which is hereby incorporated by reference (hereinafter collectively referred to as the “User Agreement”). 
</p>

<h3> 3. REGISTRATION AS USER ETC.,
</h3>
<p>You may register on the Website/ Mobile Application as a single user, representing yourself /or other persons/ entities. In the event you are a User registering on the Website/ Mobile Application on behalf of other persons/ entities, you represent that such person(s)/ entity(ies) have provided the requisite consent and approval authorizing you to create the account and provide the information on their behalf on the Website/ Mobile Application. The User will be responsible for any activity on your account until your account is closed, whether such activity is undertaken by you or any other persons.  </p>
<p>
In the event you are an individual entering into this User Agreement, (i) you confirm that you are eligible to enter into a contract as per the requirements of the Indian Contracts Act, 1872; (ii) you acknowledge and understand that the information provided by you may constitute “personally identifiable sensitive information” as defined in Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data and Information) Rules, 2011 and you expressly consented to collection, storage and transmission of such information by Bintix through the Mobile Application/Website to relevant third party service providers in accordance with the terms of  this User Agreement; and (iii) this User Agreement would constitute a valid and legally binding obligation on you.  
</p>
<p>
Only individuals above the age of 18 years are permitted to register as User on the Website/ Mobile Application.  
</p>
<p>
If you are using the Bintix Services on behalf of a residential association or other legal entity (hereinafter referred to as an “Entity”), you are individually bound by this User Agreement even if your residential association etc., has a separate agreement with us. In the event you are entering into this User Agreement on behalf of any Entity, you confirm that, (i) you are the authorized representative of such Entity; (ii) all requisite authorizations have been obtained to appoint you as the authorized representative of such Entity; and (iii) this User Agreement would constitute a valid and legally binding obligation on such Entity; 
</p>
<p>
By becoming a User, you acknowledge that you have read and understood the terms and conditions of this User Agreement and that you agree to be bound by all the provisions of this User Agreement as may be revised from time to time, and you understand and agree that you are bound by such terms when you access, use, download, operate this Website/ Mobile Application or utilizing or relying on any service which forms a part of any of the Bintix Services.
</p>

<h3>4. USER INFORMATION, PRIVACY etc., </h3>
<p>The User will be required to provide details such as names, address, email, telephone number etc., on the Website/ Mobile Application. The User understands that certain information provided by him/her will qualify to be personally identifiable sensitive information under the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data and Information) Rules, 2011and the disclosure of the same is completely voluntary. We confirm that the storage of such personal information is  in compliance with the requirements under the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data and Information) Rules, 2011.  You expressly consent to the collection, storage and the aforesaid transmission of information and shall not hold Bintix liable or make any claims against Bintix in respect of the collection, storage and transmission of the aforesaid information in accordance with this User Agreement. The User also understands that the efficiency of Bintix Service is dependent on the accuracy and completeness of the information provided by you. Therefore, it is requested that you keep the information up to date. </p>

<p>
Bintix may share your personal information with other service providers for the purpose of providing Bintix Services, including, without limitation, collection agents, website hosting services,  order processing, for verifying location and demographics and visitor surveys. The Company takes commercially reasonable steps to ensure these service providers adhere to the security standards we apply to your personal information and prohibits them from using your information for any reason apart from provision of Bintix Services.
</p>

<p>
At times, Bintix will conduct customer surveys, polls etc., to understand customer behaviour, consumption preferences etc., and may collect certain personally identifiable information from you, which may be transmitted to third parties. Please note that, Bintix will procure your consent before sharing/transmitting any of your personally identifiable information collected as part of these surveys, dry waste collected from your household polls etc., with third parties. All such disclosures shall be made in compliance with applicable laws.
</p>

<p>
Bintix shall create a personal dashboard that can be accessed through your personal account on the Website/Mobile Application based on the dry waste collected from your household which shall include information on your consumption patterns and their environmental impact.  
</p>

<p>
Additionally, Bintix collects, stores, and transmits information about the dry waste collected from your household. Bintix reserves the right to share with third parties, anonymized  data with  third parties including its recyclers, with other waste aggregators, with organizations creating waste awareness, with non-governmental or governmental organizations, with waste picker associations, with circular economy research organizations, with carbon credit organization, for market research, for extended producer responsibility fulfillment, to aid policy making, and/or any other organizations working in the waste management space to promote environmentally sustainable waste management. The User hereby agrees and understands that Bintix will not require consent of the User while sharing such anonymized data. 
</p>

<p>
Bintix may use your personal information to contact you via mail, e-mail, telephone or mobile device in order to give you updates about information regarding Bintix Services availed by you, payment confirmations, current information regarding our Services, or other promotions that may be of interest to you. Please note that you will have an option to opt out of any promotional emails, newsletters or other emails sent by Bintix to you. 
</p>

<p>
The Company may also disclose your personal information as is necessary to: (i) comply with a court order; (ii) cooperate with law enforcement or other government agencies; (iii) establish or exercise its legal rights; (iv) protect the property or safety of Bintix and its employees, contractors, vendors, suppliers, and customers; (v) defend against legal claims; (vi) help with internal and external investigations; or (vii) as otherwise required by law or permitted by law. Bintix may disclose your information to others in connection with the sale, merger, acquisition  or financing of Bintix, or in connection with any transaction that involves the sale or assignment of some or all of our assets, including during the diligence process. 
</p>

<p>
Bintix is concerned with the security of the data and information it collects, and uses commercially reasonable physical, electronic and procedural safeguards to help ensure the security, integrity and privacy of all personal information. Bintix limits access to personal information about you to employees, sub-contractors, or third-party service providers who we believe reasonably need to come into contact with that information to provide service to you or in order to do their jobs. However, due to the inherent insecurity of the internet, no security measures should be viewed as 100 percent effective in all circumstances. Bintix cannot absolutely guarantee against a breach of its security or the actions of third parties who may seek to obtain unauthorized access to personal information. The User acknowledges that all transmissions of your personal information is done at your own risk. 
</p>

<h3>5.
HOW TO USE THE MOBILE APPLICATION/ WEBSITE 
</h3>

<p>
Bintix will arrange a pickup of segregated dry waste at the location specified by you in your request on the Website/Mobile Application  or from the designated collection point at your community.  You confirm to Bintix that you have the full authority to allow collection and disposal of the segregated dry waste.
</p>
<p>
Bintix shall ensure that we will take every effort to undertake the clearance of the waste on the date and at the time  agreed upon but, on some days, there may be delays due to circumstances beyond our control and in such case a revised date and time will be provided as soon as reasonably possible. 
</p>

<p>
You are to provide our personnel with free and safe access to the location from where the segregated dry waste is to be collected. Bintix or its partner’s personnel should not be requested or invited to go beyond the doorstep at the time of collection under any circumstance. You must notify Bintix of any special circumstances which may be relevant to our request, including any vehicular/personnel access issues, any waste that requires special treatment, etc. If notification is not provided by you to us on such special circumstances, or any information or instruction you provide us with is incomplete or inaccurate, we reserve the right to cancel/reschedule the clearance of the waste and a Journey Fee may be charged. 
</p>
<p>
If Bintix is of the view that any waste is not the type of waste that our company can  process, or belongs to a category that is unacceptable as per the segregation guidelines (including organic/wet waste, medical, sanitary or reject waste), Bintix reserves the right not to accept the same. Bintix reserves the right to charge additional fee for disposal of such non-standard waste.  You shall not dispose off any illegal substances/items to Bintix and the same will not be accepted under any circumstance and you will be responsible for all legal consequences arising from your actions.  
</p>

<p>
Dry waste is required to be packaged in the  manner notified by Bintix in designated bags/containers, sharp or dangerous objects like knives and broken glass should be  appropriately wrapped before disposal. 
</p>

<p>
Segregated dry waste or contents within the bag shall become the property of Bintix immediately upon handover to the Bintix’s personnel at the identified location. This shall not apply to any illegal substances, drugs, contraband etc., that is put into the disposal bag by you. Bintix shall have the right to recycle segregated dry waste through any aggregators or certified recyclers of its choice or recycle through any other environmentally sustainable means. Bintix shall not be responsible for any valuables or items disposed in the  bag and the same cannot be claimed back once the bags have been picked up by Bintix. 
</p>
<p>
In case you want to reschedule or cancel a pick-up by Bintix, you should intimate us via the Website/ Mobile Application at least _48____ hours prior to the allotted time of pick-up. Failure to reschedule or cancel a pick-up as per the stipulated timeline will incur a minimum journey fee prescribed by the Company from time to time. 
</p>

<p>
In the event,  the bags with household dry waste are  lost during transit from your household/collection location to our warehouse, the compensation payable by Bintix shall be limited to a reasonably calculated amount based on the average weight of dry waste collected by Bintix from your household in the month immediately preceding such an event. Bintix will not be held responsible for loss or damage to any filled bags during storage or handling at any time prior to scheduled collection. 
</p>

<p>
Unused Bags (provided by Bintix) shall remain the property of Bintix and will be collected back upon termination of service. Any loss of bags will be chargeable at existing cost price of the bag. Bintix reserves the rights to withhold amount equivalent to the cost of the unused bags from the customer as a security deposit. 
</p>

<h3>6. 
CONSIDERATION
</h3>

<p>In consideration of the User accepting the terms and conditions of the User Agreement and in consideration of the User handing over dry segregated waste to Bintix from time to time, Bintix shall pay to the User the agreed rate per kg of waste (“Waste Rates”) after deduction of handling charges , other charges, and Journey Fee by Bintix (“Consideration”).  The Consideration shall be  subject to applicable taxes and shall be paid as per the Bintix policy at pre-determined Waste Rates. The Waste Rates for each type of dry segregated waste shall be displayed on the Website/ Mobile Application and shall be updated from time to time.  </p>

<h3>7. RIGHT TO SUSPEND USAGE </h3>

<p>Bintix may modify, replace, refuse access to, suspend, or discontinue Bintix Services, Website, or the Mobile Application, partially or entirely, or introduce, change, and modify prices for all or part of the Website, Mobile Application or Bintix Services for you or for all Users at Bintix’s sole discretion. </p>

<p>Bintix reserves the right, in its sole discretion, to suspend or cancel any of the Bintix Services at any time if a computer virus, bug, or other technical problem corrupts the security or proper administration of any Bintix Service.</p>

<h3>8. THIRD PARTY LINKS AND CONTENT</h3>
<p>The Website and/or the Mobile Application may include links to third party web sites (“Third Party Sites”). You are responsible for evaluating whether you want to access or use a Third-Party Site. You should review any applicable terms and/or privacy policy of a Third-Party Site before using it. Bintix is not responsible for and does not endorse any features, content, advertising, products or other materials on or available from Third Party Sites. Accordingly, if you decide to access Third Party Sites, you do so at your own risk. </p>

<h3>9. DISCLAIMER</h3>
<p>Bintix disclaims all warranties or conditions, whether expressed or implied, (including without limitation implied, warranties or conditions of information and content). </p>

<h3>10. INTELLECTUAL PROPERTY RIGHTS</h3>
<p>Bintix reserve all intellectual property rights in all text, programs, products, processes, technology, design, source code, content, and other materials, which appear on the Website/ Mobile Application d. The Website/Mobile Application names and logos and all related product and service names, design marks and slogans are the trademarks or service marks of Bintix.</p>

<h3>11. LIMITATION OF LIABILITY </h3>
<p>Subject to the preceding, under no circumstances whatsoever shall Bintix be liable to you, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, for any loss of profit, or any indirect or consequential loss arising under or in connection with the contract; and Bintix’s total liability to you in respect of all other losses arising under or in connection with the contract, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, shall in no circumstances exceed the Consideration paid by us at the time of collection of waste in that particular instance.</p>
<h3>12. FORCE MAJEURE </h3>
<p>Bintix will not be liable or responsible for any failure to perform or delay in performance of, any of its obligations under these User Agreement caused by events outside of the Bintix’s reasonable control (Force Majeure Event). A Force Majeure Event includes any act, event, non-occurrence, omission or accident beyond our reasonable control and includes (a) civil commotion, riot, invasion, terrorist attack or threat of terrorist attack, war (whether declared or not) or threat or preparation for war; (b) fire, explosion, storm, flood, earthquake, subsidence, pandemic, epidemic or other natural disaster; (c) impossibility of the use of railways, shipping, aircraft, motor transport or other means of public or private transport; (d) impossibility of the use of public or private telecommunications networks. </p>

<p>Bintix’s obligations under these Terms are suspended for the period that the Force Majeure Event continues, and  Bintix will extend the time to perform these obligations for the duration of that period.  </p>

<h3>13. ASSIGNMENT</h3>
<p>You may not transfer any of your rights or obligations under these Terms to another person without our prior written consent, which we will not withhold unreasonably. We can transfer all or any of our rights and obligations under these Terms to another organisation, but this will not affect your rights under these Terms. </p>

<h3>14. NOTICES</h3>
<p>All notices sent by you to the Bintix must be sent to us at (our address) or email to (Our Email). We may give notice to you at either the email or postal address you provide to us at the time of creation of the Account. Notice will be deemed received and properly served 24 hours after an email is sent or three days after the date of posting of any letter. In proving the service of any notice, it will be sufficient to prove, in the case of a letter, that the letter was properly addressed, stamped and placed in the post and, in the case of an email that the email was sent to the specified email address of the addressee. </p>

<h3>15. GENERAL</h3>
<p>Bintix reserves the right to change, supplement, update or modify these Terms at any time without notice at the sole discretion of the Company. In the event we modify or change our Terms, the modified terms will be posted on our mobile application/website.  </p>
<p>If any of these conditions are deemed invalid, void, or for any reason unenforceable, the parties agree that the court should endeavour to give effect to the parties’ intentions as reflected in the provision, and the unenforceable condition shall be deemed severable and shall not affect the validity and enforceability of any remaining condition. </p>
<p>The Terms and the relationship between the you and the Company will be governed by the laws as applicable in India. Any disputes will be handled in the competent courts of Hyderabad, Telangana, India.  </p>

<p>The failure of the Company to act with respect to a breach by the you does not waive its right to act with respect to subsequent or similar breaches.  </p>

<p>The User Agreement constitutes the entire agreement between the you and Bintix and governs your use of the Bintix’s Services through our Mobile Application, and/or Website, superseding any prior agreements between the User and the Company with respect to the subject matter. </p>

<p>If you have any questions regarding these Terms, you can contact us by email at  hello@bintix.com   </p>
                    </div>
                    </div>
    </section>
    </>
  )
}

export default TermsPolicy