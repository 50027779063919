import { SET_IMPACT_SECTION_DATA, SET_PAGE,SET_HEADER_TYPE,SET_EMAIL,SET_LOADER} from "./Action";

const initialState = {
   impactSectionData:"",
    currentPage:"",
    headerType:1,
    email:"",
    loading:true
  };

  const reducer=(state=initialState,{type,payload})=>{
    switch (type){
        case SET_PAGE:
                return{ ...state, currentPage: payload };
        case SET_HEADER_TYPE:
                  return{ ...state, headerType: payload };
      case SET_IMPACT_SECTION_DATA:
        return{ ...state, impactSectionData: payload };
        case SET_EMAIL:
          return{ ...state, email: payload };
          case SET_LOADER:
          return{ ...state, loading: payload };
      default:
            return state;
    }
  }


  export default reducer