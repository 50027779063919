import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import home from "../../images/about-bnr.jpg";
import blg1 from "../../images/blg1.jpg";
import blg2 from "../../images/blg2.jpg";
import playBtn from "../../images/play.png"
import blg3 from "../../images/blg3.jpg";
import blg4 from "../../images/blg4.jpg";
import blg5 from "../../images/blg5.jpg";
import blg6 from "../../images/blg6.jpg";
import blg7 from "../../images/blg7.jpg";
import bicon4 from "../../images/bicon4.png"
import bicon1 from "../../images/bicon1.png"
import bicon2 from "../../images/bicon2.png"
import bicon3 from "../../images/bicon3.png"
import p10 from "../../images/p10.png"
import p11 from "../../images/p11.png"
import p12 from "../../images/p12.png"
import p13 from "../../images/p13.png"
import p14 from "../../images/sampurn.png"
import map from "../../images/map.png"
import imageUrl from "../../images/impctbg.png"
import f1 from "../../images/f1.png"
import f2 from "../../images/f2.png"
import f3 from "../../images/f3.png"
import f4 from "../../images/f4.png"
import f5 from "../../images/f5.png"
import f6 from "../../images/f6.png"
import mobilebnr from "../../images/about-mbnr.jpg"
import awrdpic from "../../images/awrdpic.png"
import awrdpic2 from "../../images/awrdpic2.png"
import digi1 from "../../images/dgit1.png"
import digi2 from "../../images/digi2.png"
import digi3 from "../../images/digi3.png"
import digi4 from "../../images/digi4.png"
import appviw from "../../images/appviw.png"
import aicon from "../../images/aicon.png"
import aicon2 from "../../images/aicon2.png"
import sw1 from "../../images/sw1.png"
import sw2 from "../../images/sw2.png"
import sw3 from "../../images/sw3.png"
import sw4 from "../../images/sw4.png"
import sw5 from "../../images/sw5.png"
import sw6 from "../../images/sw6.png"
import bwaste from "../../images/bwaste.png"
import bwaste2 from "../../images/bwaste2.png"
import bwastem from "../../images/bwastem.png"
import wtchvdo from "../../images/wthvdobg.png"
import $ from "jquery";
import './Collect-waste.css';
import { FaStar, FaAngleDown, FaPlay } from "react-icons/fa";
import WOW from 'wowjs';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getImpactSectionData, setEmailaction, setHeaderType, setLoading } from '../../Redux/ActionCreater';
import { ToastContainer, toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';
import impactBack from '../../images/impactBack.png';
import VideoModal from '../Home/VideoModal';

function CollectWasteDynamic() {
  const [video,setVideo]=useState(false);
  const [email,setEmail]=useState("")
  const navigate = useNavigate();
    const [impactData,setImpactData]=useState([])
    const dispatch = useDispatch();
    const { impactSectionData} = useSelector(
      (state) => ({
        impactSectionData: state.dataReducer.impactSectionData.data
      }),
      shallowEqual
    );

    const options = {
      loop: true,
      margin: 0,
      items: 1,
      autoplay: false,
      autoplaySpeed: 1200,
      rewind: false,
      lazyLoad: true,
      mouseDrag: false,
      touchDrag: false,
      dots: true,
      nav: false
    };
  
    const options2 = {
      loop: true,
      margin: 20,
      items: 1,
      autoplay: false,
      autoplaySpeed: 1200,
      rewind: false,
      lazyLoad: true,
      mouseDrag: true,
      touchDrag: true,
      dots: true,
      nav: false,
      responsive: {
        0: {
          items: 2,
          nav: false,
          dots: true,
          loop: true,
        },
        450: {
          items: 2,
          nav: false,
          dots: true,
          loop: true,
  
        },
        768: {
          items: 2,
          nav: false,
          dots: true,
          loop: true,
        },
        992: {
          items: 4,
          nav: false,
          loop: true,
          mouseDrag: false,
          touchDrag: false,
        },
        1200: {
          items: 4,
          nav: false,
          loop: true,
          margin: 30,
          mouseDrag: false,
          touchDrag: false,
        },
        1700: {
          items: 4,
          nav: false,
          loop: true,
          margin: 40,
          mouseDrag: false,
          touchDrag: false,
        },
        1920: {
          items: 4,
          nav: false,
          loop: true,
          margin: 50,
          mouseDrag: false,
          touchDrag: false,
        }
      }
    };
  
  
    const options4 = {
      loop: false,
      margin: 5,
      items: 1,
      autoplay: false,
      autoplaySpeed: 1200,
      rewind: false,
      lazyLoad: true,
      mouseDrag: true,
      touchDrag: true,
      dots: true,
      nav: false,
      responsive: {
        0: {
          items: 1,
          nav: false,
          dots: true,
          stagePadding: 30,
          left: -30,
        },
        450: {
          items: 1,
          nav: false,
          dots: true,
          stagePadding: 70,
          left: -30,
        },
        768: {
          items: 2,
          nav: false,
          dots: true,
          stagePadding: 40,
          left: -30,
        },
        992: {
          items: 4,
          nav: false,
          mouseDrag: false,
          touchDrag: false,
        },
        1200: {
          items: 4,
          nav: false,
          margin: 20,
          mouseDrag: false,
          touchDrag: false,
        },
        1700: {
          items: 4,
          nav: false,
          margin: 20,
          mouseDrag: false,
          touchDrag: false,
        },
        1920: {
          items: 4,
          nav: false,
          margin: 20,
          mouseDrag: false,
          touchDrag: false,
        }
      }
    };
    const options5 = {
      loop: true,
      margin: 5,
      items: 1,
      autoplay: false,
      autoplaySpeed: 1200,
      rewind: false,
      lazyLoad: true,
      mouseDrag: true,
      touchDrag: true,
      dots: true,
      nav: false,
      responsive: {
        0: {
          items: 1,
          nav: false,
          dots: true,
          loop: false,
          stagePadding: 30,
          left: -30,
        },
        450: {
          items: 1,
          nav: false,
          dots: true,
          loop: false,
          stagePadding: 30,
          left: -30,
        },
        768: {
          items: 2,
          nav: false,
          dots: true,
          loop: false,
          stagePadding: 30,
          left: -30,
        },
        992: {
          items: 3,
          nav: false,
          loop: true,
          mouseDrag: false,
          touchDrag: false,
        },
        1200: {
          items: 3,
          nav: false,
          loop: true,
          margin: 20,
          mouseDrag: false,
          touchDrag: false,
        },
        1700: {
          items: 3,
          nav: false,
          loop: true,
          margin: 20,
          mouseDrag: false,
          touchDrag: false,
        },
        1920: {
          items: 3,
          nav: false,
          loop: true,
          margin: 20,
          mouseDrag: false,
          touchDrag: false,
        }
      }
    };

    const closeModal =()=>{
      setVideo(false)
    };

  const click = (e, id) => {
    if ($('.aa' + id).is(':visible')) {
      $(".aa" + id).slideUp(600);
      $(".plusminus" + id).html('<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 320 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"></path></svg>');
    }
    else {
      $(".accordion_body").slideUp(600);
      $(".plusminus").html('<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 320 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"></path></svg>');
      $('.aa' + id).slideDown(600);
      $(".plusminus" + id).html('<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 320 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M177 159.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 255.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 329.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1z"></path></svg>');
    }
  }

  const submitEmail=(e)=>{
    e.preventDefault();
    if (email === "") {
      return toast.error("Please enter email!")
    }
    if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
      return toast.error("Please enter a valid email!")
    }
    dispatch(setEmailaction(email))
    dispatch(setLoading(true));
    navigate("/movement")
      }

  useEffect(() => {
    fetchData()
    new WOW.WOW({
      live: false
    }).init();
    let id =window.location.href.split("#")[1];
    setTimeout(() => {
      if(id){
        document.getElementById(id).scrollIntoView({
          behavior: 'smooth',block:"start"
        });
      }else{
        // window.scrollTo(0, 0);
      }
      setTimeout(() => {
        dispatch(setLoading(false))
        
      }, 2000);
    }, 1000);
  }, [])

  useEffect(() => {
    setImpactData(impactSectionData);
   }, [impactSectionData])

   const fetchData = () => {
    dispatch(getImpactSectionData());
  };

  function convertToInternationalCurrencySystem (labelValue) {

    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e+9

    ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(1) + "B"
    // Six Zeroes for Millions 
    : Math.abs(Number(labelValue)) >= 1.0e+6

    ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(1) + "M"
    // Three Zeroes for Thousands
    // : Math.abs(Number(labelValue)) >= 1.0e+3

    // ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(1) + "K"

    : Math.abs(Number(labelValue));

}
useEffect(()=>{
  dispatch(setHeaderType(1))
},[])
  return (
    <>
     <Helmet>
        <title>Bintix::Collect My Waste</title>
        <meta property="og:title" content="Bintix::Collect My Waste" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <main className='abut-page-bg'>
        <section className="bannercontainer abutbnr">
          <div id="NoiseBanner" className>

            <OwlCarousel className="owl-theme" {...options}  >

              <div className="item">
                <img className='dkstbnr' src={home} style={{ height: "auto", width: "100%" }} alt='demo'/>
                <img className='abmbnr' src={mobilebnr} style={{ height: "auto", width: "100%" }} alt='demo'/>
                <div className="banner-cap">
                  <div className="container">
                    <div className="banrcontent wow fadeInUp">
                      <h1 className='dkbntxt'>Be a part of our <br />zero waste movement</h1>
                      <h1 className='mbbntxt'>Be a part of our zero waste movement</h1>
                      <h4>Do your bit one blue bag at a time</h4>
                      <a href="/movement"  onClick={(e)=>{e.preventDefault();dispatch(setLoading(true));navigate("/movement")}}>Join The Movement</a>
                    </div>
                  </div>
                </div>
              </div>


            </OwlCarousel>
          </div>
        </section>

        <section className='award-sec'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-6 col-md-12 col-sm-12 wow fadeInUp'>
                <div className='awrdtxt'>
                  <h3>Bintix - your award winning dry waste collection partner</h3>
                  <p>We’re taking an age-old industry, mixing in <br/>some technology, sprinkling on some top-<br/>notch customer service and execution, to <br/>create an awesome product for you and <br/>mother nature.  </p>
                  <div className='rdytogat'>
                    <div className='rdybtn'>
                      <a href="/movement"  onClick={(e)=>{e.preventDefault();dispatch(setLoading(true));navigate("/movement")}}>Ready to get started?</a>
                    </div>
                    <div className='wtchbtn'>
                      <a href='' onClick={(e)=>{e.preventDefault();setVideo(true)}}><span><FaPlay /></span>Watch Video</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-6 col-md-12 col-sm-12 wow fadeInUp'>
                <div className='awrpic-sec'>
                  <img src={awrdpic} alt='demo'/>
                  <img src={awrdpic2} alt='demo'/>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="watch-jurny dnonvdo">
        <div className="container">
          <div className='wtchbg'>
            <img src={wtchvdo} alt='demo'/>
          </div>
          <div className="watch-content wow fadeInUp" data-wow-duration="2s">
            <div className="row align-items-center">
              <div className="col-lg-4 col-md-4 col-5 wow fadeInLeft" data-wow-duration="2s">
              <div className="video-box" >
                  <img className='ytb-vid' src={`https://img.youtube.com/vi/7tKZBVYWdhc/0.jpg`} alt='youtube-thumbnail' width={"254px"} height={"190px"}/>
                  <img class="playbtn" src={playBtn} onClick={()=>{setVideo(true)}} alt='demo'/>
                </div>
              </div>
              <div className="col-lg-8 col-md-8 col-7 wow fadeInRight" data-wow-duration="2s">
                <h5>Watch The Journey of the Blue Bag</h5>
                <a href='' className="rgyget">Ready to get started?</a>
              </div>
            </div>
          </div>
        </div>
      </section>


        <section className='digial-waste'>
          <div className='container'>
            <div class="bloghedng wow fadeInUp"><h2>Why Should You Give Your Waste To Bintix?</h2></div>
            <div className='row'>

              <OwlCarousel className="owl-theme" {...options4}  >

                <div className="item wow fadeInUp">
                  <div className='digitalbx wow fadeInUp'>
                    <span><img src={digi2} alt='demo'/></span>
                    <h5>Incentive</h5>
                    <p>Earn by giving your waste to Bintix</p>
                  </div>
                </div>
                <div className="item wow fadeInUp">
                  <div className='digitalbx'>
                    <span><img src={digi1} alt='demo'/></span>
                    <h5>Digitization & Traceability</h5>
                    <p>Personalized Dashboards Traceable waste tracking </p>
                  </div>
                </div>
                <div className="item wow fadeInUp">
                  <div className='digitalbx'>
                    <span><img src={digi3} alt='demo'/></span>
                    <h5>Social Inclusion</h5>
                    <p>Fair wages, improved working conditions & zero contact with waste during collection</p>
                  </div>
                </div>
                <div className="item wow fadeInUp">
                  <div className='digitalbx'>
                    <span><img src={digi4} alt='demo'/></span>
                    <h5>Zero waste to landfills & Oceans</h5>
                    <p>Guaranteed removal of every kg of plastic waste</p>
                  </div>
                </div>

              </OwlCarousel>

            </div>
          </div>

        </section>


        <section className='envrmntl-sec'>
          <div className='container'>
            <div class="bloghedng wow fadeInUp"><h2><span>Track Your Environmental Impact</span> With Personalized Dashboards!</h2>
              <p>You can access eco-statistics, earnings and pickup history online through your personalized dashboards</p>
            </div>

            <div className='appviw-sec wow fadeInUp'>
              <div className='appviwpic'>
                <img src={appviw} alt='demo'/>
                <p>Customer dashboard</p>
              </div>
              <div className='applink wow fadeInUp'>
                <p>Start Earning by removing plastic from our planet today</p>
                <a href=''>download app <img src={aicon} alt='demo'/> <img src={aicon2} alt='demo'/> </a>
              </div>
            </div>

          </div>
        </section>
        <section className='customer-sec'>
          <div className='container'>
            <h3>Loved By Our Customers</h3>
            <OwlCarousel className="owl-theme" {...options5}  >

              <div className="item">
                <div className='cutomerbx'>
                  <div className='customrinfo'>
                    <ul>
                      <li><FaStar /></li>
                      <li><FaStar /></li>
                      <li><FaStar /></li>
                      <li><FaStar /></li>
                      <li><FaStar /></li>

                    </ul>
                    <p>““Thank you for the extremely efficient and courteous service provided. I am grateful for your going out of the regular way to accommodate my pickup.””</p>
                    <div className='cutomer-dtl'>
                      <h6>Sunita Pillai</h6>
                      <span>Jubilee Hills</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className='cutomerbx'>
                  <div className='customrinfo'>
                    <ul>
                      <li><FaStar /></li>
                      <li><FaStar /></li>
                      <li><FaStar /></li>
                      <li><FaStar /></li>
                      <li><FaStar /></li>

                    </ul>
                    <p>“We had to literally search and beg other vendors to come and pick up our recyclables, can’t believe there is a formal service like Bintix now. Looking forward to getting my whole community to use Bintix and make sure you guys sustain.”</p>
                    <div className='cutomer-dtl'>
                      <h6>Satya</h6>
                      <span>Indu Fortune Villas</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className='item'>
                <div className='cutomerbx'>
                  <div className='customrinfo'>
                    <ul>
                      <li><FaStar /></li>
                      <li><FaStar /></li>
                      <li><FaStar /></li>
                      <li><FaStar /></li>
                      <li><FaStar /></li>

                    </ul>
                    <p>“I made the first booking on Bintix and it was completed on schedule. I am excited that I am finally able to recycle most of the waste from our house.”</p>
                    <div className='cutomer-dtl'>
                      <h6>Surya</h6>
                      <span>Quiet Lands Apartments</span>
                    </div>
                  </div>
                </div>
              </div>

            </OwlCarousel>


        </div>
      </section>


      <section className='making-sec mapwst'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-lg-12 wow fadeInUp'>
              <h3>Making India Waste Free</h3>
              <h6>One House & One City At a Time</h6>
            </div>
            <div className='col-lg-7 col-md-7 col-sm-12 wow fadeInUp'>
              <div className='newslettr-sec'>
                <span>Join the Movement</span>
                <h5> get your waste collected by us </h5>
                <div className="form-group">
                <div className="inputgroup">
                    <input value={email} onChange={e=>setEmail(e.target.value)} type="text" className="form-control" placeholder="Enter Your Email Address" name="email" />
                    <button onClick={submitEmail} className="subscribebtn" type="button">Join the Movement</button>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-5 col-md-5 col-sm-12 wow fadeInUp'>
              <div className='map-sec'>
                <img src={map} alt='demo'/>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='seggrate-waste' id='seggregate'>
        <div className='container'>
          <div className='seggrate-inner'>
            <h3>How Should You seggregate waste</h3>
            <p>(Use only BINTIX Blue Bags for dry-waste disposal)</p>


            <div className='all-wastege'>
              <div className='paper-waste'>
                <h5>Paper</h5>
                <div className='platicbx '>
                  <h6>(Must Be dusted if soiled)</h6>
                  <ul>
                    <li>Newspaper, Magzine</li>
                    <li>Stationery, Junk mail</li>
                    <li>Carton, Cardboards</li>
                    <li>Paper cups, plates</li>
                    <li>Food Boxes</li>
                  </ul>
                </div>
                <img src={sw1} alt='demo'/>
              </div>
              <div className='paper-waste'>
                <h5>Plastic</h5>
                <div className='platicbx'>
                  <h6>(Must Be rinsed if soiled)</h6>
                  <ul>
                    <li>Plastic covers, Bottles, boxes,Toffee Wrappers</li>
                    <li>Chip bags, Milk-curd packets  </li>
                    <li>Beverage containers including cans</li>
                    <li>Other plastics</li>
                  </ul>
                </div>
                <img src={sw2} alt='demo'/>
              </div>
              <div className='paper-waste'>
                <h5>E - Waste</h5>
                <div className='platicbx'>
                  <h6>(Handle with care)</h6>
                  <ul>
                    <li>Batteries(AA, AAA, Mobile, Remote, Etc.,.). Smaller E-Waste
                      (Wires, PC Parts Like Mouse, Keyboard and others)</li>
                  </ul>
                </div>
                <img src={sw3} alt='demo'/>
              </div>
              <div className='paper-waste'>
                <h5>Glass</h5>
                <div className='platicbx'>
                  <h6>(Handle with Care)</h6>
                  <ul>
                    <li>Unbrocken Glass Bottles</li>
                  </ul>
                </div>
                <img src={sw4} alt='demo'/>
              </div>
              <div className='paper-waste'>
                <h5>Metal</h5>
                <div className='platicbx'>
                  <ul>
                    <li>Alluminum foil, Container, Metal Cans</li>
                  </ul>
                </div>
                <img src={sw5} alt='demo'/>
              </div>
              <div className='paper-waste'>
                <h5>Others</h5>
                <div className='platicbx'>
                  <h6>(Must be dusted if soiled)</h6>
                  <ul>
                    <li>Rubber, Old Mops,
                      Dusters, Sponges,
                      Cosmetics, Foam <br/>Cups</li>
                  </ul>
                </div>
                <img src={sw6} alt='demo'/>
              </div>
            </div>

            <div className='mobile-wastege'>

              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingOne">
                    <button class="accordion-button platicbx" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      <h3>Paper</h3>
                      <span className='acordiantxt'>
                        <h6>(Must Be dusted if soiled)</h6>
                        <ul>
                          <li>Newspaper, Magzine</li>
                          <li>Stationery, Junk mail</li>
                          <li>Carton, Cardboards</li>
                          <li>Paper cups, plates</li>
                          <li>Food Boxes</li>
                        </ul>
                      </span>
                    </button>

                  </h2>
                  <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <div className='accdianimge'>
                        <img src={sw1} alt='demo'/>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button class="accordion-button collapsed platicbx" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      <h3>Plastic</h3>
                      <span className='acordiantxt'>
                        <h6>(Must Be rinsed if soiled)</h6>
                        <ul>
                          <li>Plastic covers, Bottles, boxes,Toffee Wrappers</li>
                          <li>Chip bags, Milk-curd packets  </li>
                          <li>Beverage containers including cans</li>
                          <li>Other plastics</li>
                        </ul>
                      </span>
                    </button>
                  </h2>
                  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <div className='accdianimge'>
                        <img src={sw2} alt='demo'/>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingThree">
                    <button class="accordion-button collapsed platicbx" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                      <h3>E - Waste</h3>
                      <span className='acordiantxt'>
                        <h6>(Handle with care)</h6>
                        <ul>
                          <li>Batteries(AA, AAA, Mobile, Remote, Etc.,.). Smaller E-Waste (Wires, PC Parts Like Mouse, Keyboard and others)</li>
                        </ul>
                      </span>
                    </button>
                  </h2>
                  <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <div className='accdianimge'>
                        <img src={sw3} alt='demo'/>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingThree">
                    <button class="accordion-button collapsed platicbx" type="button" data-bs-toggle="collapse" data-bs-target="#headingThree" aria-expanded="false" aria-controls="collapseThree">
                      <h3>Glass</h3>
                      <span className='acordiantxt'>
                        <h6>(Handle with Care)</h6>
                        <ul>
                          <li>    Unbrocken Glass Bottles</li>
                        </ul>
                      </span>
                    </button>
                  </h2>
                  <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <div className='accdianimge'>
                        <img src={sw4} alt='demo'/>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingFive">
                    <button class="accordion-button collapsed platicbx" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                      <h3>Metal</h3>
                      <span className='acordiantxt'>
                        <ul>
                          <li>Alluminum foil, Container, Metal Cans</li>
                        </ul>
                      </span>
                    </button>
                  </h2>
                  <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <div className='accdianimge'>
                        <img src={sw5} alt='demo'/>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingSix">
                    <button class="accordion-button collapsed platicbx" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                      <h3>Others</h3>
                      <span className='acordiantxt'>
                        <h6>(Must be dusted if soiled)</h6>
                        <ul>
                          <li>Rubber, Old Mops, Dusters, Sponges, Cosmetics, Foam Cups</li>
                        </ul>
                      </span>
                    </button>
                  </h2>
                  <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <div className='accdianimge'>
                        <img src={sw6} alt='demo'/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>


            <div className='waste-lower'>
              <div className='wstecrry'>
                <img src={bwaste2} alt='demo'/>
              </div>
              <div className='bintx-lower'>
                <img src={bwaste} alt='demo'/>
              </div>
              <div className='bintx-lower2'>
                <img src={bwastem} alt='demo'/>
              </div>
            </div>


            <div className='notcollect'>
              <h6><u>Not Collected By bintix:</u> <span>(Do not dispose)</span></h6>
              <div className='collect-info'>
                <ul>
                  <li>Any Kind of wet waste</li>
                  <li>Medical/ Sanitary</li>
                  <li>Face masks/Gloves</li>
                </ul>
                <ul>
                  <li>Slippers, shoes, clothes, leather</li>
                  <li>Wood, thermocol</li>
                  <li>Brocken glass, Tube lights, bulbs, sharps</li>
                </ul>





              </div>

            </div>

          </div>



        </div>
      </section>

      <section className='recycleacpt'>

        <div className='container'>
          <div id="faq" className='recycleacordian scondacordian'>
            <h3>Bintix FAQ</h3>

            <div className="accordion_container wow fadeInUp">
              <div className="accordion_head" onClick={(e) => click(e, 7)} data-id={7}>
                <span>  How can I book in Bintix?</span> <div className={`plusminus plusminus${7}`}><FaAngleDown /></div></div>
              <div className={"accordion_body aa" + 7} style={{ display: "none" }}>
                <p>Please click on “Express My Interest” button on home page to provide us your details, we will contact you. </p>
              </div>
            </div>
            <div className="accordion_container wow fadeInUp">
              <div className="accordion_head" onClick={(e) => click(e, 8)} data-id={8}>
                <span> Do you collect hazardous waste, mattresses, construction debris, thermocol, chemicals, or sewage?</span> <div className={`plusminus plusminus${8}`}><FaAngleDown /></div></div>
              <div className={"accordion_body aa" + 8} style={{ display: "none" }}>
                <p>You literally just listed almost everything we DON’T collect. Check the ‘Recyclables we accept’ section of bintix.com to see what we accept.</p>
              </div>
            </div>
            <div className="accordion_container wow fadeInUp">
              <div className="accordion_head" onClick={(e) => click(e, 9)} data-id={9}>
                <span> What time will the agent come for dry waste pickup? I may need to step out for a bit.</span> <div className={`plusminus plusminus${9}`}><FaAngleDown /></div></div>
              <div className={"accordion_body aa" + 9} style={{ display: "none" }}>
                <p>Your well-segregated dry waste will be picked up at the designated time for your community. Remember to keep your bags outside your door on the designated day.</p>
              </div>
            </div>
            <div className="accordion_container wow fadeInUp">
              <div className="accordion_head" onClick={(e) => click(e, 10)} data-id={10}>
                <span>  How I will get payment from Bintix?</span> <div className={`plusminus plusminus${10}`}><FaAngleDown /></div></div>
              <div className={"accordion_body aa" + 10} style={{ display: "none" }}>
                <p>Talk about options! We can pay you through UPI, Paytm or directly transfer to your bank using good ol' NEFT! All this can be controlled using your dashboard.</p>
              </div>
            </div>
            <div className="accordion_container wow fadeInUp">
              <div className="accordion_head" onClick={(e) => click(e, 11)} data-id={11}>
                <span>  We are vacating our house. Can you please arrange a pickup immediately?</span> <div className={`plusminus plusminus${11}`}><FaAngleDown /></div></div>
              <div className={"accordion_body aa" + 11} style={{ display: "none" }}>
                <p>Bintix is probably not the best place to give your move-related items.</p>
              </div>
            </div>
            <div className="accordion_container wow fadeInUp">
              <div className="accordion_head" onClick={(e) => click(e, 12)} data-id={12}>
                <span>  Is there a minimum quantity that you’ll pick up? Like, if I only have 1 Coke bottle will you come for that?</span> <div className={`plusminus plusminus${12}`}><FaAngleDown /></div></div>
              <div className={"accordion_body aa" + 12} style={{ display: "none" }}>
                <p>Indeed, no minimum at all. We don’t have a minimum volume because we want this to be as simple as possible for you. Of course, we would love it if you have at least 5 – 10 kg to make it worth everyone’s time, but it’s up to you!</p>
              </div>
            </div>
            <div className="accordion_container wow fadeInUp">
              <div className="accordion_head" onClick={(e) => click(e, 13)} data-id={13}>
                <span>  Can you send a Bintix agent to collect from my entire housing society?</span> <div className={`plusminus plusminus${13}`}><FaAngleDown /></div></div>
              <div className={"accordion_body aa" + 13} style={{ display: "none" }}>
                <p>Your housing association signs your community up with Bintix.</p>
              </div>
            </div>
            <div className="accordion_container wow fadeInUp">
              <div className="accordion_head" onClick={(e) => click(e, 14)} data-id={14}>
                <span>  I’ve got more than 100 kg of waste? Is Bintix for me?</span> <div className={`plusminus plusminus${14}`}><FaAngleDown /></div></div>
              <div className={"accordion_body aa" + 14} style={{ display: "none" }}>
                <p>No, 100 kg at one time is difficult. Our agents, although very handy, are still unable to lift 100 kg in one go. We can, however, collect 50 kg in two separate pickups.</p>
              </div>
            </div>
            <div className="accordion_container wow fadeInUp">
              <div className="accordion_head" onClick={(e) => click(e, 15)} data-id={15}>
                <span>  So, is this Bintix thing really free for me?</span> <div className={`plusminus plusminus${15}`}><FaAngleDown /></div></div>
              <div className={"accordion_body aa" + 15} style={{ display: "none" }}>
                <p>Yup, a Bintix pickup is completely worthless.</p>
              </div>
            </div>
            <div className="accordion_container wow fadeInUp">
              <div className="accordion_head" onClick={(e) => click(e, 16)} data-id={16}>
                <span>  How to contact Bintix?</span> <div className={`plusminus plusminus${16}`}><FaAngleDown /></div></div>
              <div className={"accordion_body aa" + 16} style={{ display: "none" }}>
                <p>Please mail to hello@bintix.com. We will get back to you.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="blog-sec wow fadeInUp clwstelog">
        <div className="container">
          <div className="bloginfo">
            <div className="bloghedng">
              <h2>It All Starts With a Blue bag &amp; Lots of tech</h2>
            </div>

            <OwlCarousel className="owl-theme" {...options2}  >

              <div className="item">
                <div className="blgnmbr"><span>1</span></div>
                <div className="blogbox">
                  <div className="blog-txt">
                    <span><img src={bicon1} alt='demo'/></span>
                    <h4>QR Coded bin bags collected from houses weekly.</h4>
                  </div>
                  <div className="blogpic">
                    <div className="bpic"><img src={blg1} alt='demo'/></div>
                    <div className="bpic"><img src={blg2} alt='demo'/></div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="blgnmbr"><span>2</span></div>
                <div className="blogbox">
                  <div className="blogpic">
                    <div className="bpic"><img src={blg3} alt='demo'/></div>
                    <div className="bpic"><img src={blg4} alt='demo'/></div>
                  </div>
                  <div className="blog-txt">
                    <h4>AI/ML Used to capture Data of each SKU/Bag</h4>
                    <span><img src={bicon2} alt='demo'/></span>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="blgnmbr"><span>3</span></div>
                <div className="blogbox">
                  <div className="blog-txt">
                    <span><img src={bicon3} alt='demo'/></span>
                    <h4>Advanced Analytics For Consumption insights</h4>
                  </div>
                  <div className="blogpic">
                    <div className="bpic"><img src={blg5} alt='demo'/></div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="blgnmbr"><span>4</span></div>
                <div className="blogbox">
                  <div className="blogpic">
                    <div className="bpic"><img src={blg6} alt='demo'/></div>
                    <div className="bpic"><img src={blg7} alt='demo'/></div>
                  </div>
                  <div className="blog-txt">
                    <h4>Waste is <br/>recycled, repurposed, removed</h4>
                    <span><img src={bicon4} alt='demo'/></span>
                  </div>
                </div>
              </div>


            </OwlCarousel>

          </div>
        </div>
      </section>
      <section className='partner-sec mbtomr'>
        <div className='container'>

        <div className='prtnr-heading wow fadeInUp' data-wow-duration="2s">
            <h3>Our Partners</h3>
            <p></p>
            {/* <p>Ground & Tech Partners</p> */}
          </div>
          
          <div className='prtnviw wow fadeInUp'>
            <div className='prtnrlgo'><img src={p10} alt='demo'/></div>
            <div className='prtnrlgo'><img src={p11} alt='demo'/></div>
            <div className='prtnrlgo'><img src={p12} alt='demo'/></div>
          </div>
          <div className='prtnviw lstlgo wow fadeInUp'>
            <div className='prtnrlgo'><img src={p13} alt='demo'/></div>
            <div className='prtnrlgo'><img src={p14} alt='demo'/></div>
          </div>

          <div className='text-center wow fadeInUp'>
              <a href="/impact#partners"  onClick={(e)=>{e.preventDefault();dispatch(setLoading(true));navigate("/impact#partners")}} className='viwallfq'>Learn More</a>
            </div>

        </div>
      </section>

      <section className='impact-details impactRedsg cltmywsteimpct' style={{ backgroundImage: `url(${impactBack})` }} >
        <div className='container'>
          <div className='impct-hed wow fadeInUp'>
            <h3>Our Impact</h3>
            <p className='dkstpimpct' > Socially Impactful Business Innovations
 <br/>That Are In Line With The U.N. Sustainable Development Goals.</p>
          </div>
          <div className="impectN">
            <div className='row'>
              <div className='col-lg-4 col-md-4 col-sm-12 wow fadeInUp'>
                <div className='tones-sec'>
                  <div className='impct-catg'>
                    <span><img src={f1} alt='demo'/></span>
                    {impactData&&<h5>{(impactData.kgs/1000).toFixed()} <sub>Tonnes</sub></h5>}
                    {/* <h5>15000 <sub>Tonnes</sub></h5> */}
                  </div>
                  <p>Waste Prevented from Landfills & Oceans</p>
                </div>
              </div>
              <div className='col-lg-4 col-md-4 col-sm-12 wow fadeInUp'>
                <div className='tones-sec'>
                  <div className='impct-catg'>
                    <span><img src={f2} alt='demo'/></span>
                    {impactData&&<h5>{convertToInternationalCurrencySystem(impactData.co_2)}<sub>KGs</sub></h5>}
                  </div>
                  <p>Averted</p>
                </div>
              </div>
              <div className='col-lg-4 col-md-4 col-sm-12 wow fadeInUp'>
                <div className='tones-sec'>
                  <div className='impct-catg'>
                    <span><img src={f3} alt='demo'/></span>
                    {impactData&&<h5>~{impactData.trees}</h5>}
                  </div>
                  <p>Trees Saved</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className='col-lg-4 col-md-4 col-sm-12 wow fadeInUp'>
                <div className='tones-sec'>
                  <div className='impct-catg'>
                    <span><img src={f4} alt='demo'/></span>
                    {impactData&&<h5>{impactData.families_impacted} <sub>Families </sub></h5>}
                  </div>
                  <p>Of waste champions impacted</p>
                </div>
              </div>
              <div className='col-lg-4 col-md-4 col-sm-12 wow fadeInUp'>
                <div className='tones-sec'>
                  <div className='impct-catg'>
                    <span><img src={f5} alt='demo'/></span>
                    {impactData&&<h5>~{impactData.households}</h5>}
                  </div>
                  <p>Households have <br/>joined the movement <br/>across 7 cities</p>
                </div>
              </div>
              <div className='col-lg-4 col-md-4 col-sm-12 wow fadeInUp'>
                <div className='tones-sec'>
                  <div className='impct-catg'>
                    <span><img src={f6} alt='demo'/></span>
                    {impactData&&<h5>{convertToInternationalCurrencySystem(impactData.pickups)}</h5>}
                  </div>
                  <p>Pickups of waste from homes</p>
                </div>
              </div>
            </div>
          </div>

        </div>
       
      </section>

      <div className='text-center wow fadeInUp'>
              <a href="/movement"  onClick={(e)=>{e.preventDefault();dispatch(setLoading(true));navigate("/movement")}} className='viwallfq2'>Collect My Waste</a>
            </div>
    </main >
    {video&&<VideoModal youtube_id={"7tKZBVYWdhc"} closeModal={closeModal}/>}
<ToastContainer />
    </>

  )
}

export default CollectWasteDynamic


