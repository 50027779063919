import React, { useState } from 'react'
import contactpic from "../../images/contactpic.jpg"
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { BASE_URL } from '../../config';

function ContactUsForm() {
    const [formData,setFormData]=useState({
        name:"",
        email:"",
        subject:"",
        comment:"",
    })

    let onChangeHandle=(e)=>{
        if(e.target.name === "name"){
            var reg = /^[a-zA-Z ]+$/;
            if ((reg.test(e.target.value) || e.target.value === "") ){
                setFormData({ ...formData, [e.target.name]: e.target.value });
            }
        }
         else {
        setFormData({...formData,[e.target.name]:e.target.value})
         }
    }

    let handleSubmit=async(e)=>{
        e.preventDefault()
        try {
            if(formData.name===""){
                return toast.error("Please enter name!")
            }
            if (!/^[a-zA-Z ]+$/.test(formData.name)) {
                return toast.error("Please enter only alphabets in name!")
            }
            if(formData.email===""){
                return toast.error("Please enter email!")
            }
            if(!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(formData.email)){
                return toast.error("Please enter a valid email!")
            }
            if(formData.subject===""){
                return toast.error("Please enter subject!")
            }
            if(formData.comment===""){
                return toast.error("Please enter comment!")
            }
            let data = await axios.post(`${BASE_URL}/contact-us`,{
                "name": formData.name,
                "email": formData.email,
                "subject": formData.subject,
                "message": formData.comment
            }).then((res)=>{
                if(res.data.status===200){
                    toast.success(res.data.msg);
                    setFormData({
                        name:"",
                        email:"",
                        subject:"",
                        comment:"",
                    })
                }else{
                    toast.error(res.data.msg)
                }
            })
        } catch (error) {
            console.log(error)
        }
    }
  return (
    <section className='abut-ontact-from' id='contact'>
    <div className='container'>
        <div className='row align-items-center'>
            <div className='col-lg-6'>
                <div className='cntctpic'>
                    <img src={contactpic} alt='demo'/>
                </div>
            </div>
            <div className='col-lg-6'>
                <div className='cnctfrm'>
                    <form>
                        <input name='name' value={formData.name} onChange={onChangeHandle} className='form-control' type='text' placeholder='Name *' />
                        <input name='email' value={formData.email} onChange={onChangeHandle} className='form-control' type='text' placeholder='E-mail *' />
                        <input name='subject' value={formData.subject} onChange={onChangeHandle} className='form-control' type='text' placeholder='Subject *' />
                        <textarea name='comment' value={formData.comment} onChange={onChangeHandle} className='form-control' placeholder='Comment or Message *'></textarea>
                        <div className='text-end'>
                            <button onClick={handleSubmit}>Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <ToastContainer/>
</section>
  )
}

export default ContactUsForm