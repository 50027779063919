import React, { useEffect, useState } from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './Movement.css';
import brd from "../../images/brd.png"
import { FaStar, FaAngleDown } from "react-icons/fa";
import "swiper/css";
import $ from "jquery";
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { BASE_URL } from '../../config';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setEmailaction, setHeaderType, setLoading } from '../../Redux/ActionCreater';
import { Helmet } from 'react-helmet-async';


function Movement() {
    let dispatch=useDispatch()
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        mobile: "",
        localicy:"",
        city: "",
        pin: "",
        how: "",
        segregate: ""
    })
    const { email } = useSelector(
        (state) => ({
          email: state.dataReducer.email
        }),
        shallowEqual
      )

    const options5 = {
        loop: true,
        margin: 5,
        // height:600,
        items: 1,
        autoplay: false,
        autoplaySpeed: 1200,
        rewind: false,
        lazyLoad: true,
        mouseDrag: true,
        touchDrag: true,
        dots: true,
        nav: false,
        responsive: {
            0: {
                items: 1,
                nav: false,
                dots: true,
                loop: false,
                stagePadding: 30,
                left: -30,
            },
            450: {
                items: 1,
                nav: false,
                dots: true,
                loop: false,
                stagePadding: 30,
                left: -30,
            },
            768: {
                items: 2,
                nav: false,
                dots: true,
                loop: false,
                stagePadding: 30,
                left: -30,
            },
            992: {
                items: 3,
                nav: false,
                loop: true,
                mouseDrag: false,
                touchDrag: false,
            },
            1200: {
                items: 3,
                nav: false,
                loop: true,
                margin: 20,
                mouseDrag: false,
                touchDrag: false,
            },
            1700: {
                items: 3,
                nav: false,
                loop: true,
                margin: 20,
                mouseDrag: false,
                touchDrag: false,
            },
            1920: {
                items: 3,
                nav: false,
                loop: true,
                margin: 20,
                mouseDrag: false,
                touchDrag: false,
            }
        }
    };


    const click = (e, id) => {
        if ($('.aa' + id).is(':visible')) {
            $(".aa" + id).slideUp(600);
            $(".plusminus" + id).html('<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 320 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"></path></svg>');
        }
        else {
            $(".accordion_body").slideUp(600);
            $(".plusminus").html('<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 320 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"></path></svg>');
            $('.aa' + id).slideDown(600);
            $(".plusminus" + id).html('<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 320 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M177 159.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 255.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 329.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1z"></path></svg>');
        }
    }


    let onChangeHandle = (e) => {
        if (e.target.name === "mobile"||e.target.name==="pin") {
            var reg = /^\d+$/;
            if ((reg.test(e.target.value) || e.target.value === "") ){
                setFormData({ ...formData, [e.target.name]: e.target.value });
            }
        }else if(e.target.name === "name"||e.target.name === "city"){
            var reg = /^[a-zA-Z ]+$/;
            if ((reg.test(e.target.value) || e.target.value === "") ){
                setFormData({ ...formData, [e.target.name]: e.target.value });
            }
        }
        
        else {
        setFormData({ ...formData, [e.target.name]: e.target.value })
        }
    }

    let handleSubmit = async (e) => {
        e.preventDefault()
        try {
            if (formData.name === "") {
                return toast.error("Please enter name!")
            }
            if (formData.email === "") {
                return toast.error("Please enter email!")
            }
            if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(formData.email)) {
                return toast.error("Please enter a valid email!")
            }
            if (formData.mobile === "") {
                return toast.error("Please enter mobile number!")
            }
            if (formData.mobile.length !== 10) {
                return toast.error("Please enter a valid mobile number!")
            }
            if (formData.localicy === "") {
                return toast.error("Please enter localicy!")
            }
            if (formData.city === "") {
                return toast.error("Please enter city!")
            }
            if (formData.pin === "") {
                return toast.error("Please enter pin code!")
            }
            if (formData.how === "") {
                return toast.error("Please select  How did you hear about us!")
            }
            if (formData.segregate === "") {
                return toast.error(" Do You Segregate Your Waste?")
            }
            let data = await axios.post(`${BASE_URL}/newLeadV2`, {
                "name": formData.name,
                "contact": formData.email,
                "phone": formData.mobile,
                "locality": formData.localicy,
                "pincode": formData.pin,
                "city": formData.city,
                "segregate_waste": formData.segregate,
                "how_did_you_hear_about_us": formData.how
            }).then((res) => {
                if (res.data.status === 200) {
                    toast.success(res.data.msg);
                    setFormData({
                        name: "",
                        email: "",
                        mobile: "",
                        localicy:"",
                        city: "",
                        pin: "",
                        how: "",
                        segregate: ""
                    })
                } else {
                    toast.error(res.data.msg)
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(()=>{
        setFormData({...formData,email:email})
        dispatch(setHeaderType(2))
        window.scrollTo(0,0);
        setTimeout(() => {
            dispatch(setLoading(false))
            
          }, 1000);
        dispatch(setEmailaction(""))
    },[])


    return (
        <>
        <Helmet>
        <title>Bintix::Movement</title>
        <meta property="og:title" content="Bintix::Movement" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
            <section className='movement-sec'>
                <div className='container'>
                    <div className='movement-box'>
                        <span className='brdpic'><img src={brd} alt='demo'/></span>
                        <div className='movenent-inner'>
                            <h3>Join the movement </h3>

                            <div className='detsp'>
                                <p>Please Enter your details to express interest</p>
                            </div>

                            <div className='frmfield'>
                                <input name='name' value={formData.name} onChange={onChangeHandle} className='inputfldtxt' type='text' placeholder='Full Name' />
                            </div>
                            <div className='frmfield'>
                                <input name='email' value={formData.email} onChange={onChangeHandle} className='inputfldtxt' type='text' placeholder='Email' />
                            </div>
                            <div className='frmfield'>
                                <input name='mobile' value={formData.mobile} onChange={onChangeHandle} className='inputfldtxt' type='text' placeholder='Mobile' />
                            </div>
                            <div className='frmfield'>
                                <input name='localicy' value={formData.localicy} onChange={onChangeHandle} className='inputfldtxt' type='text' placeholder='Locality' />
                            </div>
                            <div className='frmfield'>
                                <input name='city' value={formData.city} onChange={onChangeHandle} className='inputfldtxt' type='text' placeholder='City' />
                            </div>
                            <div className='frmfield'>
                                <input name='pin' value={formData.pin} onChange={onChangeHandle} className='inputfldtxt' type='text' placeholder='Pincode' />
                            </div>

                            <div className='frmfield'>
                                <span className='drpicn'><FaAngleDown /></span>
                                <select name='how' value={formData.how} onChange={onChangeHandle} className='inputfldtxt'>
                                    <option value="">How did you hear about us?</option>
                                    <option value={"Existing Bintix users"}>Existing Bintix users</option>
                                    <option value={"My housing society"}>My housing society</option>
                                    <option value={"Friends/family"}>Friends/family</option>
                                    <option value={"Awenest"}>Awenest</option>
                                    <option value={"Facebook"}>Facebook</option>
                                    <option value={"Instagram"}>Instagram</option>
                                    <option value={"Others"}>Others</option>

                                </select>
                            </div>
                            <h5>Do you segregate your waste?</h5>
                            <p>We pick up all types of dry, non-hazardous household waste,
                                and not just newspaper or carton waste.</p>

                            <div className='frmfield'>
                                <div class="customRadio " onClick={() => { setFormData({ ...formData, segregate: "1" }) }}>
                                    <input type="radio" name="power" value="3" id="option-1" />
                                    <label for="option-1" class="option option-1">Yes</label>
                                </div>
                                <div class="customRadio " onClick={() => { setFormData({ ...formData, segregate: "0" }) }}>
                                    <input type="radio" name="power" value="3" id="option-2" />
                                    <label for="option-2" class="option option-2">No</label>
                                </div>
                            </div>

                            <div className='frmfield'>
                                <button onClick={handleSubmit}>Submit</button>
                            </div>
                        </div>


                    </div>




                </div>
            </section>

            <section className='recycleacpt momnfaqbg movmntfq'>

                <div className='container'>


                    <div className='recycleacordian scondacordian '>
                        <h3>Bintix FAQ</h3>

                        <div className="accordion_container wow fadeInUp">
                            <div className="accordion_head" onClick={(e) => click(e, 7)} data-id={7}>
                                <span>  How can I book in Bintix?</span> <div className={`plusminus plusminus${7}`}><FaAngleDown /></div></div>
                            <div className={"accordion_body aa" + 7} style={{ display: "none" }}>
                                <p>Please click on Notify me button on home page to provide us your details, we will contact you. </p>
                            </div>
                        </div>
                        <div className="accordion_container wow fadeInUp">
                            <div className="accordion_head" onClick={(e) => click(e, 8)} data-id={8}>
                                <span> Do you collect hazardous waste, mattresses, construction debris, thermocol, chemicals, or sewage?</span> <div className={`plusminus plusminus${8}`}><FaAngleDown /></div></div>
                            <div className={"accordion_body aa" + 8} style={{ display: "none" }}>
                                <p>You literally just listed almost everything we DON’T collect. Check the ‘Recyclables we accept’ section of bintix.com to see what we accept.</p>
                            </div>
                        </div>
                        <div className="accordion_container wow fadeInUp">
                            <div className="accordion_head" onClick={(e) => click(e, 9)} data-id={9}>
                                <span> What time will the agent come for dry waste pickup? I may need to step out for a bit.</span> <div className={`plusminus plusminus${9}`}><FaAngleDown /></div></div>
                            <div className={"accordion_body aa" + 9} style={{ display: "none" }}>
                                <p>Your well-segregated dry waste will be picked up at the designated time for your community. Remember to keep your bags outside your door on the designated day.</p>
                            </div>
                        </div>
                        <div className="accordion_container wow fadeInUp">
                            <div className="accordion_head" onClick={(e) => click(e, 10)} data-id={10}>
                                <span>  How I will get payment from Bintix?</span> <div className={`plusminus plusminus${10}`}><FaAngleDown /></div></div>
                            <div className={"accordion_body aa" + 10} style={{ display: "none" }}>
                                <p>Talk about options! We can pay you through UPI, Paytm or directly transfer to your bank using good ol' NEFT! All this can be controlled using your dashboard.</p>
                            </div>
                        </div>
                        <div className="accordion_container wow fadeInUp">
                            <div className="accordion_head" onClick={(e) => click(e, 11)} data-id={11}>
                                <span>  We are vacating our house. Can you please arrange a pickup immediately?</span> <div className={`plusminus plusminus${11}`}><FaAngleDown /></div></div>
                            <div className={"accordion_body aa" + 11} style={{ display: "none" }}>
                                <p>Bintix is probably not the best place to give your move-related items.</p>
                            </div>
                        </div>
                        <div className="accordion_container wow fadeInUp">
                            <div className="accordion_head" onClick={(e) => click(e, 12)} data-id={12}>
                                <span>  Is there a minimum quantity that you’ll pick up? Like, if I only have 1 Coke bottle will you come for that?</span> <div className={`plusminus plusminus${12}`}><FaAngleDown /></div></div>
                            <div className={"accordion_body aa" + 12} style={{ display: "none" }}>
                                <p>Indeed, no minimum at all. We don’t have a minimum volume because we want this to be as simple as possible for you. Of course, we would love it if you have at least 5 – 10 kg to make it worth everyone’s time, but it’s up to you!</p>
                            </div>
                        </div>
                        <div className="accordion_container wow fadeInUp">
                            <div className="accordion_head" onClick={(e) => click(e, 13)} data-id={13}>
                                <span>  Can you send a Bintix agent to collect from my entire housing society?</span> <div className={`plusminus plusminus${13}`}><FaAngleDown /></div></div>
                            <div className={"accordion_body aa" + 13} style={{ display: "none" }}>
                                <p>Your housing association signs your community up with Bintix.</p>
                            </div>
                        </div>
                        <div className="accordion_container wow fadeInUp">
                            <div className="accordion_head" onClick={(e) => click(e, 14)} data-id={14}>
                                <span>  I’ve got more than 100 kg of waste? Is Bintix for me?</span> <div className={`plusminus plusminus${14}`}><FaAngleDown /></div></div>
                            <div className={"accordion_body aa" + 14} style={{ display: "none" }}>
                                <p>No, 100 kg at one time is difficult. Our agents, although very handy, are still unable to lift 100 kg in one go. We can, however, collect 50 kg in two separate pickups.</p>
                            </div>
                        </div>
                        <div className="accordion_container wow fadeInUp">
                            <div className="accordion_head" onClick={(e) => click(e, 15)} data-id={15}>
                                <span>  So, is this Bintix thing really free for me?</span> <div className={`plusminus plusminus${15}`}><FaAngleDown /></div></div>
                            <div className={"accordion_body aa" + 15} style={{ display: "none" }}>
                                <p>Yup, a Bintix pickup is completely worthless.</p>
                            </div>
                        </div>
                        <div className="accordion_container wow fadeInUp">
                            <div className="accordion_head" onClick={(e) => click(e, 16)} data-id={16}>
                                <span>  How to contact Bintix?</span> <div className={`plusminus plusminus${16}`}><FaAngleDown /></div></div>
                            <div className={"accordion_body aa" + 16} style={{ display: "none" }}>
                                <p>Please mail to hello@bintix.com. We will get back to you.</p>
                            </div>
                        </div>


                    </div>
                </div>
            </section>


            <section className='customer-sec custmbcmntbg movmntfq'>
                <div className='container'>
                    <h3>What Our Happy Users Says</h3>
                    <OwlCarousel className="owl-theme" {...options5}  >

                        <div className="item">
                            <div className='cutomerbx'>
                                <div className='customrinfo'>
                                    <ul>
                                        <li><FaStar /></li>
                                        <li><FaStar /></li>
                                        <li><FaStar /></li>
                                        <li><FaStar /></li>
                                        <li><FaStar /></li>

                                    </ul>
                                    <p>“Thank you for the extremely efficient and courteous service provided.
                                        I am grateful for your going out of the regular way to accommodate my pickup.”</p>
                                    <div className='cutomer-dtl'>
                                        <h6>JSunita Pillai</h6>
                                        <span> Jubilee Hills</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className='cutomerbx'>
                                <div className='customrinfo'>
                                    <ul>
                                        <li><FaStar /></li>
                                        <li><FaStar /></li>
                                        <li><FaStar /></li>
                                        <li><FaStar /></li>
                                        <li><FaStar /></li>

                                    </ul>
                                    <p>“We had to literally search and beg other vendors to come and pick up our recyclables,
                                        can’t believe there is a formal service in Bintix now. Looking forward to getting my whole
                                        community to use Bintix and make sure you guys sustain.”</p>
                                    <div className='cutomer-dtl'>
                                        <h6>Satya</h6>
                                        <span>Indu Fortune Villas</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='item'>
                            <div className='cutomerbx'>
                                <div className='customrinfo'>
                                    <ul>
                                        <li><FaStar /></li>
                                        <li><FaStar /></li>
                                        <li><FaStar /></li>
                                        <li><FaStar /></li>
                                        <li><FaStar /></li>

                                    </ul>
                                    <p>“I made the first booking on Bintix and it was completed on schedule.
                                        I am excited that I am finally able to recycle most of the waste from our house.”</p>
                                    <div className='cutomer-dtl'>
                                        <h6>Surya</h6>
                                        <span>Quiet Lands Apartments</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </OwlCarousel>


                </div>
            </section>


<ToastContainer/>
        </>

    )
}

export default Movement