import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import { useNavigate } from 'react-router-dom'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import home from "../../images/banner.webp"
// import home from "../../images/banner.jpg"
import mobilebnr from "../../images/mobile-bnr.webp"
// import mobilebnr from "../../images/mobile-bnr.jpg"
import { useDispatch } from 'react-redux';
import { setLoading } from '../../Redux/ActionCreater';
import { LazyLoadImage } from 'react-lazy-load-image-component';
function BannerSection() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const options = {
    loop: false,
    margin: 0,
    items: 1,
    autoplay: false,
    autoplaySpeed: 1200,
    rewind: false,
    lazyLoad: true,
    mouseDrag: false,
    touchDrag: false,
    dots: true,
    nav: false
  };
  return (
    <section className="bannercontainer">
    <div id="NoiseBanner" className>

      <OwlCarousel className="owl-theme" {...options}  >

        <div className="item">
            <LazyLoadImage
              className='dkstbnr'
              height={"688px"}
              src={home}
              width={"1200px"} />
              <LazyLoadImage
              className='mbnr'
              src={mobilebnr}
              width={"390px"}
              height={"679px"} />
          {/* <img className='dkstbnr' src={home} style={{ height: "auto", width: "100%" }} /> */}
          {/* <img className='mbnr' src={mobilebnr} style={{ height: "auto", width: "100%" }} /> */}
          <div className="banner-cap">
            <div className="container">
              <div className="banrcontent wow fadeInUp">
                <h1 className='dkbntxt'>First Ever<br /> “Insights From Waste”<br /> Movement</h1>
                <h1 className='mbbntxt'>First Ever “Insights From Waste” Movement</h1>
                <h4>harnessing data from waste to remove waste from planet</h4>
                <a href="/movement" onClick={(e) => { e.preventDefault();dispatch(setLoading(true)); navigate("/movement") }}>Join The Movement</a>
              </div>
            </div>
          </div>
        </div>


      </OwlCarousel>
    </div>
  </section>
  )
}

export default BannerSection