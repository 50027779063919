import React from 'react';
import coming_soon from "../../images/coming-soon.png";

function ComingSoonModal({ closeModal }) {
	return (
		<div className="modal modal-md fade show" style={{ background: "rgba(0, 0, 0, 0.80)", display: "flex" }} id="comingSoonModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div className="modal-dialog" role="document">
				<div className="modal-content" style={{marginTop: "20%"}}>
					<div className="modal-body">
						<button type="button" onClick={closeModal} className="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
						<img src={coming_soon} alt='coming_soon' height="450px"/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ComingSoonModal