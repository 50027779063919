import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import BannerSection from './BannerSection'
import './home.css';
import ImpactSection from './ImpactSection';
import WOW from 'wowjs';
import pur from "../../images/pur.webp";
// import pur from "../../images/pur.jpg";
import vsn from "../../images/vsn.webp";
// import vsn from "../../images/vsn.jpg";
import blg1 from "../../images/blg1.webp";
// import blg1 from "../../images/blg1.jpg";
// import blg2 from "../../images/blg2.jpg";
// import blg3 from "../../images/blg3.jpg";
// import blg4 from "../../images/blg4.jpg";
// import blg5 from "../../images/blg5.jpg";
// import blg6 from "../../images/blg6.jpg";
// import blg7 from "../../images/blg7.jpg";
import blg2 from "../../images/blg2.webp";
import blg3 from "../../images/blg3.webp";
import blg4 from "../../images/blg4.webp";
import blg5 from "../../images/blg5.webp";
import blg6 from "../../images/blg6.webp";
import blg7 from "../../images/blg7.webp";
import misn from "../../images/misn.webp";
// import misn from "../../images/misn.jpg";
import tbg from "../../images/tbg.png"
import b1 from "../../images/b1.png"
import b2 from "../../images/b2.png"
import b3 from "../../images/b3.png"
import b4 from "../../images/b4.png"
import b5 from "../../images/b5.png"
import b6 from "../../images/b6.png"
import b7 from "../../images/b7.png"
import b8 from "../../images/b8.png"
import bicon4 from "../../images/bicon4.png"
import bicon1 from "../../images/bicon1.png"
import bicon2 from "../../images/bicon2.png"
import bicon3 from "../../images/bicon3.png"
import analytic from "../../images/analytic.webp"
// import analytic from "../../images/analytic.png"
import garbeg from "../../images/garbeg.jpg"
import hm1 from "../../images/hm1.png"
import hm2 from "../../images/hm2.png"
import hm3 from "../../images/hm3.png"
import hm4 from "../../images/hm4.png"
import Google_2015_logo from "../../images/Google_2015_logo.png"
import hm5 from "../../images/hm5.png"
import studying from "../../images/studying.webp"
// import studying from "../../images/studying.png"
import barcode from "../../images/barcode.png"
import wstestore from "../../images/wste-store.jpg"
// import w1 from "../../images/w1.png"
// import w2 from "../../images/w2.png"
// import w3 from "../../images/w3.png"
// import w4 from "../../images/w4.png"
// import w5 from "../../images/w5.png"
// import w6 from "../../images/w6.png"
import w1 from "../../images/w1.webp"
import w2 from "../../images/w2.webp"
import w3 from "../../images/w3.webp"
import w4 from "../../images/w4.webp"
import w5 from "../../images/w5.webp"
import w6 from "../../images/w6.webp"
import p1 from "../../images/p1.webp"
// import p1 from "../../images/p1.png"
// import p2 from "../../images/p2.png"
import p2 from "../../images/p2.webp"
import p3 from "../../images/p3.png"
import p4 from "../../images/p4.webp"
// import p4 from "../../images/p4.png"
import p5 from "../../images/p5.png"
// import p6 from "../../images/p6.webp"
import p6 from "../../images/p6.png"
import p7 from "../../images/p7.png"
import p8 from "../../images/p8.webp"
// import p8 from "../../images/p8.png"
import p9 from "../../images/p9.webp"
// import p9 from "../../images/p9.png"
import p10 from "../../images/p10.webp"
// import p10 from "../../images/p10.png"
import p11 from "../../images/p11.webp"
// import p11 from "../../images/p11.png"
import p12 from "../../images/p12.webp"
// import p12 from "../../images/p12.png"
import p13 from "../../images/p13.webp"
// import p13 from "../../images/p13.png"
import p14 from "../../images/p14.png"
import tem from "../../images/tem.webp"
// import tem from "../../images/tem.jpg"
import tem5 from "../../images/tem5.png"
import pple1 from "../../images/pple1.jpg"
import pple3 from "../../images/pple3.webp"
// import pple3 from "../../images/pple3.jpg"
import pple4 from "../../images/pple4.jpg"
import pple5 from "../../images/pple5.jpg"
import pple6 from "../../images/pple6.webp"
// import pple6 from "../../images/pple6.jpg"
import pple7 from "../../images/pple7.jpg"
// import pple8 from "../../images/pple8.jpg"
import pple8 from "../../images/pple8.webp"
import pple10 from "../../images/pple10.jpg"
// import pple12 from "../../images/pple12.jpg"
import pple12 from "../../images/pple12.webp"
import pple14 from "../../images/pple14.jpg"
import pple16 from "../../images/pple16.webp"
// import pple16 from "../../images/pple16.jpg"
import pple17 from "../../images/pple17.jpg"
import pple18 from "../../images/pple18.jpg"
import pple19 from "../../images/pple19.jpg"
import pple22 from "../../images/pple22.webp"
// import pple22 from "../../images/pple22.jpg"
// import pple23 from "../../images/pple23.jpg"
import pple23 from "../../images/pple23.webp"
import wmn1 from "../../images/wmn1.jpg"
import wmn2 from "../../images/wmn2.jpg"
import wmn3 from "../../images/wmn3.jpg"
import wmn4 from "../../images/wmn4.webp"
// import wmn4 from "../../images/wmn4.jpg"
import wmn5 from "../../images/wmn5.webp"
// import wmn5 from "../../images/wmn5.jpg"
import wmn6 from "../../images/wmn6.jpg"
import wmn7 from "../../images/wmn7.jpg"
import wmn8 from "../../images/wmn8.webp"
// import wmn8 from "../../images/wmn8.jpg"
import wmn9 from "../../images/wmn9.jpg"
import wmn10 from "../../images/wmn10.webp"
// import wmn10 from "../../images/wmn10.jpg"
import wmn11 from "../../images/wmn11.webp"
// import wmn11 from "../../images/wmn11.jpg"
import wmn12 from "../../images/wmn12.jpg"
import wmn13 from "../../images/wmn13.webp"
// import wmn13 from "../../images/wmn13.jpg"
import wmn14 from "../../images/wmn14.webp"
// import wmn14 from "../../images/wmn14.jpg"
import wmn15 from "../../images/wmn15.jpg"
import wmn16 from "../../images/wmn16.jpg"
import wmn17 from "../../images/wmn17.jpg"
import map from "../../images/map.png"
import phoneimageUrl from "../../images/mobilbgimpct.png"
import f1 from "../../images/f1.png"
import f2 from "../../images/f2.png"
import f3 from "../../images/f3.png"
import f4 from "../../images/f4.png"
import f5 from "../../images/f5.png"
import f6 from "../../images/f6.png"
import winr from "../../images/winr.png"
import winr2 from "../../images/winr2.png"
import winr3 from "../../images/winr3.png"
// import ifl1 from "../../images/ifl1.png"
// import ifl2 from "../../images/ifl2.png"
// import ifl3 from "../../images/ifl3.png"
// import ifl4 from "../../images/ifl4.png"
// import ifl5 from "../../images/ifl5.png"
// import ifl7 from "../../images/ifl7.png"
import ifl1 from "../../images/ifl1.webp"
import ifl2 from "../../images/ifl2.webp"
import ifl3 from "../../images/ifl3.webp"
import ifl4 from "../../images/ifl4.webp"
import ifl5 from "../../images/ifl5.webp"
import ifl7 from "../../images/ifl7.webp"
import ifl8 from "../../images/ifl8.webp"
// import ifl8 from "../../images/ifl8.png"
import brcod from "../../images/brcod.png"
import wtchvdo from "../../images/wthvdobg.webp"
// import wtchvdo from "../../images/wthvdobg.png"
import uplift1 from "../../images/uplift1.jpg"
import uplift2 from "../../images/uplift2.jpg"
import playBtn from "../../images/play.png"
import mup from "../../images/mup.jpg"
import mup2 from "../../images/mup2.jpg"
import prcs1 from "../../images/prcs1.jpg"
import prcs2 from "../../images/prcs2.jpg"
import prcs3 from "../../images/prcs3.jpg"
// import impctimageUrl from "../../images/onlyimpctbg.png"
import impctimageUrl from "../../images/onlyimpctbg.webp"
// import dkstpawrdimageUrl from "../../images/awrdsbg.png"
import dkstpawrdimageUrl from "../../images/awrdsbg.webp"
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getImpactSectionData, setEmailaction, setHeaderType, setLoading } from '../../Redux/ActionCreater';
import { Helmet } from 'react-helmet-async';
import { ToastContainer, toast } from 'react-toastify';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import VideoModal from './VideoModal';
import impactBack from '../../images/impactBack.png';

function HomepageDynamic() {
  const [email,setEmail]=useState("")
  const navigate = useNavigate();
  const [impactData, setImpactData] = useState([]);
  const [video,setVideo]=useState(false);
  const dispatch = useDispatch();
  const { impactSectionData } = useSelector(
    (state) => ({
      impactSectionData: state.dataReducer.impactSectionData.data
    }),
    shallowEqual
  );

  const closeModal =()=>{
    setVideo(false)
  };
  const options1 = {
    loop: false,
    margin: 20,
    items: 3,
    autoplay: false,
    autoplaySpeed: false,
    rewind: false,
    lazyLoad: true,
    mouseDrag: true,
    touchDrag: true,
    dots: true,
    nav: false,
    responsive: {
      0: {
        items: 1,
        nav: false,
        margin: 20,
        mouseDrag: true,
        touchDrag: true,
        stagePadding: 40,
        left: -30,
      },
      450: {
        items: 1,
        nav: false,
        margin: 20,
        mouseDrag: true,
        touchDrag: true,
        stagePadding: 40,
        left: -30,
      },
      768: {
        items: 3,
        nav: true,
      },
      992: {
        items: 3,
        nav: false,
        margin: 46,

      },
      1200: {
        items: 3,
        nav: false,
        margin: 36,
        mouseDrag: false,
        touchDrag: false,
      },
      1800: {
        items: 3,
        nav: false,
        margin: 36,
        mouseDrag: false,
        touchDrag: false,
      },
      1920: {
        items: 3,
        nav: false,
        margin: 46,
        mouseDrag: false,
        touchDrag: false,
      }
    }
  };
  useEffect(() => {
    dispatch(setHeaderType(1))
    fetchData()
    new WOW.WOW({
      live: false
    }).init();
    const imageEl = document.getElementById("fadingImage");
    const images = [
      tem,pple19,pple22,pple23
    ];
    const imageE2 = document.getElementById("fadingImage2");
    const images2 = [
       tem5,pple1,wmn1,pple3,wmn15
    ];
    const imageE3 = document.getElementById("fadingImage3");
    const images3 = [
      pple4,pple5,pple6,pple7,wmn14
    ];
    const imageE4 = document.getElementById("fadingImage4");
    const images4 = [
      pple8,wmn2,pple10,wmn3,wmn13
    ];
    const imageE5 = document.getElementById("fadingImage5");
    const images5 = [
      pple12,wmn5,pple14,wmn4,wmn12
    ];
    const imageE6 = document.getElementById("fadingImage6");
    const images6 = [
      pple16,wmn7,pple18,wmn6,wmn11
    ];
    const imageE7 = document.getElementById("fadingImage7");
    const images7 = [
      wmn16,wmn8,wmn17,wmn9,pple17,wmn10
    ];

    window.setInterval(() => { changePicture(images, imageEl) }, 2000);
    window.setInterval(() => { changePicture(images2, imageE2) }, 6000);
    window.setInterval(() => { changePicture(images3, imageE3) }, 4000);
    window.setInterval(() => { changePicture(images4, imageE4) }, 6000);
    window.setInterval(() => { changePicture(images5, imageE5) }, 4000);
    window.setInterval(() => { changePicture(images6, imageE6) }, 3000);
    window.setInterval(() => { changePicture(images7, imageE7) }, 5000);
    let i = 0;
    function changePicture(arr, elm) {
      i++;
      if (i > arr.length - 1) i = 0;
      elm.src = arr[i];
    }
    //window.scrollTo(0, 0);
  
  setTimeout(() => {
    dispatch(setLoading(false))
    
  }, 2000);
  }, [])



  useEffect(() => {
    setImpactData(impactSectionData);
  }, [impactSectionData])

  const fetchData = () => {
    dispatch(getImpactSectionData());
  };



  function convertToInternationalCurrencySystem(labelValue) {

    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e+9

      ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(1) + "B"
      // Six Zeroes for Millions 
      : Math.abs(Number(labelValue)) >= 1.0e+6

        ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(1) + "M"
        // Three Zeroes for Thousands
        // : Math.abs(Number(labelValue)) >= 1.0e+3

        // ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(1) + "K"

        : Math.abs(Number(labelValue));

  }
  const submitEmail=(e)=>{
    e.preventDefault();
    if (email === "") {
      return toast.error("Please enter email!")
    }
    if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
      return toast.error("Please enter a valid email!")
    }
    dispatch(setEmailaction(email))
    dispatch(setLoading(true));
    navigate("/movement")
      }
  return (
    <>
      <Helmet>
        <title>Bintix::Home</title>
        <meta property="og:title" content="Bintix::Home" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div style={{overflowX:"hidden"}}>
      <BannerSection />
      <ImpactSection impactData={impactData} />

     
     <section className="missionsec ">
        <div className="squarepic wow fadeInUp"><img src={tbg} alt='demo' width={"208px"} height={"214px"}/></div>
        <div className="container">
          <div className='ourvsnmsn'>
            <OwlCarousel className="owl-theme" {...options1}  >

              <div className="item">
                <div className="mission-info">
                  <figure><img src={pur} alt='demo' width={"522px"} height={"497px"}/></figure>
                  <figcaption>
                    <h4>Our Purpose</h4>
                    <p>No Waste Shall Go Wasted</p>
                  </figcaption>
                </div>
              </div>
              <div className="item">
                <div className="mission-info">
                  <figure><img src={vsn} alt='demo' width={"522px"} height={"497px"}/></figure>
                  <figcaption>
                    <h4>Our Vision</h4>
                    <p>Use Insights from waste as a super-power that removes waste from planet  & uplifts marginalized waste workers </p>
                  </figcaption>
                </div>
              </div>
              <div className="item">
                <div className="mission-info">
                  <figure><img src={misn} alt='demo' width={"522px"} height={"497px"}/></figure>
                  <figcaption>
                    <h4>Our Mission</h4>
                    <p>Apply technology to waste to transform it into valuable insights & solutions</p>
                  </figcaption>
                </div>
              </div>
            </OwlCarousel>
          </div>

          <div className="removing-content">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 wow fadeInLeft" data-wow-duration="2s">
                <h3>Harnessing Data from waste to Remove ocean & landfill bound plastic </h3>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 wow fadeInRight" data-wow-duration="2s">
                <p>A first of its kind Waste-Tech Venture that uses technology solutions to collect, tag & analyze dry-waste of house-holds. </p>
              </div>
            </div>
          </div>

        </div>

      </section>

      <section className="ocean-sec">
        <div className="container">

          <div className="without-bntx wow fadeInUp">

            <div className='tabbox-sec'>

            <ul className="nav nav-tabs flex justify-content-center mb-3" id="ex1" role="tablist">
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link active"
                  id="ex3-tab-1"
                  data-bs-toggle="tab"
                  href="#ex3-tabs-1"
                  role="tab"
                  aria-controls="ex3-tabs-1"
                  aria-selected="true"
                >With Bintix</a
                >
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link"
                  id="ex3-tab-2"
                  data-bs-toggle="tab"
                  href="#ex3-tabs-2"
                  role="tab"
                  aria-controls="ex3-tabs-2"
                  aria-selected="false"
                >Without Bintix</a
                >
              </li>
            </ul>

            <div className="tab-content" id="ex2-content">
              <div
                className="tab-pane fade show active"
                id="ex3-tabs-1"
                role="tabpanel"
                aria-labelledby="ex3-tab-1">
                  <div className="withbntix-sec">
                    <div className="squarepic wow swing"><img src={tbg} alt='demo' width={"298px"} height={"307px"}/></div>
                    <div className="squarepic2 wow swing"><img src={tbg} alt='demo' width={"298px"} height={"307px"}/></div>
                    <div className="wthouthed">
                      <h5>With Bintix</h5>
                    </div>
                    <div className="row">
                    <div className="col-lg-12 wow fadeInUp" data-wow-duration="2s">
                      <div className="collect-bntx ">
                        <img src={b5} alt='demo' width={"315px"} height={"205px"}/>
                        <p>House-Hold Waste collected by Bintix</p>
                      </div>
                    </div>
                    <div className="col-lg-12 wow fadeInUp" data-wow-duration="2s">
                      <div className="collect-bntx scnclct">
                        <img src={b6} alt='demo' width={"137px"} height={"146px"}/>
                        <p>Data Insights From Waste</p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-4 wow fadeInUp" data-wow-duration="2s">
                      <div className="recycleinfo rckyl">
                        <img src={b2} alt='demo' width={"145px"} height={"139px"}/>
                        <a href=''></a>
                        <p>Recycled</p>
                        <span>(Valuable Waste)</span>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-4 wow fadeInUp" data-wow-duration="2s">
                      <div className="recycleinfo midlbbdr">
                        <img src={b7} alt='demo' width={"135px"} height={"129px"}/>
                        <a href=''>Plastic Is...</a>
                        <p>Repurposed</p>
                        <span>(Products From Waste)</span>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-4 wow fadeInUp" data-wow-duration="2s">
                      <div className="recycleinfo lstbdr">
                        <img src={b8} alt='demo' width={"158px"} height={"139px"}/>
                        <a href=''></a>
                        <p>Removed</p>
                        <span>(Waste Incinerated)</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="ex3-tabs-2" role="tabpanel" aria-labelledby="ex3-tab-2">
                

                <div className="wstecolect">
                  <div className="wthouthed">
                    <h5>Without Bintix</h5>
                  </div>
                  <div className="row align-items-end">
                    <div className="col-lg-12 wow fadeInUp" data-wow-duration="2s">
                      <div className="huse-wste fstbdr ">
                        <img src={b1} alt='demo' width={"198px"} height={"169px"}/>
                        <p>House-Hold Waste Collected</p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-6 wow fadeInUp" data-wow-duration="2s">
                      <div className="huse-wste midlbdr">
                        <img src={b2} alt='demo' width={"145px"} height={"139px"}/>
                        <p>Some is Recycled</p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-6 wow fadeInUp" data-wow-duration="2s">
                      <div className="huse-wste lsticn">
                        <img src={b3} alt='demo' width={"234px"} height={"117px"}/>
                        <p>But a Lot Goes to Ocean...</p>
                      </div>
                    </div>
                    <div className="col-lg-12 wow fadeInUp" data-wow-duration="2s">
                      <div className="huse-wste">
                        <img src={b4} alt='demo' width={"185px"} height={"148px"}/>
                        <p>... And To Land-Fills / Dump-Yards</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            </div>

            <div className="row wtoutbntx">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="wstecolect">
                  <div className="wthouthed">
                    <h5>Without Bintix</h5>
                  </div>
                  <div className="row align-items-end">
                    <div className="col-lg-12 wow fadeInUp" data-wow-duration="2s">
                      <div className="huse-wste fstbdr ">
                        <img src={b1} alt='demo' width={"198px"} height={"169px"}/>
                        <p>House-Hold Waste Collected</p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-6 wow fadeInUp" data-wow-duration="2s">
                      <div className="huse-wste midlbdr">
                        <img src={b2} alt='demo' width={"145px"} height={"139px"}/>
                        <p>Some is Recycled</p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-6 wow fadeInUp" data-wow-duration="2s">
                      <div className="huse-wste lsticn">
                        <img src={b3} alt='demo' width={"234px"} height={"117px"}/>
                        <p>But a Lot Goes to Ocean...</p>
                      </div>
                    </div>
                    <div className="col-lg-12 wow fadeInUp" data-wow-duration="2s">
                      <div className="huse-wste">
                        <img src={b4} alt='demo' width={"185px"} height={"148px"}/>
                        <p>... And To Land-Fills / Dump-Yards</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="2s">
                <div className="withbntix-sec">
                  <div className="squarepic wow swing"><img src={tbg} alt='demo' width={"209px"} height={"215px"} /></div>
                  <div className="squarepic2 wow swing"><img src={tbg} alt='demo' width={"239px"} height={"246px"}/></div>
                  <div className="wthouthed">
                    <h5>With Bintix</h5>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 wow fadeInUp" data-wow-duration="2s">
                      <div className="collect-bntx ">
                        <img src={b5} alt='demo' width={"315px"} height={"205px"}/>
                        <p>House-Hold Waste collected by Bintix</p>
                      </div>
                    </div>
                    <div className="col-lg-12 wow fadeInUp" data-wow-duration="2s">
                      <div className="collect-bntx scnclct">
                        <img src={b6} alt='demo' width={"137px"} height={"146px"}/>
                        <p>Data Insights From Waste</p>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-4 wow fadeInUp" data-wow-duration="2s">
                      <div className="recycleinfo rckyl">
                        <img src={b2} alt='demo' width={"145px"} height={"139px"}/>
                        <a href=''></a>
                        <p>Recycled</p>
                        <span>(Valuable Waste)</span>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-4 wow fadeInUp" data-wow-duration="2s">
                      <div className="recycleinfo midlbbdr">
                        <img src={b7} alt='demo' width={"135px"} height={"129px"}/>
                        <a href=''>Plastic Is...</a>
                        <p>Repurposed</p>
                        <span>(Products From Waste)</span>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-4 wow fadeInUp" data-wow-duration="2s">
                      <div className="recycleinfo lstbdr">
                        <img src={b8} alt='demo' width={"158px"} height={"139px"}/>
                        <a href=''></a>
                        <p>Removed</p>
                        <span>(Waste Incinerated)</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="blog-sec">
        <div className="container">
          <div className="bloginfo wow fadeInUp" data-wow-duration="2s">
            <div className="bloghedng">
              <h2>It All Starts With a Blue bag &amp; Lots of tech</h2>
            </div>

            <div className='row'>
              <div className='col-lg-3 col-6'>
                <div className="item">
                  <div className="blgnmbr"><span>1</span></div>
                  <div className="blogbox">
                    <div className="blog-txt">
                      <span><img src={bicon1} alt='demo' width={"50px"} height={"50px"}/></span>
                      <h4>QR Coded bin bags collected from houses weekly.</h4>
                    </div>
                    <div className="blogpic">
                      <div className="bpic"><img src={blg1} alt='demo'/></div>
                      <div className="bpic"><img src={blg2} alt='demo'/></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-lg-3 col-6'>
                <div className="item">
                  <div className="blgnmbr"><span>2</span></div>
                  <div className="blogbox">
                    <div className="blogpic">
                      <div className="bpic"><img src={blg3} alt='demo'/></div>
                      <div className="bpic"><img src={blg4} alt='demo'/></div>
                    </div>
                    <div className="blog-txt">
                      <h4>AI/ML Used to capture Data of each SKU/Bag</h4>
                      <span><img src={bicon2}  alt='demo' width={"55px"} height={"50px"} /></span>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-lg-3 col-6'>
                <div className="item">
                  <div className="blgnmbr"><span>3</span></div>
                  <div className="blogbox">
                    <div className="blog-txt">
                      <span><img src={bicon3} alt='demo' width={"50px"} height={"50px"}/></span>
                      <h4>Advanced Analytics For Consumption insights</h4>
                    </div>
                    <div className="blogpic">
                      <div className="bpic"><img src={blg5} alt='demo'/></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-lg-3 col-6'>
                <div className="item">
                  <div className="blgnmbr"><span>4</span></div>
                  <div className="blogbox">
                    <div className="blogpic">
                      <div className="bpic"><img src={blg6} alt='demo'/></div>
                      <div className="bpic"><img src={blg7} alt='demo'/></div>
                    </div>
                    <div className="blog-txt">
                      <h4>Waste is recycled, repurposed, removed</h4>
                      <span><img src={bicon4} alt='demo' width={"50px"} height={"50px"}/></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="watch-jurny">
        <div className="container">
          <div className='wtchbg'>
            <img src={wtchvdo} alt='demo' width={"1645px"} height={"362px"}/>
          </div>
          <div className="watch-content wow fadeInUp" data-wow-duration="2s">
            <div className="row align-items-center">
              <div className="col-lg-4 col-md-4 col-5 wow fadeInLeft" data-wow-duration="2s">
                <div className="video-box" >
                  <img className='ytb-vid' src={`https://img.youtube.com/vi/7tKZBVYWdhc/0.jpg`} alt='youtube-thumbnail' width={"254px"} height={"190px"}/>
                  <img class="playbtn" src={playBtn} onClick={()=>{setVideo(true)}} alt='demo'/>
                </div>
              </div>
              <div className="col-lg-8 col-md-8 col-7 wow fadeInRight" data-wow-duration="2s">
                <h5>Watch The Journey of the Blue Bag</h5>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="waste-challenge wow fadeInUp" data-wow-duration="1s">
        <div className="container">
          <h3 className='todyhedng'>Today’s plastic waste challenge</h3>
          <div className="garbej-sec">
            <div className="squarepic2 wow swing"><img src={brcod} alt='demo' width={"298px"} height={"307px"}/></div>
            <div className="row align-items-center">
              <div className="col-lg-5 col-md-5 col-sm-12 wow fadeInLeft" data-wow-duration="1.2s">
                <div className="garbjpic">
                <LazyLoadImage src={garbeg} alt='demo' width={"657px"} />
                  {/* <img src={garbeg} /> */}
                  
                  </div>
              </div>
              <div className="col-lg-7 col-md-7 col-sm-12 wow fadeInRight" data-wow-duration="1.2s">
                <div className="garbj-info">
                  <h6 style={{color:"#204D36"}}>The Problem</h6>
                  <p><span>
                    {`>40%`}</span>   of waste (mostly multilayered plastic) still ends up in the environment.{`{`}<i>8000 Tonnes daily just in India</i>{`}`}</p>
                </div>
                <div className="garbj-info">
                  <h6 style={{color:"#204D36"}}>Why?</h6>
                  <p>cost to dispose-off <em>{`>`}</em> value of waste</p>
                </div>
                <div className="garbj-info">
                  <h6 style={{color:"#204D36"}}>Our Solution?</h6>
                  <p>increase the value of waste through data</p>
                </div>
              </div>
            </div>
          </div>
          <div className="wstedfrntly wow fadeInUp" data-wow-duration="2s">
            <h4>We See Waste <i>Differently</i> As</h4>
            <h3>a mirror reflection of society’s consumption Habits</h3>
          </div>
          <div className="studying-sec">
            <div className="row align-items-center">
              <div className="col-lg-5 wow fadeInleft" data-wow-duration="2s">
                <div className="study-content">
                  <h3>By Studying Every Bit <br/>Of Waste Disposed..</h3>
                  <img className='wow fadeInUp' src={barcode} alt='demo' width={"344px"} height={"272px"}/>
                </div>
              </div>
              <div className="col-lg-7 wow fadeInRight" data-wow-duration="2s">
                <div className="studypic"><img src={studying} alt='demo' style={{width:'914px', height:'546px'}}/></div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-7 wow fadeInleft" data-wow-duration="2s">
                <div className="studypic"><img src={analytic} alt='demo' style={{width:'887px', height:'647px'}}/>
                  <div className="squarepic2 wow fadeInUp"><img src={brcod} alt='demo'/></div>
                </div>
              </div>
              <div className="col-lg-5 wow fadeInRight" data-wow-duration="2s">
                <div className="study-content">
                  <h3>and By Applying Advanced Analytics...</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="unlocking ustopmrgn wow fadeInUp" data-wow-duration="2s">
            <h3>... We’re Unlocking Never-Possible-Before Insights for our partners</h3>
          </div>
          <div className="associate-sec">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 col-sm-6 col-6 wow fadeInUp" data-wow-duration="2s">
                <div className="resident-sec">
                  <figure className="tagpic"><img src={hm1} alt='demo' width={"141px"} height={"171px"}/></figure>
                  <h4>Homes</h4>
                  <p>Free, personalized <br/>dashboards to help <br/>families improve their <br/>waste impact  </p>
                  <a href='/collect-my-waste' onClick={(e) => { e.preventDefault(); window.scrollTo(0, 0);dispatch(setLoading(true)); navigate("/collect-my-waste") }}>Collect My Waste</a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-6 wow fadeInUp" data-wow-duration="2s">
                <div className="resident-sec">
                  <figure className="tagpic"><img src={hm2} alt='demo' width={"134px"} height={"185px"}/></figure>
                  <h4>Resident Associations</h4>
                  <p>Free community <br/>dashboards to help Resident <br/>Associations improve their <br/>waste impact </p>
                  <a href='/collect-my-waste' onClick={(e) => { e.preventDefault(); window.scrollTo(0, 0); dispatch(setLoading(true)); navigate("/collect-my-waste") }}>Collect My Waste</a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-6 wow fadeInUp" data-wow-duration="2s">
                <div className="resident-sec">
                  <figure className="tagpic"><img src={hm4} alt='demo' width={"141px"} height={"159px"}/></figure>
                  <h4>Co-Opetitors</h4>
                  <p>Bespoke waste <br/>management data <br/>solutions to help companies <br/>track  waste better </p>
                  <a href="/context" onClick={(e) => { e.preventDefault(); window.scrollTo(0, 0); dispatch(setLoading(true)); navigate("/context") }}>Learn More</a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-6 wow fadeInUp" data-wow-duration="2s">
                <div className="resident-sec">
                  <figure className="tagpic"><img src={hm5} alt='demo' width={"137px"} height={"183px"}/></figure>
                  <h4>Branded Product Companies</h4>
                  <p>Real time consumption <br/>insights to make <br/>products better & more sustainable</p>
                  <a href="/partner-code" onClick={(e) => { e.preventDefault(); window.scrollTo(0, 0); navigate("/partner-code") }}>Learn More</a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6 col-6 wow fadeInUp" data-wow-duration="2s">
                <div className="resident-sec">
                  <figure className="tagpic"><img src={hm3} alt='demo' width={"136px"} height={"171px"}/></figure>
                  <h4>The Public Sector</h4>
                  <p>Localized waste & <br/>consumption trends to help <br/>improve public policy on <br/>waste</p>
                  <a href="/context" onClick={(e) => { e.preventDefault(); window.scrollTo(0, 0); dispatch(setLoading(true)); navigate("/context") }}>Learn More</a>
                </div>
              </div>


            </div>
          </div>

          
        </div>
      </section>

      <section className='recycle-sec wow fadeInUp' data-wow-duration="2s">
        <div className='container'>

          <div className='wste-store'>
            <LazyLoadImage  src={wstestore} alt='demo'  />
            <div className='mobilrecycle'>
              <div className='rcylpic'><img src={w4} alt='demo' /></div>
              <div className='rcylpic'><img src={w5} alt='demo'/></div>
              <div className='rcylpic'><img src={w3} alt='demo'/></div>
            </div>
            <div className='wststorinfo'>
              <div className='recycletxt'>
                <h3>Waste.<br /> Data.<br /> Recyclability.</h3>
                <p>All Under One Roof</p>
              </div>
              <div className='recyclepic'>

                <div className='row'>
                  <div className='col-6'>
                    <div className='recypic'>
                      <img src={w1} alt='demo' style={{width: '171px', height:'257px'}}/>
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className='recypic'>
                      <img src={w2} alt='demo' style={{width: '171px', height:'257px'}}/>
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className='recypic'>
                      <img src={w3} alt='demo' style={{width: '207px', height:'174px'}}/>
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className='recypic'>
                      <img src={w4} alt='demo' style={{width: '246px', height:'236px'}}/>
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className='recypic'>
                      <img src={w5} alt='demo' style={{width: '292px', height:'181px'}}/>
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className='recypic'>
                      <img src={w6} alt='demo' style={{width: '274px', height:'182px'}}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="squarepic"><img src={tbg} alt='demo' width={"298px"} height={"307px"}/></div>
        </div>
      </section>

      <section className='partner-sec '>
        <div className='container'>
          <div className='prtnr-heading wow fadeInUp' data-wow-duration="2s">
            <h3>Our Partners</h3>
            <p>Brand Partners</p>
          </div>
          <div className='prtnviw wow fadeInUp'>
            <div className='prtnrlgo'><img src={p8} alt='demo'/></div>
            <div className='prtnrlgo'><img src={p7} alt='demo'/></div>
          </div>
          <div className='prtnviw wow fadeInUp'>
            <div className='prtnrlgo'><img src={p9} alt='demo'/></div>
            <div className='prtnrlgo'><img src={p6} alt='demo'/></div>
            <div className='prtnrlgo'><img src={p5} alt='demo'/></div>
          </div>
          <div className='prtnviw lstlgo wow fadeInUp'>
            <div className="squarepic2"><img src={tbg} alt='demo' width={"155px"} height={"160px"}/></div>
            <div className='prtnrlgo'><img src={p4} alt='demo'/></div>
            <div className='prtnrlgo'><img src={p3} alt='demo'/></div>
            <div className='prtnrlgo'><img src={p1} alt='demo'/></div>
            <div className='prtnrlgo'><img src={p2} alt='demo'/></div>
          </div>

          <div className='prtnr-heading extrmrgn wow fadeInUp' data-wow-duration="2s">
            <p>Ground & Tech Partners</p>
          </div>
          <div className='prtnviw wow fadeInUp'>
            <div className='prtnrlgo'><img src={p10} alt='demo'/></div>
            <div className='prtnrlgo'><img src={p11} alt='demo'/></div>
            <div className='prtnrlgo'><img src={p12} alt='demo'/></div>
          </div>
          <div className='prtnviw lstlgo wow fadeInUp'>
            <div className='prtnrlgo'><img src={Google_2015_logo} alt='demo'/></div>
            <div className='prtnrlgo'><img src={p13} alt='demo'/></div>
            <div className='prtnrlgo'><img src={p14} alt='demo'/></div>
          </div>

        </div>
      </section>

      <section className='impact-details impactRedsg newimpct' style={{ backgroundImage: window.screen.width > 991 ? `url(${impactBack})` : `url(${impactBack})`, }} >
        <div className='container'>
          <div className='impct-hed wow fadeInUp' data-wow-duration="2s">
            <h3 class="">Our Impact</h3>
            <p className='dkstpimpct' > Socially Impactful Business Innovations
 <br/>That Are In Line With The U.N. Sustainable Development Goals.</p>
            <p className='impcmobiltxt'>innovations in line with the U.N. Sustainable Development Goals.</p>
          </div>
          <div className="impectN dkstpimpct">
            <div className='row'>
              <div className='col-lg-4 col-md-4 col-sm-6 col-6 wow fadeInUp'>
                <div className='tones-sec'>
                  <div className='impct-catg'>
                    <span><img src={f1} alt='demo' width={"39px"} height={"33px"} /></span>
                    {impactData && <h5>{(impactData.kgs / 1000).toFixed()} <sub>Tonnes</sub></h5>}
                  </div>
                  <p>Waste Prevented from Landfills & Oceans</p>
                </div>
              </div>
              <div className='col-lg-4 col-md-4 col-sm-6 col-6 wow fadeInUp'>
                <div className='tones-sec'>
                  <div className='impct-catg' >
                    <span><img src={f2} alt='demo' width={"33px"} height={"21px"}/></span>
                    {impactData && <h5>{convertToInternationalCurrencySystem(impactData.co_2)}<sub>KGs</sub></h5>}
                  </div>
                  <p>Averted</p>
                </div>
              </div>
              <div className='col-lg-4 col-md-4 col-sm-6 col-6 wow fadeInUp'>
                <div className='tones-sec'>
                  <div className='impct-catg'>
                    <span><img src={f3} alt='demo' width={"33px"} height={"30px"}/></span>
                    {impactData && <h5>~{impactData.trees}</h5>}
                  </div>
                  <p>Trees Saved</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className='col-lg-4 col-md-4 col-sm-6 col-6 wow fadeInUp'>
                <div className='tones-sec'>
                  <div className='impct-catg'>
                    <span><img src={f4} alt='demo' width={"33px"} height={"33px"}/></span>
                    {impactData && <h5>{impactData.families_impacted} <sub>Families </sub></h5>}
                  </div>
                  <p>Of waste champions impacted</p>
                </div>
              </div>
              <div className='col-lg-4 col-md-4 col-sm-6 col-6 wow fadeInUp'>
                <div className='tones-sec'>
                  <div className='impct-catg'>
                    <span><img src={f5} alt='demo' width={"33px"} height={"36px"}/></span>
                    {impactData && <h5>~{impactData.households}</h5>}
                  </div>
                  <p>Households Have <br/>Joined The Movement Across 7 Cities</p>
                </div>
              </div>
              <div className='col-lg-4 col-md-4 col-sm-6 col-6 wow fadeInUp'>
                <div className='tones-sec'>
                  <div className='impct-catg'>
                    <span><img src={f6} alt='demo' width={"33px"} height={"23px"}/></span>
                    {impactData && <h5>{convertToInternationalCurrencySystem(impactData.pickups)}</h5>}
                  </div>
                  <p>Pickups of waste from homes</p>
                </div>
              </div>
            </div>
          </div>

          <div className="impectN mobilimpct">
            <div className='row'>
              <div className='col-lg-4 col-md-4 col-sm-6 col-6 wow fadeInUp'>
                <div className='tones-sec'>
                  <div className='impct-catg'>
                    <span><img src={f1} alt='demo'/></span>
                    {impactData && <h5>{(impactData.kgs / 1000).toFixed()} <sub>Tonnes</sub></h5>}
                  </div>
                  <p>Waste Prevented from Landfills & Oceans</p>
                </div>
              </div>
              <div className='col-lg-4 col-md-4 col-sm-6 col-6 wow fadeInUp'>
                <div className='tones-sec'>
                  <div className='impct-catg'>
                    <span><img src={f2} alt='demo'/></span>
                    {impactData && <h5>{convertToInternationalCurrencySystem(impactData.co_2)}<sub>KGs</sub></h5>}
                  </div>
                  <p>Averted</p>
                </div>
              </div>
              <div className='col-lg-4 col-md-4 col-sm-6 col-6 wow fadeInUp'>
                <div className='tones-sec'>
                  <div className='impct-catg'>
                    <span><img src={f3} alt='demo'/></span>
                    {impactData && <h5>~{impactData.trees}</h5>}
                  </div>
                  <p>Trees Saved</p>
                </div>
              </div>
              <div className='col-lg-4 col-md-4 col-sm-6 col-6 wow fadeInUp'>
                <div className='tones-sec'>
                  <div className='impct-catg'>
                    <span><img src={f4} alt='demo'/></span>
                    {impactData && <h5>{impactData.families_impacted} <sub>Families </sub></h5>}
                  </div>
                  <p>Of waste champions impacted</p>
                </div>
              </div>
              <div className='col-lg-4 col-md-4 col-sm-6 col-6 wow fadeInUp'>
                <div className='tones-sec'>
                  <div className='impct-catg'>
                    <span><img src={f5} alt='demo'/></span>
                    {impactData && <h5>~{impactData.households}</h5>}
                  </div>
                  <p>Households have joined the movement across 7 cities</p>
                </div>
              </div>
              <div className='col-lg-4 col-md-4 col-sm-6 col-6 wow fadeInUp'>
                <div className='tones-sec'>
                  <div className='impct-catg'>
                    <span><img src={f6} alt='demo'/></span>
                    {impactData && <h5>{convertToInternationalCurrencySystem(impactData.pickups)}</h5>}
                  </div>
                  <p>Pickups of waste from homes</p>
                </div>
              </div>

            </div>
          </div>

        </div>
      </section>


<section className='uplifting'>
        <div className='container'>
          <h3>Uplifting the lives of our waste Champions</h3>
          <div className='championbx'>
            <div className='chpiopic'>
              <figure>
                <LazyLoadImage src={mup} />
                </figure>
              <figure>
              <LazyLoadImage src={uplift1} />
                </figure>
              <div className='uplto'>
                <em>From</em>
                <p>Working in Dangerous Conditions </p>
              </div>
            </div>

            <div className='chpiopic'>
              <figure>
              <LazyLoadImage src={mup2} />
                </figure>
              <figure>
              <LazyLoadImage src={uplift2} />
                </figure>
              <div className='uplto'>
                <em>To</em>
                <p>Working With Computers & Scanners </p>
              </div>
            </div>

          </div>


          <div className='processing-sec'>
            <div className='process-prcnt'>
              <div className='wrkfrcbx'>
                <h4>80%</h4>
                <p>of processing workforce is women</p>
              </div>
              <div className='wrkfrcbx'>
                <h4>20%</h4>
                <p>Higher earnings than  minimum wage</p>
              </div>

            </div>

            <div className='procss-pic'>
              <div className='fstprcs'>
                <LazyLoadImage src={prcs1} />
              </div>
              <div className='scndprcs'>
              <LazyLoadImage src={prcs2} />
              <LazyLoadImage src={prcs3} />
              </div>
            </div>
          </div>

        </div>

      </section>


      <section className='impact-details impctawrsbg' style={{ backgroundImage: window.screen.width > 991 ? `url(${dkstpawrdimageUrl})` : `url(${phoneimageUrl})`, }} >
        <div className='container'>

          <div className='impct-hed hedbtom'>
            <p className='mobilimpct'>socially impactful business innovations
              that are in line with the U.N. Sustainable Development Goals.</p>

            <h3>Awards</h3>
          </div>
          <div className='awrd-panel'>
            <div className='row mrgntop '>
              <div className='col-lg-6 col-md-6 col-sm-12 wow fadeInUp' data-wow-duration="1.2s">
                <div className='winner-sec'>
                  <span><img src={winr} alt='demo' width={"245px"} height={"61px"}/></span>
                  <p>MRSI
                    <br/>Golden Key Awards <br/>Best Data Collection <br/>innovation
                    2022</p>
                  <div className='taginfo'>
                    <li><img src={ifl5} alt='demo' width={"169px"} height={"100px"}/></li>
                  </div>
                </div>
              </div>
               <div className='col-lg-6 col-md-6 col-sm-12 wow fadeInUp' data-wow-duration="1.2s">
                <div className='winner-sec'>
                  <span><img src={winr} alt='demo' width={"245px"} height={"61px"}/></span>
                  <p>Ministry Of Health & AFD SWACHHATA STARTUP <br/>CHALLENGE, India 
<br/>2022</p>
                  <div className='taginfo'>
                    <li><img src={ifl1} alt='demo' width={"133px"} height={"65px"}/></li>
                    <li><img src={ifl2} alt='demo' width={"123px"} height={"69px"}/></li>
                    <li><img src={ifl3} alt='demo' width={"127px"} height={"57px"}/></li>
                    <li><img src={ifl4} alt='demo' width={"143px"} height={"64px"}/></li>
                  </div>
                </div>
              </div> 
              <div className='col-lg-6 col-md-6 col-sm-12 wow fadeInUp' data-wow-duration="1.2s">
                <div className='winner-sec'>
                  <span><img src={winr2} alt='demo' width={"245px"} height={"61px"}/></span>
                  <p>Representing India At <br/>The World’s Premier <br/>Startup Event
 <br/>2022</p>
                  <div className='taginfo'>
                    <li><img src={ifl7} alt='demo' width={"178px"} height={"85px"}/></li>
                  </div>
                </div>
              </div>
              <div className='col-lg-6 col-md-6 col-sm-12 wow fadeInUp' data-wow-duration="1.2s">
                <div className='winner-sec'>
                  <span><img src={winr3} alt='demo' width={"245px"} height={"61px"}/></span>
                  <p>One of the 15 indian <br/>startups chosen for <br/>innovation in plastics <br/>2023</p>
                  <div className='taginfo'>
                    <li><img src={ifl8} alt='demo' width={"168px"} height={"174px"}/></li>
                  </div>
                </div>
              </div>
              <div className='col-lg-6 col-md-6 col-sm-12 wow fadeInUp' data-wow-duration="1.2s">
                <div className='winner-sec'>
                  <span><img src={winr} alt='demo' width={"245px"} height={"61px"}/></span>
                  <p>MRSI
<br/>Golden Key Awards <br/>Most Humane Organisation
<br/>2023 
 </p>
                  <div className='taginfo'>
                    <li><img src={ifl5} alt='demo' width={"154px"} height={"104px"}/></li>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='making-sec'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-lg-12 wow fadeInUp' data-wow-duration="2s">
              <h3>Making India Waste Free</h3>
              <h6>One House & One City At a Time</h6>
            </div>
            <div className='col-lg-7 col-md-7 col-sm-12 wow fadeInUp' data-wow-duration="2s">
              <div className='newslettr-sec wow fadeInleft'>
                <span>Join the Movement</span>
                <h5> get your waste collected by us </h5>
                <div className="form-group">
                  <div className="inputgroup">
                    <input type="text" value={email} onChange={(e)=>{e.preventDefault();setEmail(e.target.value)}} className="form-control" placeholder="Enter Your Email Address" name="email" />
                    <button className="subscribebtn" type="button" onClick={submitEmail}>Click Here</button>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-5 col-md-5 col-sm-12 wow fadeInUp' data-wow-duration="2s">
              <div className='map-sec'>
                <LazyLoadImage src={map} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='our-team'>
        <div className='container'>
          <div className='waste-data'>
            <div onClick={()=>{dispatch(setLoading(true));navigate("/about-us")}} className='team-heding wow fadeInUp' data-wow-duration="2s">
              <h3>Our Team </h3>
              <p>The Waste & Data Champions</p>
            </div>
            <div className='row'>
              <div className='col-lg-12 col-md-12 col-sm-12 col-12 wow fadeInUp' data-wow-duration="1.2s">
                <div className='team-pic'>
                  <img id="fadingImage" className="image-styled" src='' alt='demo' width={"1120px"} height={"600px"} />
                </div>
              </div>
              <div className='col-lg-4 col-md-4 col-sm-4 col-4 wow fadeInUp' data-wow-duration="1.2s">
                <div className='team-pic'>
                  <img id="fadingImage2" className="image-styled" src='' alt='demo' width={"358px"} height={"239px"} />
                </div>
              </div>
              <div className='col-lg-4 col-md-4 col-sm-4 col-4 wow fadeInUp' data-wow-duration="1.2s">
                <div className='team-pic'>
                  <img id="fadingImage3" className="image-styled" src='' alt='demo'  width={"358px"} height={"239px"}/>
                </div>
              </div>
              <div className='col-lg-4 col-md-4 col-sm-4 col-4 wow fadeInUp' data-wow-duration="1.2s">
                <div className='team-pic'>
                  <img id="fadingImage4" className="image-styled" src='' alt='demo'  width={"358px"} height={"239px"}/>
                </div>
              </div>
              <div className='col-lg-4 col-md-4 col-sm-4 col-4 wow fadeInUp' data-wow-duration="1.2s">
                <div className='team-pic'>
                  <img id="fadingImage5" className="image-styled" src='' alt='demo'   width={"358px"} height={"239px"} />
                </div>
              </div>
              <div className='col-lg-4 col-md-4 col-sm-4 col-4 wow fadeInUp' data-wow-duration="1.2s">
                <div className='team-pic'>
                  <img id="fadingImage6" className="image-styled" src='' alt='demo'  width={"358px"} height={"239px"}/>
                </div>
              </div>
              <div className='col-lg-4 col-md-4 col-sm-4 col-4 wow fadeInUp' data-wow-duration="1.2s">
                <div className='team-pic'>
                  <img id="fadingImage7" className="image-styled" src='' alt='demo'  width={"358px"} height={"239px"} />
                </div>
              </div>
            </div>
            <div className='mthechmp'>
              <a href="/about-us" onClick={(e) => { e.preventDefault();dispatch(setLoading(true)); navigate("/about-us") }}>Meet The Champions</a>
            </div>
          </div>
        </div>
      </section>

      <section className='collect-sec'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 col-md-6 col-12 wow fadeInUp' data-wow-duration="1.5s">
              <div className='collect-box'>
                <h4>What we collect</h4>
                <p>paper, plastic, e-waste, glass, metal, others</p>
                <a href="/collect-my-waste#seggregate" onClick={(e) => { e.preventDefault();dispatch(setLoading(true)); navigate("/collect-my-waste#seggregate") }}>RECYCLABLES WE ACCEPT</a>
              </div>
            </div>
            <div className='col-lg-6 col-md-6 col-12 wow fadeInUp' data-wow-duration="1.5s">
              <div className='collect-box'>
                <h4>Why seggregate?</h4>
                <p>Segregation at source is critical to its recycling</p>
                <a href='/collect-my-waste#faq' onClick={(e) => { e.preventDefault();dispatch(setLoading(true)); navigate("/collect-my-waste#faq") }}>Go to FAQ’s</a>
              </div>
            </div>
          </div>
        </div>
      </section>
     </div>
      
      {video&&<VideoModal youtube_id={"7tKZBVYWdhc"} closeModal={closeModal}/>}
      <ToastContainer/>
    </>
  )
}

export default HomepageDynamic