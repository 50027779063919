import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import home from "../../images/impact-banner.png";
import map3 from "../../images/map3.png"
import imageUrl from "../../images/impctbg.png"
import phoneimageUrl from "../../images/mobilbgimpct.png"
import f1 from "../../images/f1.png"
import f2 from "../../images/f2.png"
import f3 from "../../images/f3.png"
import f4 from "../../images/f4.png"
import f5 from "../../images/f5.png"
import f6 from "../../images/f6.png"
import mobilebnr from "../../images/imp-mbl-bnr.png"
import awrdpic from "../../images/awrdpic.png"
import awrdpic2 from "../../images/awrdpic2.png"
import scalepic from "../../images/scalepic.png"
import op1 from "../../images/op1.jpg"
import op2 from "../../images/op2.jpg"
import op3 from "../../images/op3.jpg"
import op4 from "../../images/op4.jpg"
import op5 from "../../images/op5.jpg"
import op6 from "../../images/op6.jpg"
import op7 from "../../images/op7.jpg"
import opicon1 from "../../images/opicon1.png"
import opicon2 from "../../images/opicon2.png"
import opicon3 from "../../images/opicon3.png"
import opicon4 from "../../images/opicon4.png"
import opicon5 from "../../images/opicon5.png"
import opicon6 from "../../images/opicon6.png"
import opicon7 from "../../images/opicon7.png"
import sxty from "../../images/sxty.png"
import ninty from "../../images/ninty.png"
import ptshp1 from "../../images/ptshp1.png"
import ptshp2 from "../../images/ptshp2.png"
import prtnr1 from "../../images/prtnr1.png"
import prtnr2 from "../../images/prtnr2.png"
import prtnr3 from "../../images/prtnr3.png"
import prtnr4 from "../../images/prtnr4.png"
import prtnr5 from "../../images/prtnr5.png"
import fundrpic from "../../images/fundrpic.jpg"
import covr1 from "../../images/covr1.jpg"
import covr2 from "../../images/covr2.jpg"
import covr3 from "../../images/covr3.jpg"
import covr4 from "../../images/covr4.jpg"
import covr5 from "../../images/covr5.jpg"
import covr6 from "../../images/covr6.jpg"
import covr7 from "../../images/covr7.jpg"
import stepbg1 from "../../images/stepbg1.png"
import stepbg2 from "../../images/stepbg2.png"
import stepbg3 from "../../images/stepbg3.png"
import mshp1 from "../../images/mshp1.png"
import mshp2 from "../../images/mshp2.png"
import mshp3 from "../../images/mshp3.png"
import line from "../../images/line.png"
import stp1 from "../../images/stp1.png"
import stp2 from "../../images/stp2.png"
import stp3 from "../../images/stp3.png"
import mstp1 from "../../images/mstp1.png"
import mb1 from "../../images/mb1.png"
import mb2 from "../../images/mb2.png"
import mb3 from "../../images/mb3.png"
import mb4 from "../../images/mb4.png"
import mb5 from "../../images/mb5.png"
import mb6 from "../../images/mb6.png"
import mb7 from "../../images/mb7.png"
import { TbWorld } from "react-icons/tb";
import { GoLocation } from "react-icons/go";
import WOW from 'wowjs';
import './Impact.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getImpactSectionData, setHeaderType, setLoading } from '../../Redux/ActionCreater';
import $ from "jquery";
import impactBack from '../../images/impactBack.png';

function ImpactDynamic() {
    const navigate = useNavigate();
    const [impactData,setImpactData]=useState([])
    const dispatch = useDispatch();
    const { impactSectionData} = useSelector(
      (state) => ({
        impactSectionData: state.dataReducer.impactSectionData.data
      }),
      shallowEqual
    );
    const styles = {
        backgroundImage: window.screen.width > 991 ? `url(${imageUrl})` : `url(${phoneimageUrl})`,
    };
    const options = {
        loop: true,
        margin: 0,
        items: 1,
        autoplay: false,
        autoplaySpeed: 1200,
        rewind: false,
        lazyLoad: true,
        mouseDrag: false,
        touchDrag: false,
        dots: true,
        nav: false,
      };
    



    useEffect(() => {
      dispatch(setHeaderType(1))
        fetchData()
        new WOW.WOW().init();
        let id =window.location.href.split("#")[1];
    setTimeout(() => {
      if(id){
  $('html, body').animate(
    {
      scrollTop: $("#"+id).offset().top-200
    },
  )
      }
      // else{
      //   window.scrollTo(0, 0);
      // }
      setTimeout(() => {
        dispatch(setLoading(false))
        
      }, 2000);
    }, 1000);
      }, [])

  
     useEffect(() => {


         setImpactData(impactSectionData);
         console.log(impactSectionData)
        }, [impactSectionData])
 
        const fetchData = () => {
         dispatch(getImpactSectionData());
       };
     
 
 
 function convertToInternationalCurrencySystem (labelValue) {
 
     // Nine Zeroes for Billions
     return Math.abs(Number(labelValue)) >= 1.0e+9
 
     ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(1) + "B"
     // Six Zeroes for Millions 
     : Math.abs(Number(labelValue)) >= 1.0e+6
 
     ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(1) + "M"
     // Three Zeroes for Thousands
     // : Math.abs(Number(labelValue)) >= 1.0e+3
 
     // ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(1) + "K"
 
     : Math.abs(Number(labelValue));
 
 }


    return (
        <>
        <Helmet>
        <title>Bintix::Impact</title>
        <meta property="og:title" content="Bintix::Impact" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
  
        <main className="abut-page-bg cntxt-bg">
          <section className="bannercontainer abutbnr impctpe">
            <div id="NoiseBanner" className>
              <OwlCarousel className="owl-theme" {...options}>
                <div className="item">
                  <img
                    className="dkstbnr"
                    src={home} alt='demo'
                    style={{ height: "auto", width: "100%" }}
                  />
                  <img
                    className="abmbnr"
                    src={mobilebnr} alt='demo'
                    style={{ height: "auto", width: "100%" }}
                  />
                  <div className="banner-cap">
                    <div className="container">
                      <div className="banrcontent wow fadeInUp">
                        <h1 className="dkbntxt">
                          National Award Winning
                          <br />
                          360° Action On Waste
                        </h1>
                        <h1 className="mbbntxt">
                          National Award Winning 360° Action On Waste
                        </h1>
                        <h4>ensuring circularity & Zero waste By partnering with experts </h4>
                        <a
                          href="/partner1"
                          onClick={(e) => {
                            e.preventDefault();
                            dispatch(setLoading(true));
                            navigate("/partner1");
                          }}
                        >
                          Partner With Us
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </section>
  
          <section className="scale-info">
            <div className="container">
              <h2 className="wow fadeInUp">The Scale of the problem</h2>
              <div className="scaleprblm">
                <div className="row align-items-center">
                  <div className="col-lg-4 wow fadeInUp">
                    <div className="scalepic">
                      <img src={scalepic} alt='demo'/>
                    </div>
                  </div>
                  <div className="col-lg-8 ">
                    <div className="allscale">
                      <div className="scale-million wow fadeInUp">
                        <div className="scaleamunt">
                          <h3>~20 Million </h3>
                        </div>
                        <p>
                          Tonnes of plastic is consumed in india every year & is
                          growing at CAGR 10%
                        </p>
                      </div>
                      <div className="scale-million wow fadeInUp">
                        <div className="scaleamunt">
                          <h3>~11 Million </h3>
                        </div>
                        <p>
                          Tonnes of plastic is used in packaging (food package,
                          delivery boxes etc.){" "}
                        </p>
                      </div>
                      <div className="scale-million wow fadeInUp">
                        <div className="scaleamunt">
                          <h3>~3.5 Million </h3>
                        </div>
                        <p>
                          Tonnes of plastic waste is generated every year in india
                          & is growing at CAGR 21%
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
  
          <section className="award-sec btomspc">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12 wow fadeInUp">
                  <div className="awrdtxt">
                    <h3>
                      Bintix - award winning dry waste management in partnership
                      with local experts
                    </h3>
                    <p>
                      We’re taking an age-old industry, mixing in some technology,
                      sprinkling on some top-notch customer service and execution,
                      in partnership with multiple experts across various cities.{" "}
                    </p>
                    <div className="rdytogat">
                      <div className="rdybtn">
                        <a
                          href="/partner1"
                          onClick={(e) => {
                            e.preventDefault();
                            dispatch(setLoading(true));
                            navigate("/partner1");
                          }}
                        >
                          Partner With Us
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 wow fadeInUp">
                  <div className="awrpic-sec">
                    <img src={awrdpic} alt='demo'/>
                    <img src={awrdpic2} alt='demo'/>
                  </div>
                  <div className="rdytogat mblabtn">
                    <div className="rdybtn">
                      <a
                        href="/partner1"
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(setLoading(true));
                          navigate("/partner1");
                        }}
                      >
                        Partner With Us
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
  

                
                
                <section className='impact-detailsinfo impactRedsg' style={{ backgroundImage: window.screen.width > 991 ? `url(${impactBack})` : `url(${impactBack})`, }}>
                    <div className='container'>
                        <div className='impct-hed wow fadeInUp'>
                            <h3>Our Impact</h3>
                            <p>socially impactful business innovations<br/>
                                that are in line with the U.N. Sustainable Development Goals.</p>
                        </div>
                        <div className="impectN dkstpimpct">
                            <div className='row'>
                                <div className='col-lg-4 col-md-4 col-sm-6 col-6 wow fadeInUp'>
                                    <div className='tones-sec'>
                                        <div className='impct-catg'>
                                            <span><img src={f1} alt='demo'/></span>
                                            {impactData&&<h5>{(impactData.kgs/1000).toFixed()} <sub>Tonnes</sub></h5>}
                                        </div>
                                        <p>Waste Prevented from Landfills & Oceans</p>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-4 col-sm-6 col-6 wow fadeInUp'>
                                    <div className='tones-sec'>
                                        <div className='impct-catg'>
                                            <span><img src={f2} alt='demo'/></span>
                                            {impactData&&<h5>{convertToInternationalCurrencySystem(impactData.co_2)}<sub>KGs</sub></h5>}
                                        </div>
                                        <p>Averted</p>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-4 col-sm-6 col-6 wow fadeInUp'>
                                    <div className='tones-sec'>
                                        <div className='impct-catg'>
                                            <span><img src={f3} alt='demo'/></span>
                                            {impactData&&<h5>~{impactData.trees}</h5>}
                                        </div>
                                        <p>Trees Saved</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className='col-lg-4 col-md-4 col-sm-6 col-6 wow fadeInUp'>
                                    <div className='tones-sec'>
                                        <div className='impct-catg'>
                                            <span><img src={f4} alt='demo'/></span>
                                            {impactData&&<h5>{impactData.families_impacted} <sub>Families </sub></h5>}
                                        </div>
                                        <p>Of waste champions impacted</p>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-4 col-sm-6 col-6 wow fadeInUp'>
                                    <div className='tones-sec'>
                                        <div className='impct-catg'>
                                            <span><img src={f5} alt='demo'/></span>
                                            {impactData&&<h5>~{impactData.households}</h5>}
                                        </div>
                                        <p>Households have joined the movement across 7 cities</p>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-4 col-sm-6 col-6 wow fadeInUp'>
                                    <div className='tones-sec'>
                                        <div className='impct-catg'>
                                            <span><img src={f6} alt='demo'/></span>
                                            {impactData&&<h5>{convertToInternationalCurrencySystem(impactData.pickups)}</h5>}
                                        </div>
                                        <p>Pickups of waste from homes</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="impectN mobilimpct">
                            <div className='row'>
                                <div className='col-lg-4 col-md-4 col-sm-6 col-6 wow fadeInUp'>
                                    <div className='tones-sec'>
                                        <div className='impct-catg'>
                                            <span><img src={f1} alt='demo'/></span>
                                            {impactData&&<h5>{(impactData.kgs/1000).toFixed()} <sub>Tonnes</sub></h5>}
                                        </div>
                                        <p>Waste Prevented from Landfills & Oceans</p>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-4 col-sm-6 col-6 wow fadeInUp'>
                                    <div className='tones-sec'>
                                        <div className='impct-catg'>
                                            <span><img src={f2} alt='demo'/></span>
                                            {impactData&&<h5>{convertToInternationalCurrencySystem(impactData.co_2)}<sub>KGs</sub></h5>}
                                        </div>
                                        <p>Averted</p>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-4 col-sm-6 col-6 wow fadeInUp'>
                                    <div className='tones-sec'>
                                        <div className='impct-catg'>
                                            <span><img src={f3} alt='demo'/></span>
                                            {impactData&&<h5>~{impactData.trees}</h5>}
                                        </div>
                                        <p>Trees Saved</p>
                                    </div>
                                </div>                         
                                <div className='col-lg-4 col-md-4 col-sm-6 col-6 wow fadeInUp'>
                                    <div className='tones-sec'>
                                        <div className='impct-catg'>
                                            <span><img src={f4} alt='demo'/></span>
                                            {impactData&&<h5>{impactData.families_impacted} <sub>Families </sub></h5>}
                                        </div>
                                        <p>Of waste champions impacted</p>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-4 col-sm-6 col-6 wow fadeInUp'>
                                    <div className='tones-sec'>
                                        <div className='impct-catg'>
                                            <span><img src={f5} alt='demo'/></span>
                                            {impactData&&<h5>~{impactData.households}</h5>}
                                        </div>
                                        <p>Households have joined the movement across 7 cities</p>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-4 col-sm-6 col-6 wow fadeInUp'>
                                    <div className='tones-sec'>
                                        <div className='impct-catg'>
                                            <span><img src={f6} alt='demo'/></span>
                                            {impactData&&<h5>{convertToInternationalCurrencySystem(impactData.pickups)}</h5>}
                                        </div>
                                        <p>Pickups of waste from homes</p>
                                    </div>
                                </div>
                        
                        </div>
                        </div>

                    </div>
                </section>
  
          <section className="operation-sec">
            <div className="container">
              <h2>Our Nation Wide Operations</h2>
              <div className="mapcities">
                <div className="oprtaionmap wow fadeInUp">
                  <img src={map3} alt='demo'/>
                </div>
                <div className="all-citis">
                  <div className="row">
                    <div className="col-lg-4 wow fadeInUp">
                      <div className="location-box">
                        <div className="fltrbx">
                          <div className="fltrshdw"></div>
                        </div>
                        <div className="lcitypic">
                          <img src={op1} alt='demo'/>
                        </div>
                        <div className="optnicon1">
                          <img src={opicon1} alt='demo'/>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 wow fadeInUp">
                      <div className="location-box">
                        <div className="fltrbx2">
                          <div className="fltrshdw2"></div>
                        </div>
                        <div className="lcitypic">
                          <img src={op2} alt='demo'/>
                        </div>
                        <div className="optnicon1">
                          <img src={opicon2} alt='demo'/>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 wow fadeInUp">
                      <div className="location-box">
                        <div className="fltrbx3">
                          <div className="fltrshdw3"></div>
                        </div>
                        <div className="lcitypic">
                          <img src={op3} alt='demo'/>
                        </div>
                        <div className="optnicon1">
                          <img src={opicon3} alt='demo'/>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 wow fadeInUp">
                      <div className="location-box">
                        <div className="fltrbx4">
                          <div className="fltrshdw4"></div>
                        </div>
                        <div className="lcitypic">
                          <img src={op4} alt='demo'/>
                        </div>
                        <div className="optnicon1">
                          <img src={opicon4} alt='demo'/>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 wow fadeInUp">
                      <div className="location-box">
                        <div className="fltrbx5">
                          <div className="fltrshdw5"></div>
                        </div>
                        <div className="lcitypic">
                          <img src={op6} alt='demo'/>
                        </div>
                        <div className="optnicon1">
                          <img src={opicon6} alt='demo'/>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 wow fadeInUp">
                      <div className="location-box">
                        <div className="fltrbx6">
                          <div className="fltrshdw6"></div>
                        </div>
                        <div className="lcitypic">
                          <img src={op5} alt='demo'/>
                        </div>
                        <div className="optnicon1">
                          <img src={opicon5} alt='demo'/>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 wow fadeInUp">
                      <div className="location-box">
                        <div className="fltrbx7">
                          <div className="fltrshdw7"></div>
                        </div>
                        <div className="lcitypic">
                          <img src={op7} alt='demo'/>
                        </div>
                        <div className="optnicon1">
                          <img src={opicon7} alt='demo'/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="showall-city">
                  <div className="sepratecity">
                    <p className="wow fadeInUp">Hyderabad</p>
                    <figure>
                      <img src={op5} alt='demo'/>
                      <span>
                        <img src={mb1} alt='demo'/>
                      </span>
                    </figure>
                  </div>
                  <div className="sepratecity wow slideInRight">
                    <p>Kolkata</p>
                    <figure>
                      <img src={op4} alt='demo'/>
                      <span>
                        <img src={mb2} alt='demo'/>
                      </span>
                    </figure>
                  </div>
                  <div className="sepratecity wow slideInLeft">
                    <p>Lucknow</p>
                    <figure>
                      <img src={op2} alt='demo'/>
                      <span>
                        <img src={mb3} alt='demo'/>
                      </span>
                    </figure>
                  </div>
                  <div className="sepratecity wow slideInRight">
                    <p>Delhi</p>
                    <figure>
                      <img src={op1} alt='demo'/>
                      <span>
                        <img src={mb4} alt='demo'/>
                      </span>
                    </figure>
                  </div>
                  <div className="sepratecity wow slideInLeft">
                    <p>Mumbai</p>
                    <figure>
                      <img src={op3} alt='demo'/>
                      <span>
                        <img src={mb5} alt='demo'/>
                      </span>
                    </figure>
                  </div>
                  <div className="sepratecity wow slideInRight">
                    <p>Banglore</p>
                    <figure>
                      <img src={op6} alt='demo'/>
                      <span>
                        <img src={mb6} alt='demo'/>
                      </span>
                    </figure>
                  </div>
                  <div className="sepratecity wow slideInLeft">
                    <p>Chennai</p>
                    <figure>
                      <img src={op7} alt='demo'/>
                      <span>
                        <img src={mb7} alt='demo'/>
                      </span>
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </section>
  
          <section className="our-coverage dksvrsn">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-12 wow fadeInUp">
                  <div className="covgetxt">
                    <h2>Our City-Wide Coverage </h2>
                    <p>
                      Collecting weekly waste across multiple pin-codes in all
                      parts of the city
                    </p>
                    <a
                      href="/partner1"
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(setLoading(true));
                        navigate("/partner1");
                      }}
                    >
                      Partner With Us
                    </a>
                  </div>
                </div>
                <div className="col-lg-6 col-6 wow fadeInUp">
                  <div className="coverge-box">
                    <span>
                      <img src={opicon1} alt='demo'/>
                    </span>
                    <figure>
                      <img src={covr1} alt='demo'/>
                    </figure>
                    <h4>New Delhi</h4>
                  </div>
                </div>
              </div>
              <div className="row cvrgemrgn fstrow">
                <div className="col-lg-6 col-6 wow fadeInUp">
                  <div className="coverge-box">
                    <span>
                      <img src={opicon6} alt='demo'/>
                    </span>
                    <figure>
                      <img src={covr2} alt='demo'/>
                    </figure>
                    <h4>Bengaluru</h4>
                  </div>
                </div>
                <div className="col-lg-6 col-6 wow fadeInUp">
                  <div className="coverge-box">
                    <span>
                      <img src={opicon3} alt='demo'/>
                    </span>
                    <figure>
                      <img src={covr3} alt='demo'/>
                    </figure>
                    <h4>Mumbai</h4>
                  </div>
                </div>
              </div>
              <div className="row fstrow3">
                <div className="col-lg-6 col-6 wow fadeInUp">
                  <div className="coverge-box">
                    <span>
                      <img src={opicon7} alt='demo'/>
                    </span>
                    <figure>
                      <img src={covr6} alt='demo'/>
                    </figure>
                    <h4>Chennai</h4>
                  </div>
                </div>
                <div className="col-lg-6 col-6 wow fadeInUp">
                  <div className="coverge-box">
                    <span>
                      <img src={opicon4} alt='demo'/>
                    </span>
                    <figure>
                      <img src={covr7} alt='demo'/>
                    </figure>
                    <h4>Kolkata</h4>
                  </div>
                </div>
              </div>
              <div className="row fstrow2">
                <div className="col-lg-6 col-6 wow fadeInUp">
                  <div className="coverge-box">
                    <span>
                      <img src={opicon2} alt='demo'/>
                    </span>
                    <figure>
                      <img src={covr4} alt='demo'/>
                    </figure>
                    <h4>Lucknow</h4>
                  </div>
                </div>
                <div className="col-lg-6 col-6 wow fadeInUp">
                  <div className="coverge-box">
                    <span>
                      <img src={opicon5} alt='demo'/>
                    </span>
                    <figure>
                      <img src={covr5} alt='demo'/>
                    </figure>
                    <h4>Hyderabad</h4>
                  </div>
                </div>
              </div>
              
            </div>
          </section>
          <section className="our-coverage mobile-viw">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-12">
                  <div className="covgetxt">
                    <h2>Our City-Wide Coverage </h2>
                    <p>
                      Collecting weekly waste across multiple pin-codes in all
                      parts of the city
                    </p>
                    <a
                      href="/partner1"
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(setLoading(true));
                        navigate("/partner1");
                      }}
                    >
                      Partner With Us
                    </a>
                  </div>
                </div>
                <div className="col-lg-6 col-6">
                  <div className="coverge-box">
                    <span>
                      <img src={opicon5} alt='demo'/>
                    </span>
                    <figure>
                      <img src={covr5} alt='demo'/>
                    </figure>
                    <h4>Hyderabad</h4>
                  </div>
                </div>
                <div className="col-lg-6 col-6">
                  <div className="coverge-box">
                    <span>
                      <img src={opicon4} alt='demo'/>
                    </span>
                    <figure>
                      <img src={covr7} alt='demo'/>
                    </figure>
                    <h4>Kolkata</h4>
                  </div>
                </div>
                <div className="col-lg-6 col-6">
                  <div className="coverge-box">
                    <span>
                      <img src={opicon3} alt='demo'/>
                    </span>
                    <figure>
                      <img src={covr3} alt='demo'/>
                    </figure>
                    <h4>Mumbai</h4>
                  </div>
                </div>
                <div className="col-lg-6 col-6">
                  <div className="coverge-box">
                    <span>
                      <img src={opicon1} alt='demo'/>
                    </span>
                    <figure>
                      <img src={covr1} alt='demo'/>
                    </figure>
                    <h4>New Delhi</h4>
                  </div>
                </div>
                <div className="col-lg-6 col-6">
                  <div className="coverge-box">
                    <span>
                      <img src={opicon7} alt='demo'/>
                    </span>
                    <figure>
                      <img src={covr6} alt='demo'/>
                    </figure>
                    <h4>Chennai</h4>
                  </div>
                </div>
                <div className="col-lg-6 col-6">
                  <div className="coverge-box">
                    <span>
                      <img src={opicon6} alt='demo'/>
                    </span>
                    <figure>
                      <img src={covr2} alt='demo'/>
                    </figure>
                    <h4>Bengaluru</h4>
                  </div>
                </div>
  
                <div className="col-lg-6 col-6 dkclumnon"></div>
                <div className="col-lg-6 col-6">
                  <div className="coverge-box">
                    <span>
                      <img src={opicon2} alt='demo'/>
                    </span>
                    <figure>
                      <img src={covr4} alt='demo'/>
                    </figure>
                    <h4>Lucknow</h4>
                  </div>
                </div>
              </div>
            </div>
          </section>
  
          <section className="ourground-partner" id='partners' >
            <div className="container">
              <h2>Our ground partners</h2>
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="groundbox">
                    <div className="grund-upper">
                      <figure>
                        <img src={prtnr1} alt='demo'/>
                      </figure>
                      <span>
                        <GoLocation /> Hyderabad
                      </span>
                    </div>
                    <div className="ground-middle-conent">
                      <a href="https://wasteventures.com/" target='_blank'>
                        <TbWorld /> https://wasteventures.com/
                      </a>
                      <p>
                        Waste Venture’s vision is to create a better quality of
                        life for informal waste pickers and rag pickers by
                        including them in low value plastics collection
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="groundbox">
                    <div className="grund-upper">
                      <figure>
                        <img src={prtnr2} alt='demo'/>
                      </figure>
                      <span>
                        <GoLocation /> Kolkata
                      </span>
                    </div>
                    <div className="ground-middle-conent">
                      <a href="https://sngreenovation.com/" target='_blank'>
                        <TbWorld /> https://sngreenovation.com/
                      </a>
                      <p>
                        SN Greenovation is committed to opening doors to help
                        educate and motivate the general public, businesses,
                        government agencies and other service providers about the
                        benefits of using alternative fuels and environmentally
                        responsible methods
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12 ">
                  <div className="groundbox">
                    <div className="grund-upper">
                      <figure>
                        <img src={prtnr3} alt='demo'/>
                      </figure>
                      <span>
                        <GoLocation /> Bengaluru
                      </span>
                    </div>
                    <div className="ground-middle-conent">
                      <a href="https://shudh-labh.com/" target='_blank'>
                        <TbWorld /> https://shudh-labh.com/
                      </a>
                      <p>
                      Shudh-Labh aims at developing economically viable technology for handling and processing all types of domestic waste. They are focused on upcycling dry waste and enhancing value recovery from waste
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="groundbox">
                    <div className="grund-upper">
                      <figure>
                        <img src={prtnr4} alt='demo'/>
                      </figure>
                      <span>
                        <GoLocation /> Lucknow
                      </span>
                    </div>
                    <div className="ground-middle-conent">
                      <a href="https://www.prithviinnovations.org/" target='_blank'>
                        <TbWorld /> https://www.prithviinnovations.org/
                      </a>
                      <p>
                        Prithvi Innovations' vision is to carry out promotional,
                        developmental and transformational activities in the areas
                        of Education, Environment, Management, Health & Nutrition
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="groundbox">
                    <div className="grund-upper">
                      <figure>
                        <img src={prtnr5} alt='demo'/>
                      </figure>
                      <span>
                        <GoLocation /> Mumbai
                      </span>
                    </div>
                    <div className="ground-middle-conent">
                      <a href="http://www.sampurnearth.com/" target='_blank'>
                        <TbWorld /> http://www.sampurnearth.com/
                      </a>
                      <p>
                        Sampurn(e)arth is a social enterprise. We believe in a
                        triple bottom-line approach wherein our projects are
                        Environment Friendly, Engage with the informal sector and
                        are economically self-sustaining
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
  
          <section className="get-inspired prtnrship">
            <div className="container">
              <h2>With long lasting partnerships</h2>
              <div className="get-inspired-inner algnitm">
                <div className="hersey-box wow fadeInUp">
                  <div className="inspired-box">
                    <span>
                      <img src={ptshp1} alt='demo'/>
                    </span>
                    <p>
                      <img className="cmtsxty" src={sxty} />
                      Shudh Labh Solutions Pvt Ltd and Bintix Waste Research have
                      been in partnership for over 5 years now. During this
                      period, their systems have helped residents of apartments
                      recycle their waste effectively and also providing
                      traceability too. Traceability of waste is an important
                      aspect of waste management as it helps keep waste out of
                      landfills.
                      <img className="cmtninty" src={ninty} alt='demo'/>
                    </p>
  
                    <div className="funder-sec">
                      <div className="fndrpic">
                        <img src={fundrpic} alt='demo'/>
                      </div>
                      <div className="fundrinfo">
                        <p>Ramanan Natarajan</p>
                        <strong>Founder</strong>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="kellogs wow fadeInUp">
                  <div className="inspired-box">
                    <span>
                      <img src={ptshp2} alt='demo'/>
                    </span>
                    <p>
                      <img className="cmtsxty" src={sxty} alt='demo'/>
                      We felt extremely happy when Bintix offered us to be it's
                      executing Ground Partner in Lucknow city March 2020 as we
                      share the same passion and concern for environment.
                      <img className="cmtninty" src={ninty} alt='demo'/>{" "}
                    </p>
                    <div className="funder-sec">
                      <div className="fndrpic">
                        <img src={fundrpic} alt='demo'/>
                      </div>
                      <div className="fundrinfo">
                        <p>Anuradha Gupta</p>
                        <strong>Founder</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
  
          <section className="process-partner">
            <div className="container">
              <div className="precss-heding wow fadeInUp">
                <h2>our three step process for partnership</h2>
                <p>We do not compete. We always seek to collaborate. </p>
              </div>
  
              <div className="processing-info">
                <div className="stepinformation">
                  <div className="steb-by-sec ">
                    <div className="fast-shape">
                      <img src={stepbg1} alt='demo'/>
                      <img src={mshp1} alt='demo'/>
                      <div className="stpbx">
                        <span>STEP</span>
                        <strong>01</strong>
                      </div>
                      <i>
                        <img src={line} alt='demo'/>
                      </i>
                    </div>
                    <div className="shparw1">
                      <img src={stp1} alt='demo'/>
                      <img src={mstp1} alt='demo'/>
                    </div>
                    <h4>Search</h4>
                    <p>
                    We look out for established and/or empaneled organizations in waste management 
                    </p>
                  </div>
                  <div className="steb-by-sec">
                    <div className="fast-shape2">
                      <img src={stepbg2} alt='demo'/>
                      <img src={mshp2} alt='demo'/>
                      <div className="stpbx">
                        <span>STEP</span>
                        <strong>02</strong>
                      </div>
                      <i>
                        <img src={line} alt='demo'/>
                      </i>
                    </div>
                    <div className="shparw2">
                      <img src={stp2} alt='demo'/>
                      <img src={mstp1} alt='demo'/>
                    </div>
                    <h4>Select</h4>
                    <p>
                      We select partners who Have an accomplished body of work &
                      adhere to our checks & SOPs
                    </p>
                  </div>
                  <div className="steb-by-sec">
                    <div className="fast-shape3">
                      <img src={stepbg3} alt='demo'/>
                      <img src={mshp3} alt='demo'/>
                      <div className="stpbx">
                        <span>STEP</span>
                        <strong>03</strong>
                      </div>
                      <i>
                        <img src={line} alt='demo'/>
                      </i>
                    </div>
                    <div className="shparw3">
                      <img src={stp3} alt='demo'/>
                      <img src={mstp1} alt='demo'/>
                    </div>
                    <h4>Support</h4>
                    <p>
                      We ensure long term business sustainability for our
                      partners, by providing flexible business models, customer
                      dashboards & full tech support
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>  
          <section className="movement-prt wow fadeInUp">
            <div className="container">
              <h3>Be a Part Of the Movement</h3>
              <a
                href="/partner1"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(setLoading(true));
                  navigate("/partner1");
                }}
              >
                Partner With Us
              </a>
            </div>
          </section>
        </main>
      </>

    )
}

export default ImpactDynamic



