import React, { useEffect } from 'react'
import logo from "../../images/logo.png"
import {  ImTwitter,  ImFacebook, ImGoogle, ImLinkedin } from "react-icons/im";
import { FaInstagram } from "react-icons/fa";
import WOW from 'wowjs';
import { useNavigate } from 'react-router-dom';
import $ from "jquery";
import { useDispatch } from 'react-redux';
import { setLoading } from '../../Redux/ActionCreater';
function Footer() {
  const navigate=useNavigate();
  let dispatch=useDispatch()
const clickNav=(e,slug)=>{
  e.preventDefault();
  dispatch(setLoading(true));
  navigate(slug)
}
  useEffect(() => {
    console.log("first")

    new WOW.WOW({
      live: false
    }).init();
  }, [])


  const clickAboutUs=(e,slug)=>{
    e.preventDefault();
    console.log(window.location.pathname.includes("about-us"))
    if(!window.location.pathname.includes("about-us")){
      dispatch(setLoading(true));
    }
    navigate(slug)
    
  }

  
  const clickContactUs=(e,slug)=>{
    e.preventDefault();
    if(!window.location.pathname.includes("about-us")){
      dispatch(setLoading(true));
      navigate(slug)
    }else{
      $('html, body').animate(
        {
          scrollTop: $("#contact").offset().top-200
        },
      )
    }
    
 
    
  }

  const clickfaqtUs=(e,slug)=>{
    e.preventDefault();
    if(!window.location.pathname.includes("collect-my-waste")){
      dispatch(setLoading(true));
      navigate(slug)
    }
    
  }

  return (
    <footer className='footer-sec'>
    <div className='container'>
      <div className='row'>
        <div className='col-lg-6 col-md-6 col-sm-12 wow fadeInUp'>
          <div className='footer-abut'>
            <div className='fotrlgo wow fadeInUp'>
              <a href=''><img src={logo} alt='logo' width={"100px"} height={"58px"} /></a>
            </div>
            <p>Bintix Waste Research Pvt. Ltd.</p>
            <span>Follow Us</span>
            <ul className='social-link wow fadeInUp'>
              <li><a href=''><ImTwitter/></a></li>
              <li><a href=''><FaInstagram/></a></li>
              <li><a href=''><ImFacebook/></a></li>
              <li><a href=''><ImGoogle/></a></li>
              <li><a href='https://www.linkedin.com/company/bintix' target='_blank'><ImLinkedin/></a></li>
            </ul>
            <ul className='prvcylnk wow fadeInUp'>
              <li><a href='/terms-policy' onClick={(e)=>clickNav(e,"/terms-policy")}>Terms & Privacy Policy</a></li>
            </ul>
          </div>
        </div>
        <div className='col-lg-3 col-md-3 col-sm-6 col-12 wow fadeInUp'>
          <div className='foter-menu'>
            <h4>Menu</h4>
            <ul>
              <li><a href='/' onClick={(e)=>clickNav(e,"/")}>Home</a></li>
              <li><a href='/about-us'onClick={(e)=>clickAboutUs(e,"/about-us")}>About</a></li>
              <li><a href='/collect-my-waste' onClick={(e)=>clickNav(e,"/collect-my-waste")}>Collect<br/>My Waste</a></li>
            </ul>
            <ul>
              <li><a href='/context' onClick={(e)=>clickNav(e,"/context")}>Clients</a></li>
              <li><a href='/movement' onClick={(e)=>clickNav(e,"/movement")}>Join the<br/>Movement</a></li>
            </ul>
          </div>
        </div>
        <div className='col-lg-3 col-md-3 col-sm-6 col-12 wow fadeInUp'>
          <div className='foter-menu'>
            <h4>About Us</h4>
            <ul>
              <li><a href='/about-us' onClick={(e)=>clickAboutUs(e,"/about-us")}>Who we are?</a></li>
              <li><a href='/about-us' onClick={(e)=>clickContactUs(e,"/about-us#contact")}>Contact Us</a></li>
              <li><a href='/collect-my-waste#faq' onClick={(e)=>clickfaqtUs(e,"/collect-my-waste#faq")}>FAQ</a></li>
            </ul>
            <ul>
              <li><a href='/about-us' onClick={(e)=>clickContactUs(e,"/about-us#contact")}>Help</a></li>
            </ul>
          </div>
        </div>
        </div>
      </div>
  </footer>

  )
}

export default Footer