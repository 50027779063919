import React, { useEffect, useState } from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './News.css';
import home from "../../images/newsbanner.jpg"
import mobilebnr from "../../images/mnews.jpg"
import "swiper/css";
import news1 from "../../images/news1.jpg"
import nuser from "../../images/nuser.jpg"
import blog1 from "../../images/blog1.jpg"
import blog2 from "../../images/blog2.jpg"
import blog3 from "../../images/blog3.jpg"
import blog4 from "../../images/blog4.jpg"
import blog5 from "../../images/blog5.jpg"
import blog6 from "../../images/blog6.jpg"
import blog7 from "../../images/blog7.jpg"
import blog8 from "../../images/blog8.jpg"
import blog9 from "../../images/blog9.jpg"
import articlpic1 from "../../images/articlpic1.jpg"
import { BsChevronRight } from "react-icons/bs";
import { setHeaderType } from '../../Redux/ActionCreater';
import { useDispatch } from 'react-redux';



function News() {
    let dispatch=useDispatch()
    const options = {
        loop: true,
        margin: 0,
        // height:600,
        items: 1,
        autoplay: false,
        autoplaySpeed: 1200,
        rewind: false,
        lazyLoad: true,
        mouseDrag: false,
        touchDrag: false,
        // navRewind: true,
        // autoplayTimeout:6000,
        // navSpeed: 2000,
        // dragEndSpeed: 2000,
        dots: true,
        nav: false
    };
    useEffect(()=>{
        dispatch(setHeaderType(1))
    },[])

    return (
        <>
            <section className="bannercontainer abutpgebnr">
                <div id="NoiseBanner">

                    <OwlCarousel className="owl-theme" {...options}  >

                        <div className="item">
                            <img className='dkstbnr' src={home} style={{ height: "auto", width: "100%" }} alt='demo'/>
                            <img className='mbnr' src={mobilebnr} style={{ height: "auto", width: "100%" }} alt='demo'/>
                            <div className="newsbnr-content">
                                <div className="container">
                                    <div className='nwsbntxt'>
                                        <span>Technology</span>
                                        <h3>The Impact of Technology on the Workplace: How Technology is
                                            Changing</h3>
                                        <div className='media'>
                                            <div className='mediapic'><img src={nuser} alt='demo'/></div>
                                            <div className='media-object'>
                                                <em>Tracey Wilson<i>August 20, 2022</i></em>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </OwlCarousel>
                </div>
            </section>


            <section className='latest-news-sec'>
                <div className='container'>
                    <div className='news-heading'>
                        <h2>Latest News</h2>
                        <a href=''>All News</a>
                    </div>
                    <div className='row'>
                        <div className='col-lg-4'>
                            <div className='news-box'>
                                <div className='newsbx-inner'>
                                    <figure><img src={news1} alt='demo'/></figure>

                                    <div className='newscont'>
                                        <span>Technology</span>
                                        <h3>The Impact of Technology on the Workplace: How Technology is \</h3>
                                        <div className='media'>
                                            <div className='mediapic'><img src={nuser} alt='demo'/></div>
                                            <div className='media-object'>
                                                <em>Tracey Wilson<i>August 20, 2022</i></em>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='news-box'>
                                <div className='newsbx-inner'>
                                    <figure><img src={news1} alt='demo'/></figure>

                                    <div className='newscont'>
                                        <span>Technology</span>
                                        <h3>The Impact of Technology on the Workplace: How Technology is \</h3>
                                        <div className='media'>
                                            <div className='mediapic'><img src={nuser} alt='demo'/></div>
                                            <div className='media-object'>
                                                <em>Tracey Wilson<i>August 20, 2022</i></em>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='news-box'>
                                <div className='newsbx-inner'>
                                    <figure><img src={news1} alt='demo'/></figure>

                                    <div className='newscont'>
                                        <span>Technology</span>
                                        <h3>The Impact of Technology on the Workplace: How Technology is \</h3>
                                        <div className='media'>
                                            <div className='mediapic'><img src={nuser} alt='demo'/></div>
                                            <div className='media-object'>
                                                <em>Tracey Wilson<i>August 20, 2022</i></em>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='latest-news-sec'>
                <div className='container'>
                    <div className='news-heading'>
                        <h2>Latest Blogs</h2>
                    </div>
                    <div className='row'>
                        <div className='col-lg-4'>
                            <div className='blog-box'>
                                <div className='newsbx-inner'>
                                    <figure><img src={blog1} alt='demo'/></figure>

                                    <div className='newscont'>
                                        <span>Technology</span>
                                        <h3>The Impact of Technology on the Workplace: How Technology is \</h3>
                                        <div className='media'>
                                            <div className='mediapic'><img src={nuser} alt='demo'/></div>
                                            <div className='media-object'>
                                                <em>Tracey Wilson<i>August 20, 2022</i></em>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='blog-box'>
                                <div className='newsbx-inner'>
                                    <figure><img src={blog2} alt='demo'/></figure>

                                    <div className='newscont'>
                                        <span>Technology</span>
                                        <h3>The Impact of Technology on the Workplace: How Technology is \</h3>
                                        <div className='media'>
                                            <div className='mediapic'><img src={nuser} alt='demo'/></div>
                                            <div className='media-object'>
                                                <em>Tracey Wilson<i>August 20, 2022</i></em>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='blog-box'>
                                <div className='newsbx-inner'>
                                    <figure><img src={blog3} alt='demo'/></figure>

                                    <div className='newscont'>
                                        <span>Technology</span>
                                        <h3>The Impact of Technology on the Workplace: How Technology is \</h3>
                                        <div className='media'>
                                            <div className='mediapic'><img src={nuser} alt='demo'/></div>
                                            <div className='media-object'>
                                                <em>Tracey Wilson<i>August 20, 2022</i></em>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='blog-box'>
                                <div className='newsbx-inner'>
                                    <figure><img src={blog4} alt='demo'/></figure>

                                    <div className='newscont'>
                                        <span>Technology</span>
                                        <h3>The Impact of Technology on the Workplace: How Technology is \</h3>
                                        <div className='media'>
                                            <div className='mediapic'><img src={nuser} alt='demo'/></div>
                                            <div className='media-object'>
                                                <em>Tracey Wilson<i>August 20, 2022</i></em>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='blog-box'>
                                <div className='newsbx-inner'>
                                    <figure><img src={blog5} alt='demo'/></figure>

                                    <div className='newscont'>
                                        <span>Technology</span>
                                        <h3>The Impact of Technology on the Workplace: How Technology is \</h3>
                                        <div className='media'>
                                            <div className='mediapic'><img src={nuser} alt='demo'/></div>
                                            <div className='media-object'>
                                                <em>Tracey Wilson<i>August 20, 2022</i></em>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='blog-box'>
                                <div className='newsbx-inner'>
                                    <figure><img src={blog6} alt='demo'/></figure>

                                    <div className='newscont'>
                                        <span>Technology</span>
                                        <h3>The Impact of Technology on the Workplace: How Technology is \</h3>
                                        <div className='media'>
                                            <div className='mediapic'><img src={nuser} alt='demo'/></div>
                                            <div className='media-object'>
                                                <em>Tracey Wilson<i>August 20, 2022</i></em>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='blog-box'>
                                <div className='newsbx-inner'>
                                    <figure><img src={blog7} alt='demo'/></figure>

                                    <div className='newscont'>
                                        <span>Technology</span>
                                        <h3>The Impact of Technology on the Workplace: How Technology is \</h3>
                                        <div className='media'>
                                            <div className='mediapic'><img src={nuser} alt='demo'/></div>
                                            <div className='media-object'>
                                                <em>Tracey Wilson<i>August 20, 2022</i></em>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='blog-box'>
                                <div className='newsbx-inner'>
                                    <figure><img src={blog8} alt='demo'/></figure>

                                    <div className='newscont'>
                                        <span>Technology</span>
                                        <h3>The Impact of Technology on the Workplace: How Technology is \</h3>
                                        <div className='media'>
                                            <div className='mediapic'><img src={nuser} alt='demo'/></div>
                                            <div className='media-object'>
                                                <em>Tracey Wilson<i>August 20, 2022</i></em>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <div className='blog-box'>
                                <div className='newsbx-inner'>
                                    <figure><img src={blog9} alt='demo'/></figure>

                                    <div className='newscont'>
                                        <span>Technology</span>
                                        <h3>The Impact of Technology on the Workplace: How Technology is \</h3>
                                        <div className='media'>
                                            <div className='mediapic'><img src={nuser} alt='demo'/></div>
                                            <div className='media-object'>
                                                <em>Tracey Wilson<i>August 20, 2022</i></em>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-12'>
                            <div className='newsviw-all'>
                                <a href=''>View All Post</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className='latest-news-sec'>
                <div className='container'>
                    <div className='news-heading'>
                        <h2>Latest Articles</h2>
                    </div>

                    <div className='article-box'>
                        <div className='row'>
                            <div className='col-lg-7'>
                                <div className='articlepic'>
                                    <img src={articlpic1} alt='demo'/>
                                </div>
                            </div>
                            <div className='col-lg-5'>
                                <div className='articletxt'>
                                    <em><i>Analytics</i> 5 min read</em>
                                    <h3>Your passport to the web3 economy</h3>
                                    <p>If you’ve read this far and you’re wondering what “web3” is exactly,
                                        this is one of those need-to-knows, and it’s pretty simple.
                                        We’ll explain more below, but in short web3 is the next era of the
                                        internet in which blockchain technology will play a central role.
                                    </p>
                                    <a href="">Read more <BsChevronRight /></a>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='article-box'>
                        <div className='row'>
                            <div className='col-lg-7'>
                                <div className='articlepic'>
                                    <img src={articlpic1} alt='demo'/>
                                </div>
                            </div>
                            <div className='col-lg-5'>
                                <div className='articletxt'>
                                    <em><i>Analytics</i> 5 min read</em>
                                    <h3>Your passport to the web3 economy</h3>
                                    <p>If you’ve read this far and you’re wondering what “web3” is exactly,
                                        this is one of those need-to-knows, and it’s pretty simple.
                                        We’ll explain more below, but in short web3 is the next era of the
                                        internet in which blockchain technology will play a central role.
                                    </p>
                                    <a href="">Read more <BsChevronRight /></a>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='article-box'>
                        <div className='row'>
                            <div className='col-lg-7'>
                                <div className='articlepic'>
                                    <img src={articlpic1} alt='demo'/>
                                </div>
                            </div>
                            <div className='col-lg-5'>
                                <div className='articletxt'>
                                    <em><i>Analytics</i> 5 min read</em>
                                    <h3>Your passport to the web3 economy</h3>
                                    <p>If you’ve read this far and you’re wondering what “web3” is exactly,
                                        this is one of those need-to-knows, and it’s pretty simple.
                                        We’ll explain more below, but in short web3 is the next era of the
                                        internet in which blockchain technology will play a central role.
                                    </p>
                                    <a href="">Read more <BsChevronRight /></a>
                                </div>
                            </div>
                        </div>

                    </div>


                </div>

            </section>






        </>

    )
}

export default News