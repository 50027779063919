import React, { useEffect, useState } from 'react'
import './Partner.css';
import {  FaAngleDown,  } from "react-icons/fa";
import brd from "../../images/brd.png"
import "swiper/css";
import $ from "jquery";
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { BASE_URL } from '../../config';
import { setHeaderType, setLoading } from '../../Redux/ActionCreater';
import { useDispatch } from 'react-redux';
import sxty from "../../images/sxty.png"
import ninty from "../../images/ninty.png"
import ptshp1 from "../../images/ptshp1.png"
import ptshp2 from "../../images/ptshp2.png"
import fundrpic from "../../images/fundrpic.jpg"
import { Helmet } from 'react-helmet-async';


function Partner1() {
    let dispatch=useDispatch()
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        mobile: "",
        city: "",
        company: "",
        job: "",
        how: ""
    })


    let onChangeHandle = (e) => {
        if (e.target.name === "mobile") {
            var reg = /^\d+$/;
            if ((reg.test(e.target.value) || e.target.value === "") ){
                setFormData({ ...formData, [e.target.name]: e.target.value });
            }
        }else if(e.target.name === "name"||e.target.name === "job"||e.target.name === "city"||e.target.name === "company"){
            var reg = /^[a-zA-Z ]+$/;
            if ((reg.test(e.target.value) || e.target.value === "") ){
                setFormData({ ...formData, [e.target.name]: e.target.value });
            }
        }
         else {
        setFormData({ ...formData, [e.target.name]: e.target.value })
        }
    }

    let handleSubmit = async (e) => {
        e.preventDefault()
        try {
            if (formData.name === "") {
                return toast.error("Please enter name!")
            }
            if (!/^[a-zA-Z ]+$/.test(formData.name)) {
                return toast.error("Please enter only alphabets in name!")
            }
            if (formData.email === "") {
                return toast.error("Please enter email!")
            }
            if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(formData.email)) {
                return toast.error("Please enter a valid email!")
            }
            if (formData.mobile === "") {
                return toast.error("Please enter mobile number!")
            }
            if (formData.mobile.length !== 10) {
                return toast.error("Please enter a valid mobile number!")
            }
            if (formData.city === "") {
                return toast.error("Please enter city!")
            }
            if (!/^[a-zA-Z ]+$/.test(formData.city)) {
                return toast.error("Please enter only alphabets in city!")
            }
            if (formData.company === "") {
                return toast.error("Please enter company name!")
            }
            if (!/^[a-zA-Z ]+$/.test(formData.company)) {
                return toast.error("Please enter only alphabets in company name!")
            }
            if (formData.job === "") {
                return toast.error("Please enter job title!")
            }
            if (!/^[a-zA-Z ]+$/.test(formData.job)) {
                return toast.error("Please enter only alphabets in job title!")
            }
            if (formData.how === "") {
                return toast.error("Please select  How did you hear about us!")
            }
           
            let data = await axios.post(`${BASE_URL}/partner-with-us`, {
                "full_name": formData.name,
                "email": formData.email,
                "mobile": formData.mobile,
                "job_title": formData.job,
                "city": formData.city,
                "company_name": formData.company,
                "how_did_you_hear_about_us": formData.how,
                "partner_type": "business"
            }).then((res) => {
                if (res.data.status === 200) {
                    toast.success(res.data.msg);
                    setFormData({
                        name: "",
        email: "",
        mobile: "",
        city: "",
        company: "",
        job: "",
        how: ""
                    })
                } else {
                    toast.error(res.data.msg)
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(()=>{
        dispatch(setHeaderType(2))
        window.scrollTo(0, 0);
  
  setTimeout(() => {
    dispatch(setLoading(false))
    
  }, 1000);
    },[])

    return (
        <>
        <Helmet>
        <title>Bintix::Partner1</title>
        <meta property="og:title" content="Bintix::Partner1" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
            


            <section className='movement-sec'>
                <div className='container'>
                    <div className='movement-box'>
                        <span className='brdpic'><img src={brd}/></span>
                        <div className='movenent-inner'>
                            <h3>Partner us on your business </h3>

                            <div className='detsp'>
                                <p>Please Enter your details & we will get back to you!</p>
                            </div>

                            <div className='frmfield'>
                                <input name='name' value={formData.name} onChange={onChangeHandle} className='inputfldtxt' type='text' placeholder='Full Name' />
                            </div>
                            <div className='frmfield'>
                                <input name='email' value={formData.email} onChange={onChangeHandle} className='inputfldtxt' type='text' placeholder='Business Email' />
                            </div>
                            <div className='frmfield'>
                                <input name='mobile' value={formData.mobile} onChange={onChangeHandle} className='inputfldtxt' type='text' placeholder='Mobile' />
                            </div>
                            <div className='frmfield'>
                                <input name='city' value={formData.city} onChange={onChangeHandle} className='inputfldtxt' type='text' placeholder='City' />
                            </div>
                            <div className='frmfield'>
                                <input name='company' value={formData.company} onChange={onChangeHandle} className='inputfldtxt' type='text' placeholder='Company Name' />
                            </div>
                            <div className='frmfield'>
                                <input name='job' value={formData.job} onChange={onChangeHandle} className='inputfldtxt' type='text' placeholder='Job Title' />
                            </div>
                            

                            <div className='frmfield'>
                                <span className='drpicn'><FaAngleDown /></span>
                                <select name='how' value={formData.how} onChange={onChangeHandle} className='inputfldtxt'>
                                    <option value="">How did you hear about us?</option>
                                    <option value={"Existing Bintix users"}>Existing Bintix users</option>
                                    <option value={"My housing society"}>My housing society</option>
                                    <option value={"Friends/family"}>Friends/family</option>
                                    <option value={"Facebook"}>Facebook</option>
                                    <option value={"Instagram"}>Instagram</option>
                                    <option value={"Others"}>Others</option>

                                </select>
                            </div>

                            <div className='frmfield btntpmrgn'>
                                <button onClick={handleSubmit}>Submit</button>
                            </div>
                        </div>


                    </div>




                </div>
            </section>

            <section className="get-inspired prtnrship">
            <div className="container">
              <h2>With long lasting partnerships</h2>
              <div className="get-inspired-inner algnitm">
                <div className="hersey-box wow fadeInUp">
                  <div className="inspired-box">
                    <span>
                      <img src={ptshp1} />
                    </span>
                    <p>
                      <img className="cmtsxty" src={sxty} />
                      Shudh Labh Solutions Pvt Ltd and Bintix Waste Research have
                      been in partnership for over 5 years now. During this
                      period, their systems have helped residents of apartments
                      recycle their waste effectively and also providing
                      traceability too. Traceability of waste is an important
                      aspect of waste management as it helps keep waste out of
                      landfills.
                      <img className="cmtninty" src={ninty} />
                    </p>
  
                    <div className="funder-sec">
                      <div className="fndrpic">
                        <img src={fundrpic} />
                      </div>
                      <div className="fundrinfo">
                        <p>Ramanan Natarajan</p>
                        <strong>Founder</strong>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="kellogs wow fadeInUp">
                  <div className="inspired-box">
                    <span>
                      <img src={ptshp2} />
                    </span>
                    <p>
                      <img className="cmtsxty" src={sxty} />
                      We felt extremely happy when Bintix offered us to be it's
                      executing Ground Partner in Lucknow city March 2020 as we
                      share the same passion and concern for environment.
                      <img className="cmtninty" src={ninty} />{" "}
                    </p>
                    <div className="funder-sec">
                      <div className="fndrpic">
                        <img src={fundrpic} />
                      </div>
                      <div className="fundrinfo">
                        <p>Anuradha Gupta</p>
                        <strong>Founder</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>


<ToastContainer/>

        </>

    )
}

export default Partner1