import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import home from "../../images/data-banner.jpg";
import map from "../../images/map2.png"
import mobilebnr from "../../images/mbdata-bnr.jpg"
import un1 from "../../images/un1.png"
import un2 from "../../images/un2.png"
import un3 from "../../images/un3.png"
import un4 from "../../images/un4.png"
import un5 from "../../images/un5.png"
import un6 from "../../images/un6.png"
import datawste from "../../images/datawste.png"
import evrybcod from "../../images/evrybcod.png"
import precscod from "../../images/precscod.png"
import of1 from "../../images/of1.png"
import of2 from "../../images/of2.png"
import of3 from "../../images/of3.png"
import of4 from "../../images/of4.png"
import of5 from "../../images/of5.png"
import of6 from "../../images/of6.png"
import p1 from "../../images/p1.png"
import p2 from "../../images/p2.png"
import p3 from "../../images/p3.png"
import p4 from "../../images/p4.png"
import p5 from "../../images/p5.png"
import p6 from "../../images/p6.png"
import p7 from "../../images/p7.png"
import p8 from "../../images/p8.png"
import p9 from "../../images/p9.png"
import p10 from "../../images/p10.png"
import get1 from "../../images/get1.png"
import get2 from "../../images/get2.png"
import get3 from "../../images/tbccc.png"
import grahax from "../../images/grahax.png"
import ctgrypic from "../../images/ctgrypic.png"
import ctgrypic1 from "../../images/ctgrypic1.png"
import ctgrypic2 from "../../images/ctgrypic2.png"
import vcse1 from "../../images/vcse1.png"
import vcse2 from "../../images/vcse2.png"
import vcse3 from "../../images/vcse3.png"
import vcse4 from "../../images/vcse4.png"
import vcse5 from "../../images/vcse5.png"
import vcse6 from "../../images/vcse6.png"
import vcse7 from "../../images/vcse7.png"
import sxty from "../../images/sxty.png"
import ninty from "../../images/ninty.png"
import dg1 from "../../images/dg1.png"
import dg2 from "../../images/dg2.png"
import ptrn from "../../images/ptrn.png";
import './Data.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { FaAngleRight } from "react-icons/fa";
import WOW from 'wowjs';
import { useDispatch } from 'react-redux';
import { setHeaderType, setLoading } from '../../Redux/ActionCreater';

function OurData() {
    const navigate = useNavigate();
    let dispatch = useDispatch()
    const options = {
        loop: true,
        margin: 0,
        // height:600,
        items: 1,
        autoplay: false,
        autoplaySpeed: 1200,
        rewind: false,
        lazyLoad: true,
        mouseDrag: false,
        touchDrag: false,
        dots: true,
        nav: false
    };

    const options5 = {
        loop: false,
        margin: 0,
        // height:600,
        items: 1,
        autoplay: false,
        autoplaySpeed: 1200,
        rewind: false,
        lazyLoad: true,
        mouseDrag: true,
        touchDrag: true,
        dots: false,
        nav: false,
        stagePadding: 6,
        left: -40,
        responsive: {
            0: {
                items: 1,
            },
            450: {
                items: 1,
            },
            768: {
                items: 1,
            },
            992: {
                items: 1,
            },
            1200: {
                items: 1,
            },

        }
    };

    const options6 = {
        loop: true,
        margin: 20,
        // height:600,
        items: 2,
        autoplay: true,
        autoplayTimeout: 2000,
        autoplaySpeed: 500,
        smartSpeed: 1000,
        rewind: false,
        lazyLoad: true,
        mouseDrag: true,
        touchDrag: true,
        dots: false,
        nav: false,
        stagePadding: 6,
        responsive: {
            0: {
                items: 2,
            },
            450: {
                items: 2,
            },
            768: {
                items: 4,
            },
            992: {
                items: 5,
            },
            1200: {
                items: 6,
                margin: 30,
            },

        }
    };


    useEffect(() => {
        dispatch(setHeaderType(1))
        new WOW.WOW({
            live: false
        }).init();
        // window.scrollTo(0, 0);
        setTimeout(() => {
            dispatch(setLoading(false))

        }, 2000);

    }, [])


    return (
        <>
            <Helmet>
                <title>Bintix::Our Data</title>
                <meta property="og:title" content="Bintix::our Data" />
                <link rel="canonical" href={window.location.href} />
            </Helmet>

            <main className='abut-page-bg cntxt-bg'>
                <section className="bannercontainer abutbnr clntbn ourdatabnr">
                    <div id="NoiseBanner" className>

                        <OwlCarousel className="owl-theme" {...options}  >

                            <div className="item">
                                <img className='dkstbnr' src={home} style={{ height: "auto", width: "100%" }} alt='demo'/>
                                <img className='mbnr' src={mobilebnr} style={{ height: "auto", width: "100%" }} alt='demo'/>
                                <div className="banner-cap">
                                    <div className="container">
                                        <div className="banrcontent wow fadeInUp">
                                            <h1 className='dkbntxt'>Power your brand With<br />
                                                real time consumption insights</h1>
                                            <h1 className='mbbntxt'>Power your brand With
                                                real time consumption insights</h1>
                                            <h4>Because a bin never lies!</h4>
                                            <a href="/partner2" onClick={(e) => { e.preventDefault(); dispatch(setLoading(true)); navigate("/partner2") }}>Get In Touch</a>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </OwlCarousel>
                    </div>
                </section>

                <section className='logo-slide dkstdatalgo'>
                    <OwlCarousel className="owl-theme" {...options6}  >

                        <div className="item">
                            <div className='lgoslide-inner'>
                                <img src={p1} alt='demo'/>
                            </div>
                        </div>
                        <div className="item">
                            <div className='lgoslide-inner'>
                                <img src={p2} alt='demo'/>
                            </div>
                        </div>
                        <div className="item">
                            <div className='lgoslide-inner'>
                                <img src={p3} alt='demo'/>
                            </div>
                        </div>
                        <div className="item">
                            <div className='lgoslide-inner'>
                                <img src={p4} alt='demo'/>
                            </div>
                        </div>
                        <div className="item">
                            <div className='lgoslide-inner'>
                                <img src={p5} alt='demo'/>
                            </div>
                        </div>
                        <div className="item">
                            <div className='lgoslide-inner'>
                                <img src={p6} alt='demo'/>
                            </div>
                        </div>
                        <div className="item">
                            <div className='lgoslide-inner'>
                                <img src={p7} alt='demo'/>
                            </div>
                        </div>
                        <div className="item">
                            <div className='lgoslide-inner'>
                                <img src={p8} alt='demo'/>
                            </div>
                        </div>
                        <div className="item">
                            <div className='lgoslide-inner'>
                                <img src={p9} alt='demo'/>
                            </div>
                        </div>
                        <div className="item">
                            <div className='lgoslide-inner'>
                                <img src={p10} alt='demo'/>
                            </div>
                        </div>

                    </OwlCarousel>
                </section>

                <div className='oudtatmobilelogo'>
                    <div className='logo-slide'>
                        <OwlCarousel className="owl-theme" {...options6}  >

                            <div className="item">
                                <div className='lgoslide-inner'>
                                    <img src={p9} alt='demo'/>
                                </div>
                            </div>
                            <div className="item">
                                <div className='lgoslide-inner'>
                                    <img src={p7} alt='demo'/>
                                </div>
                            </div>
                            <div className="item">
                                <div className='lgoslide-inner'>
                                    <img src={p3} alt='demo'/>
                                </div>
                            </div>


                        </OwlCarousel>
                    </div>
                    <div className='logo-slide'>
                        <OwlCarousel className="owl-theme" {...options6}  >

                            <div className="item">
                                <div className='lgoslide-inner'>
                                    <img src={p1} alt='demo'/>
                                </div>
                            </div>
                            <div className="item">
                                <div className='lgoslide-inner'>
                                    <img src={p5} alt='demo'/>
                                </div>
                            </div>
                            <div className="item">
                                <div className='lgoslide-inner'>
                                    <img src={p2} alt='demo'/>
                                </div>
                            </div>


                        </OwlCarousel>
                    </div>
                    <div className='logo-slide'>
                        <OwlCarousel className="owl-theme" {...options6}  >



                            <div className="item">
                                <div className='lgoslide-inner'>
                                    <img src={p4} alt='demo'/>
                                </div>
                            </div>
                            <div className="item">
                                <div className='lgoslide-inner'>
                                    <img src={p8} alt='demo'/>
                                </div>
                            </div>
                            <div className="item">
                                <div className='lgoslide-inner'>
                                    <img src={p6} alt='demo'/>
                                </div>
                            </div>
                            <div className="item">
                                <div className='lgoslide-inner'>
                                    <img src={p10} alt='demo'/>
                                </div>
                            </div>

                        </OwlCarousel>
                    </div>
                </div>

                <section className='uniquness-sec'>
                    <div className='container'>
                        <h2>Our Data Uniqueness</h2>
                        <div className='dataviw-dkstp'>
                            <div className='row'>
                                <div className='col-lg-4 col-md-6 col-6 wow fadeInUp'>
                                    <div className='uniqunes-inner'>
                                        <figure><img src={un1} alt='demo'/></figure>
                                        <figcaption>
                                            <p>Actual <br/> Consumption Data. No surveys. </p>
                                        </figcaption>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-6 col-6 wow fadeInUp'>
                                    <div className='uniqunes-inner'>
                                        <figure><img src={un2} alt='demo'/></figure>
                                        <figcaption>
                                            <p>Continuous Panel. Weekly. </p>
                                        </figcaption>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-6 col-6 wow fadeInUp'>
                                    <div className='uniqunes-inner'>
                                        <figure><img src={un3} alt='demo'/></figure>
                                        <figcaption>
                                            <p>Unmatched Depth </p>
                                            <span>(<FaAngleRight />1 Million SKUs) </span>
                                        </figcaption>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-6 col-6 wow fadeInUp'>
                                    <div className='uniqunes-inner'>
                                        <figure><img src={un4} alt='demo'/></figure>
                                        <figcaption>
                                            <p>Unmatched Coverage </p>
                                            <span>(100+ Categories across CPG, pharma, media, alcohol, staples)</span>
                                        </figcaption>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-6 col-6 wow fadeInUp'>
                                    <div className='uniqunes-inner'>
                                        <figure><img src={un5} alt='demo'/></figure>
                                        <figcaption>
                                            <p>Exclusive coverage of Gated &Premium communities</p>
                                        </figcaption>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-6 col-6 wow fadeInUp'>
                                    <div className='uniqunes-inner'>
                                        <figure><img src={un6} alt='demo'/></figure>
                                        <figcaption>
                                            <p>100% Coverage of Ecom & D2C Consumption</p>
                                        </figcaption>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </section>


                <section className='wvry-product'>
                    <div className='container'>
                        <div className='row align-items-center'>
                            <div className='col-lg-5 '>
                                <div className='evryprdct-content'>
                                    <h3>Every Product From Every Bin
                                        Is Cataloged...</h3>
                                    <img src={evrybcod} alt='demo'/>
                                </div>
                            </div>
                            <div className='col-lg-7'>
                                <div className="evrdypic"><img src={datawste} alt='demo'/></div>
                            </div>
                        </div>
                    </div>

                </section>

                <section className='unlok-sec wow fadeInUp'>
                    <div className='container'>
                        <div className='unlok-txt'>
                            <h3>...To unlock Never-Possible-Before
                                Consumption Insights </h3>
                        </div>
                    </div>
                </section>


                <section className='precision-sec'>
                    <div className='prcsion-code'><img src={precscod} alt='demo'/></div>
                    <div className='prcsion-txt wow fadeInUp'>
                        <p>Precision Insights.<br />
                            Incredible Results</p>
                        <a href="/partner2" onClick={(e) => { e.preventDefault(); dispatch(setLoading(true)); navigate("/partner2") }}>Contact Us</a>

                    </div>
                    <div className='erlylunch-sec '>
                        <div className='erlyinfo wow fadeInUp'>
                            <span>01</span>
                            <p>Early Launch Analysis For New Products. From 1st Month.</p>
                        </div>
                        <div className='erlyinfo wow fadeInUp'>
                            <span>02</span>
                            <p>Early Read of Emerging Consumer Needs</p>
                        </div>
                        <div className='erlyinfo wow fadeInUp'>
                            <span>03</span>
                            <p>Faster ROI Analysis Of Promotions</p>
                        </div>
                        <div className='erlyinfo wow fadeInUp'>
                            <span>04</span>
                            <p>Deep-Dives Into Categories Difficult To Track Historically</p>
                        </div>
                        <div className='erlyinfo wow fadeInUp'>
                            <span>05</span>
                            <p>Exclusive Cross-Category Insights (Eg CPG Vs Pharma)</p>
                        </div>
                        <div className='erlyinfo wow fadeInUp'>
                            <span>06</span>
                            <p>Precision HHs For Qual Research/CHVs</p>
                        </div>
                        <div className='erlyinfo wow fadeInUp'>
                            <span>07</span>
                            <p>Precision HHs For Sampling/Test market</p>
                        </div>

                    </div>
                </section>


                <section className='data-coverge-sec'>
                    <div className='container'>
                        <h2>Data Coverage</h2>
                        <div className='row'>
                            <div className='col-lg-6 wow fadeInUp'>
                                <div className='mapdata'>
                                    <img src={map} alt='demo'/>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='national-coverge wow fadeInUp'>
                                    <div className='mblidiv'>
                                        <h4>National Coverage</h4>
                                    </div>

                                    <div className='covaregae-info'>
                                        <div className='row'>
                                            <div className='col-lg-12 col-6'>
                                                <div className='cvrge-inner'>
                                                    <h5>&gt;25000 HHS</h5>
                                                </div>
                                            </div>
                                            <div className='col-lg-12 col-6'>
                                                <div className='cvrge-inner'>
                                                    <h5>7 Key Cities</h5>
                                                </div>
                                            </div>
                                            <div className='col-lg-12 col-6'>
                                                <div className='cvrge-inner'>
                                                    <h5>&gt;50 Pin Codes/City</h5>
                                                </div>
                                            </div>
                                            <div className='col-lg-12 col-6'>
                                                <div className='cvrge-inner'>
                                                    <h5>Weekly Data Collection</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>




                                </div>
                                <div className='national-coverge wow fadeInUp'>
                                    <div className='mblidiv'>
                                        <h4>Exclusive Access </h4>
                                    </div>

                                    <div className='covaregae-info'>
                                        <div className='row'>
                                            <div className='col-lg-12 col-12'>
                                                <div className='cvrge-inner'>
                                                    <h5>ECom & D2C Consumption Captured</h5>
                                                </div>
                                            </div>
                                            <div className='col-lg-12 col-12'>
                                                <div className='cvrge-inner'>
                                                    <h5>Premium Households</h5>
                                                </div>

                                                <div className="harbingtxt">
                                                    <div className='cvrge-inner'>
                                                        <h5>Harbingers of innovation  </h5>
                                                    </div>
                                                    <div className='cvrge-inner'>
                                                        <h5>~40% of Urban Consumption</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>




                                </div>
                            </div>
                        </div>

                    </div>
                </section>

                <section className='insight-offer'>
                    <div className='container'>
                        <h2>Our Exclusive Insight Offerings </h2>
                        <div className='offer-inner'>
                            <div className='row'>
                                <div className='col-lg-4 col-md-6 col-12 wow fadeInUp'>
                                    <div className='trax-box'>
                                        <div className='ofricon'><img src={of1} alt='demo'/></div>
                                        <div className='mediaobject'>
                                            <h5>Bintix <strong>TRAX</strong></h5>
                                            <p>Continuous Data Panel With In-depth Insights & Analytics</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-6 col-12 wow fadeInUp'>
                                    <div className='trax-box'>
                                        <div className='ofricon'><img src={of2} alt='demo'/></div>
                                        <div className='mediaobject'>
                                            <h5>Bintix <strong>PRO</strong></h5>
                                            <p>Exclusive Lifestyle Insights. Beyond CPG Categories</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-6 col-12 wow fadeInUp'>
                                    <div className='trax-box'>
                                        <div className='ofricon'><img src={of3} alt='demo'/></div>
                                        <div className='mediaobject'>
                                            <h5>Bintix  <strong>Innovate</strong></h5>
                                            <p>Bespoke Early Launch Insights, Sustainable Innovation Insights</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-6 col-12 wow fadeInUp'>
                                    <div className='trax-box'>
                                        <div className='ofricon'><img src={of4} alt='demo'/></div>
                                        <div className='mediaobject'>
                                            <h5>Bintix  <strong>Media</strong></h5>
                                            <p>Measurement of  Change in consumption in Response to media</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-6 col-12 wow fadeInUp'>
                                    <div className='trax-box'>
                                        <div className='ofricon'><img src={of5} alt='demo'/></div>
                                        <div className='mediaobject'>
                                            <h5>Bintix  <strong>TRIAL</strong></h5>
                                            <p>Access To Precision-Mapped Consumers For New Product Sampling</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-6 col-12 wow fadeInUp'>
                                    <div className='trax-box'>
                                        <div className='ofricon'><img src={of6} alt='demo'/></div>
                                        <div className='mediaobject'>
                                            <h5>Bintix <strong>CONNECT</strong></h5>
                                            <p>Access To Precision Mapped Consumers For  Connects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

                <section className='partner-sec lovedclient dkslgonon'>
                    <div className='container'>
                        <div className='lovclnt-heading wow fadeInUp'>
                            <h2>Loved By Our Clients</h2>
                        </div>
                        <div className='prtnviw wow fadeInUp'>
                            <div className='prtnrlgo'><img src={p1} alt='demo'/></div>
                            <div className='prtnrlgo'><img src={p2} alt='demo'/></div>
                        </div>
                        <div className='prtnviw wow fadeInUp'>
                            <div className='prtnrlgo'><img src={p3} alt='demo'/></div>
                            <div className='prtnrlgo'><img src={p4} alt='demo'/></div>
                            <div className='prtnrlgo'><img src={p5} alt='demo'/></div>
                        </div>
                        <div className='prtnviw wow fadeInUp'>
                            <div className='prtnrlgo'><img src={p6} alt='demo'/></div>
                            <div className='prtnrlgo'><img src={p7} alt='demo'/></div>
                            <div className='prtnrlgo'><img src={p8} alt='demo'/></div>
                            <div className='prtnrlgo'><img src={p9} alt='demo'/></div>
                        </div>


                    </div>
                </section>

                <section className='partner-sec lovedclient mobile-viwchng'>
                    <div className='container'>
                        <div className='lovclnt-heading wow fadeInUp'>
                            <h2>Loved By Our Clients</h2>
                        </div>
                        <div className='prtnviw wow fadeInUp'>
                            <div className='prtnrlgo'><img src={p1} alt='demo'/></div>
                            <div className='prtnrlgo'><img src={p2} alt='demo'/></div>
                        </div>
                        <div className='prtnviw wow fadeInUp'>
                            <div className='prtnrlgo'><img src={p3} alt='demo'/></div>
                            <div className='prtnrlgo'><img src={p4} alt='demo'/></div>
                            <div className='prtnrlgo'><img src={p5} alt='demo'/></div>
                        </div>
                        <div className='prtnviw wow fadeInUp'>
                            <div className='prtnrlgo'><img src={p7} alt='demo'/></div>
                            <div className='prtnrlgo'><img src={p8} alt='demo'/></div>
                        </div>
                        <div className='prtnviw wow fadeInUp d-flex justify-content-between'>
                            <div className='prtnrlgo'><img src={p6} alt='demo'/></div>

                            <div className='prtnrlgo'><img src={p9} alt='demo'/></div>
                        </div>


                    </div>
                </section>


                <section className='get-inspired'>
                    <div className='container'>
                        <h2>Get Inspired <img src={sxty} alt='demo'/> </h2>
                        <div className='get-inspired-inner'>
                            <div className='hersey-box '>
                                <div className='inspired-box'>
                                    <span><img src={get2} /></span>
                                    <p><img className='cmtsxty' src={sxty} alt='demo'/>I can definitely vouch for great quality data that we have been getting from bintix with speed and efficiency. the data is able to catch consumer trends pretty well & ties in with our internal sales data
                                        <img className='cmtninty' src={ninty} alt='demo'/> </p>


                                    <div className="fundrinfo fndownr">
                                        <p>Sr. Associate director, insights, Kellogg’s</p>
                                    </div>
                                </div>

                            </div>
                            <div className='kellogs'>


                                <div className='inspired-box'>
                                    <span><img src={get1} alt='demo'/></span>
                                    <p><img className='cmtsxty' src={sxty} alt='demo'/>
                                        We transitioned to this new service of bintix and their product/service was chosen for higher reliability and relatively better pickup (of smaller brands/niche categories/OOH categories)
                                        <img className='cmtninty' src={ninty} alt='demo'/>
                                    </p>
                                    <div className="fundrinfo fndownr">
                                        <p>DGM, Consumer Insights, Hershey’s</p>
                                    </div>
                                </div>

                            </div>

                            <div className='inspired-box lastmxwdth'>
                                <span><img src={get3} alt='demo'/></span>
                                <p><img className='cmtsxty' src={sxty} alt='demo'/> We are impressed by the quality and professionailism of bintix on providing us with high-precision consumption insights. it enabled us to test & analyse our new range of chemical-free sustainable products with bullseye audience.
                                    <img className='cmtninty' src={ninty} alt='demo'/> </p>
                                <div className="fundrinfo fndownr">
                                    <p>Founder, Tazo</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className='case-studies-sec case-data'>
                    <h2 className='wow fadeInUp'>Case Studies</h2>
                    <div className='study-case-inner'>
                        <div className='container'>

                            <div className='case-studies-detl'>
                                <div className='case-info wow fadeInUp'>
                                    <div className='dgleg'><img src={dg1} alt='demo'/></div>
                                    <div className='case-pic'><img src={vcse1} alt='demo'/></div>
                                    <div className='case-txt'>
                                        <p>Real time pick-up & analytics of consumption data, enabled faster ROI tracking for special promo packs of Kellogg’s
                                        </p>
                                        <strong>BintixTRAX</strong>
                                    </div>
                                </div>
                                <div className='case-info wow fadeInUp'>
                                    <div className='dgleg'><img src={dg2} alt='demo'/></div>
                                    <div className='case-pic'><img src={vcse2} alt='demo'/></div>
                                    <div className='case-txt'>
                                        <p>Exclusive pick-up of Make-Up brands’ consumption, as well as analysis of different personas of users, helped Loreal identify growth opportunities for Maybelline
                                        </p>
                                        <strong>BintixTRAX</strong>
                                    </div>
                                </div>
                                <div className='case-info wow fadeInUp'>
                                    <div className='dgleg'><img src={dg1} alt='demo'/></div>
                                    <div className='case-pic'><img src={vcse3} alt='demo'/></div>
                                    <div className='case-txt'>
                                        <p>Cross referencing atta consumption patterns with other non-cpg patterns in households, helped ITC to identify the right positioning for healthy products’</p>
                                        <strong>BintixPRO</strong>
                                    </div>
                                </div>
                                <div className='case-info wow fadeInUp'>
                                    <div className='dgleg'><img src={dg2} alt='demo'/></div>
                                    <div className='case-pic'><img src={vcse4} alt='demo'/></div>
                                    <div className='case-txt'>
                                        <p>Identifying precision-targetted audience based on consumption, enabled Taazo to sample new range of chemical
                                            free products, alongwith a post-launch analysis of early adopters</p>
                                        <strong>BintixTRIAL</strong>
                                    </div>
                                </div>
                                <div className='case-info wow fadeInUp'>
                                    <div className='dgleg'><img src={dg1} alt='demo'/></div>
                                    <div className='case-pic'><img src={vcse5} alt='demo'/></div>
                                    <div className='case-txt'>
                                        <p>Early launch analysis through real time consumption data, is helping
                                            Loreal to faster read launch performance of specialised innovations
                                        </p>
                                        <strong>BintixINNOVATE</strong>
                                    </div>
                                </div>
                                <div className='case-info wow fadeInUp'>
                                    <div className='dgleg'><img src={dg1} alt='demo'/></div>
                                    <div className='case-pic'><img src={vcse6} alt='demo'/></div>
                                    <div className='case-txt'>
                                        <p>360 assessment of packsize consumption, unlocked precise pack portfolio planning for Hersheys on their premium chocolates
                                        </p>
                                        <strong>BintixTRAX</strong>
                                    </div>
                                </div>
                                <div className='case-info wow fadeInUp'>
                                    <div className='case-pic'><img src={vcse7} alt='demo'/></div>
                                    <div className='case-txt'>
                                        <p>Identifying precision-targetted households for consumer connect (CHVs) helped Kellogs understand the quick actions it needed to take in response to higher inflation
                                        </p>
                                        <strong>BintixConnect</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



                <section className='grahax-sec wow fadeInUp'>
                    <div className='container'>
                        <h2>Graahax : Our advanced analytics Platform</h2>
                        <p>Garbage in, insights out</p>
                        <img src={grahax} alt='demo'/>
                        <img className='ptrnbg' src={ptrn} alt='demo'/>
                    </div>

                </section>


                <section className='ctgry-knowldge wow fadeInUp'>
                    <div className='container'>
                        <h2>Deep Category knowledge </h2>

                        <div className='ctgry-inner dkctgry'>
                            <img src={ctgrypic} alt='demo'/>
                        </div>
                        <div className='ctgry-mobile'>
                            <OwlCarousel className="owl-theme" {...options5}  >

                                <div className="item">
                                    <div className='ctgry-inner'>
                                        <img src={ctgrypic1} alt='demo'/>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className='ctgry-inner'>
                                        <img src={ctgrypic2} alt='demo'/>
                                    </div>
                                </div>
                            </OwlCarousel>
                        </div>
                    </div>
                </section>

                <section className='anlytic-dshbrd wow fadeInUp'>
                    <div className='container'>
                        <div className='access-frm'>
                            <button className='getbutn' onClick={(e) => { e.preventDefault(); dispatch(setLoading(true)); navigate("/partner2") }}>Get In Touch</button>
                        </div>
                    </div>
                </section>
            </main >
        </>

    )
}

export default OurData