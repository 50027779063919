import React, { useEffect, useState } from 'react'
import './Partner.css';
import { AiOutlineLeft } from "react-icons/ai";
import "swiper/css";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { BASE_URL } from '../../config';
import { setHeaderType, setLoading } from '../../Redux/ActionCreater';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';



function PartnerCode() {
    const [code, setCode] = useState("");
    let dispatch=useDispatch();
    let navigate=useNavigate();

let onChangeHandle=(e)=>{
    setCode(e.target.value)
}

let handleSubmit=async(e)=>{
    e.preventDefault()
        try {
            if(code===""){
                return toast.error("Please enter code!")
            }
            
            let data = await axios.post(`${BASE_URL}/login/partner`,{
                "code": code
            }).then((res)=>{
                if(res.data.status===200){
                    toast.success(res.data.msg);
                    setCode("")
                    navigate("/our-data")
                }else{
                    toast.error(res.data.errors)
                }
            })
        } catch (error) {
            console.log(error)
        }

}

useEffect(()=>{
    dispatch(setHeaderType(2))
    // window.scrollTo(0, 0);
  
  setTimeout(() => {
    dispatch(setLoading(false))
    
  }, 1000);
},[])



    return (
        <>
        <Helmet>
        <title>Bintix::Partner Code</title>
        <meta property="og:title" content="Bintix::Partner Code" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
            <section className='details-sec'>
                <div className='container'>
                    <div className='partner-box'>
                        <div className='partnr-from'>
                            <h5>Partner code</h5>
                            <form>
                                <div className='codeinput'>
                                    <input value={code} onChange={onChangeHandle} type='text' placeholder='Enter Your Partner Code' />
                                    <button onClick={handleSubmit}>Submit</button>
                                </div>
                            </form>
                            <p>Don’t have a code?  <a href='/partner2'  onClick={(e)=>{e.preventDefault();navigate("/partner2")}}> Request One.</a></p>
                        </div>

                        <div className='text-center'>
                        <a href='/'  onClick={(e)=>{e.preventDefault();navigate(-1)}} className='bcklnk'><AiOutlineLeft/><AiOutlineLeft/>  Go back</a>
                        </div>

                        
                    </div>
                </div>
            </section>





<ToastContainer/>
        </>

    )
}

export default PartnerCode