import React, { useEffect } from 'react'
import brd from "../../images/brd.png"
import thnku from "../../images/thnku.png"
import "swiper/css";
import './ThankYou.css';
import { useDispatch } from 'react-redux';
import { setHeaderType, setLoading } from '../../Redux/ActionCreater';
import { useNavigate } from 'react-router-dom';


function ThankYou() {
    let dispatch=useDispatch()
    let navigate=useNavigate()
    useEffect(()=>{
        dispatch(setHeaderType(2))
        // window.scrollTo(0, 0);
  
  setTimeout(() => {
    dispatch(setLoading(false))
    
  }, 1000);
    },[])

    return (
        <>
            <section className='movement-sec'>
                <div className='container'>
                    <div className='movement-box thnkmrgn'>
                        <span className='brdpic'><img src={brd}/></span>
                        

                        <div className='thnkyou-sec'>
                            <h3>Thank you!</h3>
                            <figure><img src={thnku}/></figure>
                            <p>Your details are recorded & Our team will Get back you very Fast!</p>
                            <a href='/' onClick={e=>{e.preventDefault();navigate("/")}}>Back To Home Page</a>

                        </div>


                    </div>




                </div>
            </section>
        </>

    )
}

export default ThankYou