import React, { useEffect } from 'react'
import $ from "jquery";
import { useNavigate } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import home from "../../images/data-banner.jpg";
import analytic from "../../images/analytic.png"
import studying from "../../images/studying.png"
import barcode from "../../images/barcode.png"
import mobilebnr from "../../images/mbdata-bnr.jpg"
import brcod from "../../images/brcod.png"
import cntxtpic from "../../images/cntxt1.jpg"
import cntxtpic2 from "../../images/cntxt2.jpg"
import cntxtpic3 from "../../images/cntxt3.jpg"
import cntxtpic4 from "../../images/cntxt4.jpg"
import hrns1 from "../../images/hrns1.jpg"
import hrns2 from "../../images/hrns2.jpg"
import hrns3 from "../../images/hrns3.jpg"
import hrns4 from "../../images/hrns4.jpg"
import cl1 from "../../images/cl1.png"
import cl2 from "../../images/cl2.png"
import cl3 from "../../images/cl3.png"
import cl4 from "../../images/cl4.png"
import cl5 from "../../images/cl5.png"
import cicon1 from "../../images/cicon1.png"
import cicon2 from "../../images/cicon2.png"
import cicon3 from "../../images/cicon3.png"
import case3 from "../../images/case3.png"
import case4 from "../../images/case4.png"
import case5 from "../../images/case5.png"
import case6 from "../../images/case6.png"
import newcse from "../../images/newcse.png"
import stdypic from "../../images/stdyph.png"
import stdypic2 from "../../images/stdyph2.png"
import tobg from "../../images/bcode.png"
import mobcntxt from "../../images/mobcntxt.jpg"
import mobcntxt2 from "../../images/mobcntxt2.jpg"
import mobcntxt3 from "../../images/mobcntxt3.jpg"
import mcky from "../../images/mcky.png"
import geogl from "../../images/geogl.png"
import './Context.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import WOW from 'wowjs';
import { setHeaderType, setLoading } from '../../Redux/ActionCreater';
import { useDispatch } from 'react-redux';
import p1 from "../../images/p1.png"
import p2 from "../../images/p2.png"
import p3 from "../../images/p3.png"
import p4 from "../../images/p4.png"
import p5 from "../../images/p5.png"
import p6 from "../../images/p6.png"
import p7 from "../../images/p7.png"
import p8 from "../../images/p8.png"
import p9 from "../../images/p9.png"
import p10 from "../../images/p10.png"

function Context() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const options = {
        loop: true,
        margin: 0,
        
        items: 1,
        autoplay: false,
        autoplaySpeed: 1200,
        rewind: false,
        lazyLoad: true,
        mouseDrag: false,
        touchDrag: false,
        dots: true,
        nav: false
    };
    const options6 = {
        loop: true,
        margin: 20,
       
        items: 2,
        autoplay: true,
        autoplayTimeout:2000,
        autoplaySpeed: 500,
        smartSpeed:1000,
        rewind: false,
        lazyLoad: true,
        mouseDrag: true,
        touchDrag: true,
        dots: false,
        nav: false,
        stagePadding: 6,
        responsive: {
            0: {
                items: 2,
            },
            450: {
                items: 2,
            },
            768: {
                items: 4,
            },
            992: {
                items: 5,
            },
            1200: {
                items: 6,
                margin: 30,
            },

        }
    };

    useEffect(() => {
        dispatch(setHeaderType(1))
        new WOW.WOW({
            live: false
        }).init();
        let id =window.location.href.split("#")[1];
    setTimeout(() => {
      if(id){

  $('html, body').animate(
    {
      scrollTop: $("#"+id).offset().top-200
    },
  )
      }else{
        // window.scrollTo(0, 0);
      }
      setTimeout(() => {
        dispatch(setLoading(false))
        
      }, 2000);
    }, 1000);
    }, [])

    return (
        <>
             <Helmet>
        <title>Bintix::Context</title>
        <meta property="og:title" content="Bintix::Context" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

            <main className='abut-page-bg cntxt-bg'>
                <section className="bannercontainer abutbnr clntbn">
                    <div id="NoiseBanner" className>

                        <OwlCarousel className="owl-theme" {...options}  >

                            <div className="item">
                                <img className='dkstbnr' src={home} style={{ height: "auto", width: "100%" }} alt='demo'/>
                                <img className='mbnr' src={mobilebnr} style={{ height: "auto", width: "100%" }} alt='demo'/>
                                <div className="banner-cap">
                                    <div className="container">
                                        <div className="banrcontent">
                                            <h1 className='dkbntxt'>powering better life choices<br /> for our clients</h1>
                                            <h1 className='mbbntxt'>powering better life choices for our clients</h1>
                                            <h4>through unique consumption<br /> insights from waste</h4>
                                            <a href="/partner2" onClick={e=>{e.preventDefault();dispatch(setLoading(true));navigate("/partner2")}}>Get In Touch</a>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </OwlCarousel>
                    </div>
                </section>

                <section className='logo-slide dkstdatalgo'>
                <OwlCarousel className="owl-theme" {...options6}  >

                    <div className="item">
                        <div className='lgoslide-inner'>
                            <img src={p1} alt='demo'/>
                        </div>
                    </div>
                    <div className="item">
                        <div className='lgoslide-inner'>
                            <img src={p2} alt='demo'/>
                        </div>
                    </div>
                    <div className="item">
                        <div className='lgoslide-inner'>
                            <img src={p3} alt='demo'/>
                        </div>
                    </div>
                    <div className="item">
                        <div className='lgoslide-inner'>
                            <img src={p4} alt='demo'/>
                        </div>
                    </div>
                    <div className="item">
                        <div className='lgoslide-inner'>
                            <img src={p5} alt='demo'/>
                        </div>
                    </div>
                    <div className="item">
                        <div className='lgoslide-inner'>
                            <img src={p6} alt='demo'/>
                        </div>
                    </div>
                    <div className="item">
                        <div className='lgoslide-inner'>
                            <img src={p7} alt='demo'/>
                        </div>
                    </div>
                    <div className="item">
                        <div className='lgoslide-inner'>
                            <img src={p8} alt='demo'/>
                        </div>
                    </div>
                    <div className="item">
                        <div className='lgoslide-inner'>
                            <img src={p9} alt='demo'/>
                        </div>
                    </div>
                    <div className="item">
                        <div className='lgoslide-inner'>
                            <img src={p10} alt='demo'/>
                        </div>
                    </div>
                    
                </OwlCarousel>
                </section>

                <div className='oudtatmobilelogo'>
                    <div className='logo-slide'>
                    <OwlCarousel className="owl-theme" {...options6}  >

                        <div className="item">
                            <div className='lgoslide-inner'>
                                <img src={p9} alt='demo'/>
                            </div>
                        </div>
                        <div className="item">
                            <div className='lgoslide-inner'>
                                <img src={p7} alt='demo'/>
                            </div>
                        </div>
                        <div className="item">
                            <div className='lgoslide-inner'>
                                <img src={p3} alt='demo'/>
                            </div>
                        </div>
                      
                        
                    </OwlCarousel>
                    </div>
                    <div className='logo-slide'>
                        <OwlCarousel className="owl-theme" {...options6}  >

                            <div className="item">
                                <div className='lgoslide-inner'>
                                    <img src={p1} alt='demo'/>
                                </div>
                            </div>
                            <div className="item">
                                <div className='lgoslide-inner'>
                                    <img src={p5} alt='demo'/>
                                </div>
                            </div>
                            <div className="item">
                                <div className='lgoslide-inner'>
                                    <img src={p2} alt='demo'/>
                                </div>
                            </div>
                           
                            
                        </OwlCarousel>
                    </div>
                    <div className='logo-slide'>
                        <OwlCarousel className="owl-theme" {...options6}  >


                           
                            <div className="item">
                                <div className='lgoslide-inner'>
                                    <img src={p4} alt='demo'/>
                                </div>
                            </div>
                            <div className="item">
                                <div className='lgoslide-inner'>
                                    <img src={p8} alt='demo'/>
                                </div>
                            </div>
                            <div className="item">
                                <div className='lgoslide-inner'>
                                    <img src={p6} alt='demo'/>
                                </div>
                            </div>
                            <div className="item">
                                <div className='lgoslide-inner'>
                                    <img src={p10} alt='demo'/>
                                </div>
                            </div>
                            
                        </OwlCarousel>
                    </div>
                </div>

                <section className="context-sec">
                    <div class="container">
                        <div className='context-bg'>
                            {/* <h3>Context</h3> */}
                            <div className='cntxtwopic'>
                                <img src={cntxtpic} alt='demo'/>
                                <img src={cntxtpic2} alt='demo'/>
                                <img src={cntxtpic3} alt='demo'/>
                                <img src={cntxtpic4} alt='demo'/>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='reflectin-sec'>
                    <div className='container'>
                        <div className='reflectin-content'>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <div className='refecthed'>
                                        <h3>Waste has value. </h3>
                                        <h4>Because it is A Mirror Reflection of Society's Consumption Behaviour!</h4>
                                    </div></div>
                                <div className='col-lg-6'>
                                    <div className='refectpra'>
                                        <p>We believe that this waste is valuable because it can tell us a lot about how and why people consume products. </p>
                                        <p>By finding this information we help clients make better decisions about their lives sustainable products & public policy </p>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='mobile-context-sec'>
                <h3>Context</h3>
                    <div className='container'>
                        <div className='contextmob'>
                            <figure><img src={mobcntxt} alt='demo'/></figure>
                            <div className='mobilcntxt'>
                                <h5>Waste has value.</h5>
                                <p>Because it is A Mirror Reflection of Society's Consumption Behaviour!</p>
                            </div>
                        </div>
                        <div className='contextmob'>
                            <figure><img src={mobcntxt2} alt='demo'/></figure>
                            <div className='mobilcntxt'>
                                <p>We believe that plastic waste is valuable because it can tell us a lot about how people consume products.</p>
                            </div>
                        </div>
                        <div className='contextmob'>
                            <figure><img src={mobcntxt3} alt='demo'/></figure>
                            <div className='mobilcntxt'>
                                <p>By finding this information we help clients make better decisions about their lives sustainable products & public policy</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='harnessing'>
                    <div className='container'>
                        <div className='harnessing-inner'>
                            <div className='row'>
                                <div className='col-lg-7'>
                                    <div className='harness-txt'>
                                        <p>Harnessing this consumption behaviour information, can be a game-changer in understanding the journey of how products being created, consumed and disposed-off.</p>

                                        <p>We harness this consumption behaviour from waste, by applying advanced technology to waste.</p>

                                        <p>By doing so, we are helping our clients and our partners make better decisions about their lives, about the environment and about public policy on waste.
                                        </p>
                                    </div>
                                </div>
                                <div className='col-lg-5'>
                                    <div className='harnespic'>
                                        <div className='dublpic'>
                                            <div className='dpic'>
                                                <img src={hrns1} alt='demo'/>
                                            </div>
                                            <div className='dpic'>
                                                <img src={hrns2} alt='demo'/>
                                            </div>
                                        </div>
                                        <div className='dublpic'>
                                            <div className='dpic'>
                                                <img src={hrns3} alt='demo'/>
                                            </div>
                                            <div className='dpic'>
                                                <img src={hrns4} alt='demo'/>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='wstegvlu-sec'>

                    <div className='container'>
                        <div className='wastevalue-inner'>

                            <div className='mirrortxt'>
                                <div className='mrirleft'>
                                    <h3>Waste has value.</h3>
                                    <h4>Because it is A Mirror Reflection of Society's Consumption Behaviour!</h4>
                                </div>
                                <div className='mrirright'>

                                    <p>We believe that this waste is valuable because it can tell us a lot about how and why people consume products.</p>

                                    <p>By finding this information we help clients make better decisions about their lives sustainable products & public policy</p>

                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <section className="waste-challenge howdo-sec ">
                    <div className="container">

                        <div className="wstedfrntly">
                            <h4>How Do we Do It?</h4>
                        </div>
                        <div className="studying-sec">
                            <div className="squarepic2"><img src={brcod} alt='demo'/></div>
                            <div className="row align-items-center">
                                <div className="col-lg-5">
                                    <div className="study-content">
                                        <h3>By Studying Every Bit Of Waste Disposed..</h3>
                                        <img src={barcode} alt='demo'/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="studypic"><img src={studying} alt='demo'/></div>
                                </div>
                            </div>
                            <div className="row align-items-center">
                                <div className="col-lg-7">
                                    <div className="studypic"><img src={analytic} alt='demo'/>
                                        <div className="squarepic2"><img src={brcod} alt='demo'/></div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="study-content">
                                        <h3>and By Applying Advanced Analytics...</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="unlocking">
                            <h3>... We’re Unlocking Never-Possible-Before Consumption Insights for our Clients</h3>
                        </div>
                    </div>
                </section>
                <section className='client-sec'>
                    <div className='container'>
                        <div className='listItem'>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <div className='chompic'><img src={cl1} alt='demo'/>
                                        <div className='bcode1'><img src={tobg} alt='demo'/></div>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='clntinr-hdr' id="homes">
                                        <h3>Homes</h3>
                                    </div>
                                    <div className='clinettxt'>
                                        <span><img src={cicon1} alt='demo'/></span>
                                        <p>Free, personalized dashboards with <br/>consumption insights to improve waste impact  <br/>& lifestyle</p>
                                    </div>
                                    <div className='clinettxt'>
                                        <span><img src={cicon2} alt='demo'/></span>
                                        <p>Incentivized waste collection</p>
                                    </div>
                                    <div className='clinettxt'>
                                        <span><img src={cicon3} alt='demo'/></span>
                                        <p>Assurance of an Award winning waste <br/>management service (Collection & Disposal) </p>
                                    </div>
                                    <div className='clinettxt'>
                                        <a href="/movement" onClick={(e) => { e.preventDefault();dispatch(setLoading(true)); navigate("/movement") }}>Express Interest</a>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <div className='chompic'><img src={cl2} alt='demo'/>
                                        <div className='bcode2'><img src={tobg} alt='demo'/></div>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='clntinr-hdr' id='resident' >
                                        <h3>Resident Associations</h3>
                                    </div>
                                    <div className='clinettxt'>
                                        <span><img src={cicon1} alt='demo'/></span>
                                        <p>Free, community dashboards with disposal insights  </p>
                                    </div>
                                    <div className='clinettxt'>
                                        <span><img src={cicon2} alt='demo'/></span>
                                        <p>Assurance of an award winning waste <br/>management service (Collection & Disposal)</p>
                                    </div>
                                    <div className='clinettxt'>
                                        <span><img src={cicon3} alt='demo'/></span>
                                        <p>Partnership for best in class waste management for <br/>society premises</p>
                                    </div>


                                    <div className='clinettxt'>
                                        <a href="/movement" onClick={(e) => { e.preventDefault();dispatch(setLoading(true)); navigate("/movement") }}>Express Interest</a>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-6 '>
                                    <div className='chompic'><img src={cl3} alt='demo'/>
                                        <div className='bcode3'><img src={tobg} alt='demo'/></div>
                                    </div>
                                </div>
                                <div className='col-lg-6 '>
                                    <div className='clntinr-hdr'>
                                        <h3>Public Sector</h3>
                                    </div>
                                    <div className='clinettxt'>
                                        <span><img src={cicon1} alt='demo'/></span>
                                        <p>Localized insights by city on waste disposal behaviour</p>
                                    </div>
                                    <div className='clinettxt'>
                                        <span><img src={cicon2} alt='demo'/></span>
                                        <p>Partner to run programs on waste <br/>awareness</p>
                                    </div>
                                    <div className='clinettxt'>
                                        <span><img src={cicon3} alt='demo'/></span>
                                        <p>Partner for audits and waste related statistics <br/>for public policy making</p>
                                    </div>

                                    <div className='clinettxt'>
                                        <a href="/about-us#contact" onClick={(e) => { e.preventDefault();dispatch(setLoading(true)); navigate("/about-us#contact") }}>Contact Us</a>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-6 '>
                                    <div className='chompic'><img src={cl4} alt='demo'/>
                                        <div className='bcode4'><img src={tobg} alt='demo'/></div>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='clntinr-hdr'>
                                        <h3>Coopetitors</h3>
                                    </div>
                                    <div className='clinettxt'>
                                        <span><img src={cicon1} alt='demo'/></span>
                                        <p>Off the shelf insights for efficient waste <br/>management</p>
                                    </div>
                                    <div className='clinettxt'>
                                        <span><img src={cicon2} alt='demo'/></span>
                                        <p>Better earnings by tracking waste better</p>
                                    </div>
                                    <div className='clinettxt'>
                                        <span><img src={cicon3} alt='demo'/></span>
                                        <p>Waste insights to better service their <br/>communities </p>
                                    </div>

                                    <div className='clinettxt'>
                                        <a href="/about-us#contact" onClick={(e) => { e.preventDefault();dispatch(setLoading(true)); navigate("/about-us#contact") }}>Contact Us</a>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <div className='chompic'><img src={cl5} alt='demo'/>
                                        <div className='bcode5'><img src={tobg} alt='demo'/></div>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='clntinr-hdr'>
                                        <h3>Consumer goods companies</h3>
                                    </div>
                                    <div className='clinettxt'>
                                        <span><img src={cicon1} alt='demo'/></span>
                                        <p>Never before real-time insights on <br/>product consumption and new product updates</p>
                                    </div>
                                    <div className='clinettxt'>
                                        <span><img src={cicon2} alt='demo'/></span>
                                        <p>Data to help make better decisions on <br/>products & packaging</p>
                                    </div>
                                    <div className='clinettxt'>
                                        <span><img src={cicon3} alt='demo'/></span>
                                        <p>Run digital surveys / get feedbacks at <br/>National level </p>
                                    </div>

                                    <div className='clinettxt'>
                                        <a href="/partner2" onClick={(e) => { e.preventDefault();dispatch(setLoading(true)); navigate("/partner2") }}>Partner Us</a>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                </section>

                <section className='case-studies-sec'>

                    <h2 className='wow fadeInUp'>Case Studies</h2>
                    <div className='study-case-inner'>
                        <div className='container'>
                            <div className='case-studies-detl'>
                                <div className='case-info mobilecase wow fadeInUp'>
                                    <div className='case-pic'><img src={stdypic} alt='demo'/></div>
                                    <div className='case-txt'>
                                        <p>Enabling 25,000 houses in India to <br/>track their personalized insights on <br/>waste, consumption, carbon <br/>emissions and trees saved.</p>
                                    </div>
                                </div>
                                <div className='case-info mobilecase wow fadeInUp'>
                                    <div className='case-pic'><img src={stdypic2} alt='demo'/></div>
                                    <div className='case-txt'>
                                        <p>Helping Residential Associations <br/>across India to measure & track <br/>the waste and environmental <br/>impact of their building</p>
                                    </div>
                                </div>
                                <div className='case-info wow fadeInUp'>
                                    <div className='case-pic'><img src={case3} alt='demo'/></div>
                                    <div className='case-txt'>
                                        <p>Helping Governments make better <br/>public policy on waste through <br/>bespoke published papers on <br/>society’s consumption trends</p>
                                    </div>
                                </div>
                                <div className='case-info wow fadeInUp'>
                                    <div className='case-pic'><img src={case4} alt='demo'/></div>
                                    <div className='case-txt'>
                                        <p>Partnering McKinsey & Company to help Indonesian & Argentinian waste management companies (Delterra, Indonesia) with bespoke software for end-to-end waste tracking</p>
                                    </div>
                                    <div className='geoglepic2'><img src={mcky}alt='demo'/></div>
                                </div>
                                <div className='case-info wow fadeInUp'>
                                    <div className='case-pic'><img src={case5} alt='demo'/></div>
                                    <div className='case-txt'>
                                        <p>Partnering Google for advance <br/>machine learning solutions for <br/>optically identifying large volumes <br/>of waste packs, efficiently  </p>
                                    </div>
                                    <div className='geoglepic'><img src={geogl} alt='demo'/></div>
                                </div>
                                <div className={window.innerWidth > 991 ? 'case-info newcasedsgn wow fadeInUp' : 'case-info wow fadeInUp'}>
                                    <div className='case-pic'><img src={case6} alt='demo'/></div>
                                    <div className='case-txt'>
                                        <p>Using insights to help purpose-led brands to create & sample products that are better for people & better for planet</p>
                                    </div>
                                    <div className='case-picextra'><img src={newcse} alt='demo'/></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='container'>
                        <div class="text-center"><a href="/about-us#contact" onClick={(e) => { e.preventDefault();dispatch(setLoading(true)); navigate("/about-us#contact") }} class="prtnrbtn">Contact Us</a></div>
                    </div>
                </section>
            </main >

        </>

    )
}

export default Context