import React, { useEffect, useState } from 'react'
import sxty from "../../images/sxty.png"
import ninty from "../../images/ninty.png"
import get1 from "../../images/get1.png"
import get2 from "../../images/get2.png"
import get3 from "../../images/tbccc.png"
import { FaAngleDown,  } from "react-icons/fa";
import brd from "../../images/brd.png"
import "swiper/css";
import $ from "jquery";
import axios from 'axios';
import { BASE_URL } from '../../config';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setHeaderType, setLoading } from '../../Redux/ActionCreater';
import { Helmet } from 'react-helmet-async';


function Partner2() {
    let dispatch=useDispatch()
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        mobile: "",
        city: "",
        company: "",
        job: "",
        how: ""
    })


    let onChangeHandle = (e) => {
        if (e.target.name === "mobile") {
            var reg = /^\d+$/;
            if ((reg.test(e.target.value) || e.target.value === "")) {
                setFormData({ ...formData, [e.target.name]: e.target.value });
            }
        }else if(e.target.name === "name"||e.target.name === "job"||e.target.name === "city"||e.target.name === "company"){
            var reg = /^[a-zA-Z ]+$/;
            if ((reg.test(e.target.value) || e.target.value === "") ){
                setFormData({ ...formData, [e.target.name]: e.target.value });
            }
        }
         else {
            setFormData({ ...formData, [e.target.name]: e.target.value })
        }
    }

    let handleSubmit = async (e) => {
        e.preventDefault()
        try {
            if (formData.name === "") {
                return toast.error("Please enter name!")
            }
            if (!/^[a-zA-Z ]+$/.test(formData.name)) {
                return toast.error("Please enter only alphabets in name!")
            }
            if (formData.email === "") {
                return toast.error("Please enter email!")
            }
            if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(formData.email)) {
                return toast.error("Please enter a valid email!")
            }
            if (formData.mobile === "") {
                return toast.error("Please enter mobile number!")
            }
            if (formData.mobile.length !== 10) {
                return toast.error("Please enter a valid mobile number!")
            }
            if (formData.city === "") {
                return toast.error("Please enter city!")
            }
            if (!/^[a-zA-Z ]+$/.test(formData.city)) {
                return toast.error("Please enter only alphabets in city!")
            }
            if (formData.company === "") {
                return toast.error("Please enter company name!")
            }
            if (!/^[a-zA-Z ]+$/.test(formData.company)) {
                return toast.error("Please enter only alphabets in company name!")
            }
            if (formData.job === "") {
                return toast.error("Please enter job title!")
            }
            if (!/^[a-zA-Z ]+$/.test(formData.job)) {
                return toast.error("Please enter only alphabets in job title!")
            }
            if (formData.how === "") {
                return toast.error("Please select  How did you hear about us!")
            }

            let data = await axios.post(`${BASE_URL}/partner-with-us`, {
                "full_name": formData.name,
                "email": formData.email,
                "mobile": formData.mobile,
                "job_title": formData.job,
                "city": formData.city,
                "company_name": formData.company,
                "how_did_you_hear_about_us": formData.how,
                "partner_type": "brand"
            }).then((res) => {
                if (res.data.status === 200) {
                    toast.success(res.data.msg);
                    setFormData({
                        name: "",
                        email: "",
                        mobile: "",
                        city: "",
                        company: "",
                        job: "",
                        how: ""
                    })
                } else {
                    toast.error(res.data.msg)
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(()=>{
        dispatch(setHeaderType(2))
        window.scrollTo(0, 0);
  
  setTimeout(() => {
    dispatch(setLoading(false))
    
  }, 1000);
    },[])


    return (
        <>
        <Helmet>
        <title>Bintix::Partner2</title>
        <meta property="og:title" content="Bintix::Partner2" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>


            <section className='movement-sec'>
                <div className='container'>
                    <div className='movement-box'>
                        <span className='brdpic'><img src={brd} /></span>
                        <div className='movenent-inner'>
                            <h3>Partner Us On Your Brands </h3>
                            {/* <h5>Do you segregate your waste?</h5>
                            <p>We pick up all types of dry, non-hazardous household waste,
                                and not just newspaper or carton waste.</p> */}

                            {/* <div className='frmfield'>
                                <div class="customRadio ">
                                    <input type="radio" name="power" value="3" id="option-1" checked=""/>
                                        <label for="option-1" class="option option-1">Yes</label>
                                </div>
                                    <div class="customRadio ">
                                        <input type="radio" name="power" value="3" id="option-2" checked=""/>
                                            <label for="option-2" class="option option-2">No</label>
                                    </div>
                            </div> */}


                            <div className='detsp'>
                                <p>Please Enter your details & we will get back to you!</p>
                            </div>

                            <div className='frmfield'>
                                <input name='name' value={formData.name} onChange={onChangeHandle} className='inputfldtxt' type='text' placeholder='Full Name' />
                            </div>
                            <div className='frmfield'>
                                <input name='email' value={formData.email} onChange={onChangeHandle} className='inputfldtxt' type='text' placeholder='Business Email' />
                            </div>
                            <div className='frmfield'>
                                <input name='mobile' value={formData.mobile} onChange={onChangeHandle} className='inputfldtxt' type='text' placeholder='Mobile' />
                            </div>
                            <div className='frmfield'>
                                <input name='city' value={formData.city} onChange={onChangeHandle} className='inputfldtxt' type='text' placeholder='City' />
                            </div>
                            <div className='frmfield'>
                                <input name='company' value={formData.company} onChange={onChangeHandle} className='inputfldtxt' type='text' placeholder='Company Name' />
                            </div>
                            <div className='frmfield'>
                                <input name='job' value={formData.job} onChange={onChangeHandle} className='inputfldtxt' type='text' placeholder='Job Title' />
                            </div>


                            <div className='frmfield'>
                                <span className='drpicn'><FaAngleDown /></span>
                                <select name='how' value={formData.how} onChange={onChangeHandle} className='inputfldtxt'>
                                    <option value="">How did you hear about us?</option>
                                    <option value={"Existing Bintix users"}>Existing Bintix users</option>
                                    <option value={"My housing society"}>My housing society</option>
                                    <option value={"Friends/family"}>Friends/family</option>
                                    {/* <option value={"Awenest"}>Awenest</option> */}
                                    <option value={"Facebook"}>Facebook</option>
                                    <option value={"Instagram"}>Instagram</option>
                                    <option value={"Others"}>Others</option>

                                </select>
                            </div>

                            <div className='frmfield btntpmrgn'>
                            <button onClick={handleSubmit}>Submit</button>
                            </div>
                        </div>


                    </div>




                </div>
            </section>

            <section className='get-inspired'>
                    <div className='container'>
                        <h2>Get Inspired <img src={sxty} /> </h2>
                        <div className='get-inspired-inner'>
                            <div className='hersey-box '>
                            <div className='inspired-box'>
                                    <span><img src={get2} /></span>
                                    <p><img className='cmtsxty' src={sxty} />I can definitely vouch for great quality data that we have been getting from bintix with speed and efficiency. the data is able to catch consumer trends pretty well & ties in with our internal sales data
                                        <img className='cmtninty' src={ninty} /> </p>
                                        

                                        <div className="fundrinfo fndownr">
                                            <p>Sr. Associate director, insights, Kellogg’s</p>
                                        </div>
                                </div>

                            </div>
                            <div className='kellogs'>
                                

                                <div className='inspired-box'>
                                    <span><img src={get1} /></span>
                                    <p><img className='cmtsxty' src={sxty} />
                                    We transitioned to this new service of bintix and their product/service was chosen for higher reliability and relatively better pickup (of smaller brands/niche categories/OOH categories)
                                        <img className='cmtninty' src={ninty} />
                                    </p>
                                    <div className="fundrinfo fndownr">
                                            <p>DGM, Consumer Insights, Hershey’s</p>
                                        </div>
                                </div>
                                
                            </div>

                            <div className='inspired-box lastmxwdth'>
                                    <span><img src={get3} /></span>
                                    <p><img className='cmtsxty' src={sxty} /> We are impressed by the quality and professionailism of bintix on providing us with high-precision consumption insights. it enabled us to test & analyse our new range of chemical-free sustainable products with bullseye audience.
                                        <img className='cmtninty' src={ninty} /> </p>
                                        <div className="fundrinfo fndownr">
                                            <p>Founder, Tazo</p>
                                        </div>
                                </div>
                        </div>
                    </div>
                </section>



<ToastContainer/>
        </>

    )
}

export default Partner2