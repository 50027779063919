import React from 'react'
import imp1 from "../../images/imp1.png"
import imp2 from "../../images/imp2.png"
import imp3 from "../../images/imp3.png"
import imp4 from "../../images/imp4.png"
import imp5 from "../../images/imp5.png"
import mimp1 from "../../images/mimp1.png"
import mimp2 from "../../images/mimp2.png"
import mimp3 from "../../images/mimp3.png"
import mimp4 from "../../images/mimp4.png"
import mimp5 from "../../images/mimp5.png"
import CountUp from 'react-countup';

function ImpactSection({impactData}) {

function convertToInternationalCurrencySystem (labelValue) {

    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e+9

    ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(1) + "B"
    // Six Zeroes for Millions 
    : Math.abs(Number(labelValue)) >= 1.0e+6

    ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(1) + "Million"
    // Three Zeroes for Thousands
    // : Math.abs(Number(labelValue)) >= 1.0e+3

    // ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(1) + "K"

    : Math.abs(Number(labelValue));

}
function convertToInternationalCurrencySystemStart (labelValue) {

  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e+9

  ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(1) + "B"
  // Six Zeroes for Millions 
  : Math.abs(Number(labelValue)) >= 1.0e+6

  ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(1)-0.3 + "Million"
  // Three Zeroes for Thousands
  // : Math.abs(Number(labelValue)) >= 1.0e+3

  // ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(1) + "K"

  : Math.abs(Number(labelValue))-3;

}
  return (
    <section className="impact-sec" data-wow-delay="1s">
    <div className="container">
      <div className="row">
        <div className="col-lg-4 col-md-6 col-sm-6 col-6">
          <div className="impact-info">
            <h4>Our <br /> Impact<br />So Far</h4>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-6 col-6">
          <div className="impact-info">
            <span className='dkicn'><img src={imp1} alt='demo' width={"50px"} height={"50px"} /></span>
            <span className='mbicn'><img src={mimp1} alt='demo' width={"50px"} height={"50px"} /></span>
            {/* {impactData&&<h4><CountUp end={convertToInternationalCurrencySystem(impactData.pickups)}  duration={5} start={convertToInternationalCurrencySystemStart(impactData.pickups)} enableScrollSpy={true} scrollSpyDelay={2} /></h4>} */}
            {impactData&&<h4>{convertToInternationalCurrencySystem(impactData.pickups)}</h4>}
            <p>Pickups of waste <br/>from homes</p>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-6 col-6">
          <div className="impact-info">
            <span className='dkicn'><img src={imp2} alt='demo' width={"50px"} height={"50px"} /></span>
            <span className='mbicn'><img src={mimp2} alt='demo' width={"50px"} height={"50px"} /></span>
            {impactData&&<h4><CountUp end={(impactData.kgs/1000).toFixed()}  duration={5} start={(impactData.kgs/1000).toFixed()-3} enableScrollSpy={true} scrollSpyDelay={2} /><sub>Tonnes</sub> </h4>}
            <p>Waste Prevented from Landfills &amp; Oceans</p>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-6 col-6">
          <div className="impact-info">
            <span className='dkicn'><img src={imp3} alt='demo' width={"50px"} height={"50px"} /></span>
            <span className='mbicn'><img src={mimp3} alt='demo' width={"50px"} height={"50px"} /></span>
            {impactData&&<h4>&gt;{impactData.images_sorted_with_ai_ml_technologies}</h4>}
            <p>Images Sorted with AI/ML  technologies</p>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-6 col-6">
          <div className="impact-info">
            <span className='dkicn'><img src={imp4} alt='demo' width={"50px"} height={"50px"} /></span>
            <span className='mbicn'><img src={mimp4} alt='demo' width={"50px"} height={"50px"} /></span>
            {impactData&&<h4>&gt;<CountUp end={parseInt(impactData.product_types_waste_digitally_mapped)}  duration={5} start={parseInt(impactData.product_types_waste_digitally_mapped)-3} enableScrollSpy={true} scrollSpyDelay={2} /></h4>}
            <p>Product types’ waste digitally mapped</p>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-6 col-6">
          <div className="impact-info">
            <span className='dkicn'><img src={imp5} alt='demo' width={"50px"} height={"50px"} /></span>
            <span className='mbicn'><img src={mimp5} alt='demo' width={"50px"} height={"50px"} /></span>
            {impactData&&<h4><CountUp end={convertToInternationalCurrencySystem(impactData.households)}  duration={5} start={convertToInternationalCurrencySystemStart(impactData.households)} enableScrollSpy={true} scrollSpyDelay={2} /></h4>}
            <p>Households have joined the movement across 7 cities</p>
          </div>
        </div>
      </div>
    </div>
    </section>
  )
}

export default ImpactSection