import React, { useEffect, useState } from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import TypeWriterEffect from 'react-typewriter-effect';
import WOW from 'wowjs';
import $ from "jquery";
import './About.css';
import home from "../../images/impact-banner.jpg"
import mobilebnr from "../../images/impctbnrm.jpg"
import playBtn from "../../images/play.png"
import bbcod from "../../images/bbcod.png"
import corepic from "../../images/corepic.png"
import sxty from "../../images/sxty.png"
import ninty from "../../images/ninty.png"
import roshanpic from "../../images/roshanpic.png"
import { AiOutlineMail } from "react-icons/ai";
import d01 from "../../images/d01.png"
import d02 from "../../images/d02.png"
import d03 from "../../images/d03.png"
import d04 from "../../images/d04.png"
import d05 from "../../images/d05.png"
import leader1 from "../../images/leader1.jpg"
import leader2 from "../../images/leader2.jpg"
import leader3 from "../../images/leader3.jpg"
import leader4 from "../../images/leader4.jpg"
import leader5 from "../../images/leader5.jpg"
import leader6 from "../../images/leader6.jpg"
import leader7 from "../../images/leader7.jpg"
import leader8 from "../../images/leader8.jpg"
import leader9 from "../../images/leader9.jpg"
import pple2 from "../../images/pple2.jpg"
import pple9 from "../../images/pple9.jpg"
import pple11 from "../../images/pple11.jpg"
import pple19 from "../../images/pple19.jpg"
import pple22 from "../../images/pple22.jpg"
import pple23 from "../../images/pple23.jpg"
import pple27 from "../../images/pple27.jpg"
import lnkdin from "../../images/lnkdin.png"
import pssi1 from "../../images/pssi1.jpg"
import pssi2 from "../../images/pssi2.jpg"
import pssi3 from "../../images/pssi3.jpg"
import pssi4 from "../../images/pssi4.jpg"
import pssi5 from "../../images/pssi5.jpg"
import pssi6 from "../../images/pssi6.jpg"
import pssi7 from "../../images/pssi7.jpg"
import pssi8 from "../../images/pssi8.jpg"
import pssi9 from "../../images/pssi9.jpg"
import pssi10 from "../../images/pssi10.jpg"
import pssi11 from "../../images/pssi11.jpg"
import pssi12 from "../../images/pssi12.jpg"
import pssi13 from "../../images/pssi13.jpg"
import wmn1 from "../../images/wmn1.jpg"
import wmn2 from "../../images/wmn2.jpg"
import wmn3 from "../../images/wmn3.jpg"
import wmn4 from "../../images/wmn4.jpg"
import wmn5 from "../../images/wmn5.jpg"
import wmn6 from "../../images/wmn6.jpg"
import wmn7 from "../../images/wmn7.jpg"
import wmn8 from "../../images/wmn8.jpg"
import wmn9 from "../../images/wmn9.jpg"
import wmn10 from "../../images/wmn10.jpg"
import wmn11 from "../../images/wmn11.jpg"
import wmn12 from "../../images/wmn12.jpg"
import wmn13 from "../../images/wmn13.jpg"
import wmn14 from "../../images/wmn14.jpg"
import wmn15 from "../../images/wmn15.jpg"
import wmn16 from "../../images/wmn16.jpg"
import wmn17 from "../../images/wmn17.jpg"
import wmn18 from "../../images/wmn18.jpg"
import wmn19 from "../../images/wmn19.jpg"
import wmn20 from "../../images/wmn20.jpg"
import "swiper/css";
import { useRef } from 'react';
import ContactUsForm from './Contact-us-form';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../Redux/ActionCreater';
import { Helmet } from 'react-helmet';
import VideoModal from '../Home/VideoModal';

function AboutUs() {
    const [video, setVideo] = useState(false);
    const inputRef = useRef(null);
    const dispatch = useDispatch();

    const closeModal = () => {
        setVideo(false)
    };

    const options = {
        loop: true,
        margin: 0,
        items: 1,
        autoplay: false,
        autoplaySpeed: 1200,
        rewind: false,
        lazyLoad: true,
        mouseDrag: false,
        touchDrag: false,
        dots: true,
        nav: false
    };
    const options1 = {
        loop: false,
        margin: 20,
        items: 2,
        autoplay: false,
        autoplaySpeed: false,
        rewind: false,
        lazyLoad: true,
        mouseDrag: true,
        touchDrag: true,
        dots: true,
        nav: false,
        responsive: {
            0: {
                items: 1,
                nav: false,
                loop: true,
                margin: 10,
                autoplay: true,
                mouseDrag: true,
                touchDrag: true,
                stagePadding: 65,
                left: -30,
            },
            767: {
                items: 2,
                nav: false,
                loop: true,
                autoplay: true,
                margin: 10,
                mouseDrag: true,
                touchDrag: true,
                stagePadding: 50,
                left: -30,
            },
            768: {
                items: 3,
                nav: true,
                loop: true,
                autoplay: true,
                mouseDrag: true,
                touchDrag: true,
            },
            992: {
                items: 4,
                nav: false,
                loop: true,
                margin: 20,
                autoplay: true,
                mouseDrag: true,
                touchDrag: true,
            },
            1200: {
                items: 5,
                nav: false,
                loop: true,
                margin: 20,
                mouseDrag: false,
                touchDrag: false,
            }
        }
    };
    const options2 = {
        loop: false,
        margin: 10,
        autoWidth: true,
        items: 2,
        autoplay: true,
        autoplaySpeed: 1200,
        mouseDrag: true,
        touchDrag: true,
        dots: false,
        nav: false,
        stagePadding: 40,
        responsive: {
            0: {
                items: 2,
                nav: false,
                dots: true,
                loop: true,
                autoWidth: false,
                stagePadding: 0,
                margin: 5,
            },
            450: {
                items: 2,
                nav: false,
                dots: true,
                loop: true,
                autoWidth: false,
                stagePadding: 0,
                margin: 5,
            },
            768: {
                items: 2,
                nav: false,
                dots: true,
                loop: true,
            },
            992: {
                items: 4,
                nav: false,
                loop: true,
            },
            1200: {
                items: 4,
                nav: false,
                loop: true,
                margin: 10,
            },
            1700: {
                items: 5,
                nav: false,
                loop: true,
                margin: 10,
            },
            1920: {
                items: 5,
                nav: false,
                loop: true,
                margin: 10,
            }
        }
    };

    useEffect(() => {
        new WOW.WOW({
            live: false
        }).init();
        const imageEl = document.getElementById("fadingImage");
        const images = [
            pssi1, pssi2, pssi3, pssi4, pssi5, pssi6, pssi7, pssi8, pssi9, pssi10, pssi11, pssi12, pssi13
        ];
        const imageE2 = document.getElementById("fadingImage2");
        const images2 = [
            wmn1, wmn2, wmn3, wmn4, wmn5, wmn6, wmn7, wmn8, wmn9, wmn10, wmn11, wmn12, wmn13, wmn14, wmn15, wmn16, wmn17, wmn18, wmn19, wmn20
        ];

        window.setInterval(() => { changePicture(images, imageEl) }, 2000);
        window.setInterval(() => { changePicture(images2, imageE2) }, 3000);
        let i = 0;
        function changePicture(arr, elm) {
            i++;
            if (i > arr.length - 1) i = 0;
            elm.src = arr[i];
        }

        let id = window.location.href.split("#")[1];
        setTimeout(() => {
            if (id) {
                $('html, body').animate(
                    {
                        scrollTop: $("#" + id).offset().top - 200
                    },
                )
            } else {
                // window.scrollTo(0, 0);
            }
            setTimeout(() => {
                dispatch(setLoading(false))

            }, 2000);
        }, 1000);
    }, [])

    let nextsection = () => {
        $('html, body').animate({
            scrollTop: $("#nxtsec").offset().top
        }, 2000);
    }

    return (
        <>
            <Helmet>
                <title>Bintix::About Us</title>
                <meta property="og:title" content="Bintix::About Us" />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <section className="bannercontainer abutpgebnr">
                <div id="NoiseBanner">

                    <OwlCarousel className="owl-theme" {...options}  >

                        <div className="item">
                            <img className='dkstbnr' src={home} style={{ height: "auto", width: "100%" }} alt='demo'/>
                            <img className='mbnr' src={mobilebnr} style={{ height: "auto", width: "100%" }} alt='demo'/>
                            <div className="banner-cap">
                                <div className="container">
                                    <div className="banrcontent">
                                        <h1 className='dkbntxt'>Come, lead the waste to<br /> insights movement</h1>
                                        <h1 className='mbbntxt'>Come, lead the waste to insights movement</h1>
                                        <h4>Champion the removal of plastic waste from the planet</h4>
                                        <a href="javascript:void(0);" onClick={nextsection}>Learn More</a>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </OwlCarousel>
                </div>
            </section>


            <section className='about-us-sec' id='nxtsec'>
                <div className='container'>
                    <div className='abuttinner'>
                        <div className='row align-items-center'>
                            <div className='col-lg-7'>
                                <div className='about-inner'>
                                    <p>
                                        We’re taking an age-old industry, mixing in some technology, sprinkling on some top-notch customer service and execution,
                                        and coming out with an awesome product for people and for mother nature.

                                    </p>
                                </div>
                            </div>
                            <div className='col-lg-5'>
                                <div className='abutpics'>
                                    <img src={corepic} alt='demo' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='roshan-info'>
                <div className='container'>
                    <div className='roshan-info-inner'>
                        <div className='roshanpic'>
                            <img src={roshanpic} alt='demo'/>
                            <div className='miranda-sec'>
                                <div className='miranda-txt'>
                                    <h5>Roshan Miranda</h5>
                                    <span>Founder</span>
                                    <ul>
                                        <li>PhD - Genetics </li>
                                        <li>Consultant BCG</li>
                                        <li>Serial Entrepreneur</li>
                                    </ul>
                                </div>
                                <div className='miranda-txt'>
                                    <h5>Jayanarayan K</h5>
                                    <span>Founder </span>
                                    <ul>
                                        <li>PhD - Molecular Medicine </li>
                                        <li>Pharma R&D, Mayo Clinics</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='roshan-content'>
                                <p><img className='cmtsxty' src={sxty} alt='demo'/>
                                    I wanted people to look at waste differently! Bintix Was Founded To Get novel value
                                    such as Data From Waste
                                    <img className='cmtninty' src={ninty} alt='demo'/>
                                </p>
                                <span>Roshan Miranda</span>
                            </div>
                            <div className='roshan-content'>
                                <p><img className='cmtsxty' src={sxty} alt='demo'/>
                                    I believe that once there are enough of us who join the Bintix movement, waste will
                                    change from being a problem, to a powerful solution.
                                    <img className='cmtninty' src={ninty} alt='demo'/>
                                </p>
                                <span>Jayanarayan K</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='wait-shall'>
                <div className='container'>
                    <div className='wait-shall-inner'>
                        <div className='our-waste'>
                            <h6>Our Purpose</h6>
                            <h2>No Waste Shall Go Wasted</h2>
                        </div>
                        <div className='wtevison'>
                            <div className='wsvison-inner'>
                                <h6>Our Vision</h6>
                                <p>Use Insights from waste as a super-power that removes waste from planet  & uplifts marginalized waste workers  </p>
                            </div>
                            <div className='wsvison-inner'>
                                <h6>Our Mission</h6>
                                <p>Apply technology to waste to transform it into valuable insights & solutions</p>
                            </div>
                        </div>
                    </div>
                </div>

            </section>


            <section className='recycled-story'>
                <div className='rebrcod'>
                    <img src={bbcod} alt='demo'/>
                </div>
                <div className='rebrcod2'>
                    <img src={bbcod} alt='demo'/>
                </div>
                <div className='container'>
                    <div className='recycled-txt'>
                        <div className='rcycled-lft' >
                            <h3>Our<br /> <span ref={inputRef}> <TypeWriterEffect
                                startDelay={120}
                                cursorColor="black"
                                nextTextDelay={300}
                                typeSpeed={130}
                                loop={true}
                                multiText={[
                                    'Waste To Data',
                                    'No Waste',
                                    'Circularity',
                                    'Value From Waste'
                                ]}
                            />

                            </span> Story</h3>

                        </div>
                        <div className='recycled-vdo'>
                            <img src={`https://img.youtube.com/vi/KA6QuVj7ZJA/0.jpg`} alt='youtube-thumbnail' />
                            <img class="playbtn" src={playBtn} onClick={() => { setVideo(true) }} alt='demo'/>
                        </div>
                    </div>
                </div>
            </section>


            <section className='things-value'>
                <div className='container'>
                    <div className='thing-inner'>
                        <h3>Our 5 Core Values</h3>

                        <OwlCarousel className="owl-theme" {...options1} >

                            <div className="item">
                                <div className='value-box doingbg'>
                                    <h4>Doing Good</h4>
                                    <p>For the planet & for the People</p>
                                    <span><img src={d01} alt='demo'/></span>
                                </div>
                            </div>
                            <div className="item">
                                <div className='value-box doingbg2'>
                                    <h4>Being Inclusive</h4>
                                    <p>Of Everyone, No matter their background, with all having an equal voice</p>
                                    <span><img src={d02} alt='demo'/></span>
                                </div>
                            </div>
                            <div className="item">
                                <div className='value-box doingbg3'>
                                    <h4>Customer Obsessed</h4>
                                    <p>So That We Do Our Best To Serve</p>
                                    <span><img src={d03} alt='demo'/></span>
                                </div>
                            </div>
                            <div className="item">
                                <div className='value-box doingbg4'>
                                    <h4>Ability-Led</h4>
                                    <p>Good People Doing Great Things</p>
                                    <span><img src={d04} alt='demo'/></span>
                                </div>
                            </div>
                            <div className="item">
                                <div className='value-box doingbg5'>
                                    <h4>Privacy Focused</h4>
                                    <p>Ensuring That Personal Data Is Used Responsibly</p>
                                    <span><img src={d05} alt='demo'/></span>
                                </div>
                            </div>
                        </OwlCarousel>

                    </div>
                </div>

            </section>








            <section className='passionate-box'>
                <div className='container'>
                    <div className='passionate-inner-box'>
                        <div className='passionte-txt'>
                            <h2>100+ Passionate People </h2>
                        </div>
                        <div className='passionate-pic'>
                            <img id="fadingImage" className="image-styled" src='' alt='demo'/>
                            <img id="fadingImage2" className="image-styled" src='' alt='demo'/>
                        </div>

                    </div>
                </div>

            </section>


            <section className='wstageall-pic'>
                <div className='sldrinner'>
                    {
                        <OwlCarousel className="owl-theme" {...options2} >

                            <div className="item">
                                <div className='inercrsl'><img src={pple2} alt='demo'/></div>
                            </div>
                            <div className="item">
                                <div className='inercrsl'><img src={pple27} alt='demo'/></div>
                            </div>
                            <div className="item">
                                <div className='inercrsl'><img src={pple23} alt='demo'/></div>
                            </div>
                            <div className="item">
                                <div className='inercrsl'><img src={pple22} alt='demo'/></div>
                            </div>
                            <div className="item">
                                <div className='inercrsl'><img src={pple19} alt='demo'/></div>
                            </div>
                            <div className="item">
                                <div className='inercrsl'><img src={pple11} alt='demo'/></div>
                            </div>
                            <div className="item">
                                <div className='inercrsl'><img src={pple9} alt='demo'/></div>
                            </div>

                        </OwlCarousel>

                    }
                </div>
            </section>

            <section className='leaders-sec'>
                <div className='container'>
                    <h3>The Change Leaders</h3>
                    <div className='row'>
                        <div className='col-lg-3 col-6'>
                            <div className='leaderbx'>
                                <figure><img src={leader1} /></figure>
                                <div className='laeder-info'>
                                    <div className='leadr-name'>
                                        <h6>Roshan Miranda</h6>
                                        <a href='https://www.linkedin.com/in/roshanmiranda/'><img src={lnkdin} alt='demo'/></a>
                                    </div>
                                    <p>Founder</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-6'>
                            <div className='leaderbx'>
                                <figure><img src={leader2} alt='demo'/></figure>
                                <div className='laeder-info'>
                                    <div className='leadr-name'>
                                        <h6>Jayanarayan K</h6>
                                        <a href='https://www.linkedin.com/in/jayanarayan-kulathingal-3aa4428/'><img src={lnkdin} alt='demo'/></a>
                                    </div>
                                    <p>Founder</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-6'>
                            <div className='leaderbx'>
                                <figure><img src={leader3} /></figure>
                                <div className='laeder-info'>
                                    <div className='leadr-name'>
                                        <h6>Parul Mathur</h6>
                                        <a href='https://www.linkedin.com/in/parul-mathur-406120106/'><img src={lnkdin} alt='demo'/></a>
                                    </div>
                                    <p>Chief Data Scientist</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-6'>
                            <div className='leaderbx'>
                                <figure><img src={leader4} /></figure>
                                <div className='laeder-info'>
                                    <div className='leadr-name'>
                                        <h6>Kartik Khare</h6>
                                        <a href='https://www.linkedin.com/in/kartikkhare/'><img src={lnkdin} alt='demo'/></a>
                                    </div>
                                    <p>CGO</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-6'>
                            <div className='leaderbx'>
                                <figure><img src={leader5} alt='demo'/></figure>
                                <div className='laeder-info'>
                                    <div className='leadr-name'>
                                        <h6>Satheesh AV</h6>
                                        <a href='https://www.linkedin.com/in/satheesh-avaragolla-8155688/'><img src={lnkdin} alt='demo'/></a>
                                    </div>
                                    <p>Asst. Dir. Operations</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-6'>
                            <div className='leaderbx'>
                                <figure><img src={leader6} /></figure>
                                <div className='laeder-info'>
                                    <div className='leadr-name'>
                                        <h6>Shravan Poloju</h6>
                                        <a href='https://www.linkedin.com/in/shravan-poloju-a6b33ba9/'><img src={lnkdin} alt='demo'/></a>
                                    </div>
                                    <p>Innovations Manager</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-6'>
                            <div className='leaderbx'>
                                <figure><img src={leader7} /></figure>
                                <div className='laeder-info'>
                                    <div className='leadr-name'>
                                        <h6>Vijay Kumar</h6>
                                        <a href='https://www.linkedin.com/in/vijay-kumar-ravinootala-65115616b/'><img src={lnkdin} alt='demo'/></a>
                                    </div>
                                    <p>Tech Lead</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-6'>
                            <div className='leaderbx'>
                                <figure><img src={leader8} /></figure>
                                <div className='laeder-info'>
                                    <div className='leadr-name'>
                                        <h6>Rishabh Thakur</h6>
                                        <a href='https://www.linkedin.com/in/rishthakur/'><img src={lnkdin} alt='demo'/></a>
                                    </div>
                                    <p>Operations Lead</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-6'>
                            <div className='leaderbx'>
                                <figure><img src={leader9} alt='demo'/></figure>
                                <div className='laeder-info'>
                                    <div className='leadr-name'>
                                        <h6>Manish Makhijani</h6>
                                    </div>
                                    <p>Advisor</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </section>




            <section className='about-contact-info'>
                <div className='container'>
                    <h3>Contact Details</h3>
                    <div className='row justify-content-center'>

                        <div className='col-lg-4'>
                            <div className='cntctdetl'>
                                <span><AiOutlineMail /></span>
                                <h4>Email Address</h4>
                                <a>hello@bintix.com</a>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <ContactUsForm />


            {video && <VideoModal youtube_id={"KA6QuVj7ZJA"} closeModal={closeModal} />}

        </>

    )
}

export default AboutUs